import React, { forwardRef, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getMousePosition, snapToNearest45 } from "../../utils/svgFunctions";
import {
  calculateCentroidForStaircase,
  changeRotationIconColor,
  getAngleInDergreeCondition
} from "../../helper/svgHelper";
import RotationIcon from "./RotationIcon";

const LShapeStaircase = forwardRef(
  (
    {
      id,
      index,
      name,
      roomId,
      onSelect,
      type,
      isSelected,
      onTouchStart,
      onMouseMove,
      setStaircases,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      rotationAngle,
      translate,
      scale,
      floorName,
      ...config
    },
    ref
  ) => {
    const [isStaircaseRotating, setIsStaircaseRotating] = useState(false);
    const [rotationIconColor, setRotationIconColor] = useState("#3a80c7");

    const floorStaircases = useSelector(
      (state) => state.floorPlan.floorStaircases
    );

    const {
      x,
      y,
      horizontalSegment: { width: hWidth, height: hHeight, steps: hSteps },
      verticalSegment: { width: vWidth, height: vHeight, steps: vSteps }
    } = config;

    const { widthToConsider, heightToConsider } = useMemo(() => {
      const widthToConsider = hWidth + vWidth;
      const heightToConsider = hHeight + vHeight;

      return {
        widthToConsider,
        heightToConsider
      };
    }, [hWidth, vWidth, hHeight, vHeight]);

    const { arrowWidth, arrowHeight, arrowX, arrowY } = useMemo(() => {
      const arrowWidth = hSteps > 3 ? 30 : 20;
      const arrowHeight = hSteps > 3 ? 30 : 20;
      const arrowX = x + hWidth + vWidth / 2;
      const arrowY = y + heightToConsider - arrowHeight - 10; // Adjust the value 10 as needed

      return {
        arrowWidth,
        arrowHeight,
        arrowX,
        arrowY
      };
    }, [config]);

    const margin = 5; // Margin around the selected staircase

    const onClickCallback = useCallback(
      (e) => {
        e.stopPropagation();
        onSelect({
          id,
          index,
          name,
          roomId,
          type,
          x,
          y,
          horizontalSegment: config.horizontalSegment,
          verticalSegment: config.verticalSegment
        });
      },
      [onSelect]
    );

    const { iconX, iconY } = useMemo(() => {
      const centroid = calculateCentroidForStaircase(
        x,
        y,
        widthToConsider,
        heightToConsider
      );
      const iconDistance = 20;

      const offsetX = iconDistance * Math.cos(rotationAngle);
      const offsetY = iconDistance * Math.sin(rotationAngle);

      return {
        iconX: centroid.x + offsetX, // Adjust to move the icon slightly left
        iconY: centroid.y + offsetY
      };
    }, [x, y, rotationAngle, widthToConsider, heightToConsider]);

    const handleMouseMove = (e) => {
      if (isStaircaseRotating) {
        const pos = getMousePosition(e, ref, translate, scale);
        const center = calculateCentroidForStaircase(
          x,
          y,
          widthToConsider,
          heightToConsider
        );
        let currentAngle = Math.atan2(pos.y - center.y, pos.x - center.x);
        if (currentAngle < 0) {
          currentAngle += 2 * Math.PI;
        }

        const angleInDegrees = currentAngle * (180 / Math.PI);
        changeRotationIconColor(angleInDegrees, setRotationIconColor);

        let updatedSts = { ...floorStaircases };
        updatedSts[floorName] = updatedSts[floorName]?.map((st, idx) =>
          idx === index ? { ...st, rotationAngle: currentAngle } : st
        );

        setStaircases(updatedSts);
      }
    };

    const handleMouseUp = (e) => {
      e.stopPropagation();
      setIsStaircaseRotating(false);

      const angleInDegrees = rotationAngle * (180 / Math.PI);
      const angleInDegreesCondition =
        getAngleInDergreeCondition(angleInDegrees);

      if (angleInDegreesCondition) {
        const snappedAngle = snapToNearest45(angleInDegrees) * (Math.PI / 180);

        let updatedSts = { ...floorStaircases };
        updatedSts[floorName] = updatedSts[floorName]?.map((st, idx) =>
          idx === index ? { ...st, rotationAngle: snappedAngle } : st
        );

        setStaircases(updatedSts);

        setRotationIconColor("#3a80c7");
      } else {
        let updatedSts = { ...floorStaircases };
        updatedSts[floorName] = updatedSts[floorName]?.map((st, idx) =>
          idx === index ? { ...st, rotationAngle } : st
        );

        setStaircases(updatedSts);
      }
    };

    return (
      <>
        <g
          transform={`rotate(${(rotationAngle || 0) * (180 / Math.PI)} ${
            x + widthToConsider / 2
          } ${y + heightToConsider / 2})`}
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          onMouseMove={onMouseMove}
          onTouchMove={onMouseMove}
          onMouseUp={onMouseUp}
          onTouchEnd={onTouchEnd}
          onClick={onClickCallback}
        >
          {/* Outer rectangle of staircase */}
          {isSelected && (
            <rect
              x={x - margin}
              y={y - margin}
              width={Math.max(hWidth, vWidth) + 80 + 2 * margin}
              height={vHeight + 80 + 2 * margin}
              fill="none"
              stroke="#2dabefe3"
              strokeWidth="2"
            />
          )}

          {/* Horizontal portion of staircase */}
          <rect
            x={x}
            y={y}
            width={hWidth}
            height={hHeight}
            fill="none"
            stroke="black"
            pointerEvents="all"
          ></rect>

          {/* Steps of horizontal staircase */}
          {[...Array(hSteps - 1)].map((_, i) => (
            <line
              key={i}
              x1={x + ((i + 1) * hWidth) / hSteps}
              y1={y}
              x2={x + ((i + 1) * hWidth) / hSteps}
              y2={y + hHeight}
              stroke="black"
            ></line>
          ))}

          {/* Middle square of Lshaped staircase */}
          <rect
            x={x + hWidth}
            y={y}
            width={80}
            height={80}
            fill="none"
            stroke="black"
            pointerEvents="all"
          ></rect>

          {/* Vertical portion of staircase */}
          <rect
            x={x + hWidth}
            y={y + 80}
            width={vWidth}
            height={vHeight}
            fill="none"
            stroke="black"
            pointerEvents="all"
          ></rect>

          {/* Steps of vertical staircase */}
          {[...Array(vSteps - 1)].map((_, i) => (
            <line
              key={i}
              x1={x + hWidth}
              y1={y + 80 + ((i + 1) * vHeight) / vSteps}
              x2={x + hWidth + vWidth}
              y2={y + 80 + ((i + 1) * vHeight) / vSteps}
              stroke="black"
            ></line>
          ))}

          {/* Arrow, indicate the lshape staircase direction */}
          <polygon
            points={`
              ${arrowX},${arrowY}
              ${arrowX + arrowWidth / 2},${arrowY + arrowHeight / 2}
              ${arrowX + arrowWidth / 4},${arrowY + arrowHeight / 2}
              ${arrowX + arrowWidth / 4},${arrowY + arrowHeight}
              ${arrowX - arrowWidth / 4},${arrowY + arrowHeight}
              ${arrowX - arrowWidth / 4},${arrowY + arrowHeight / 2}
              ${arrowX - arrowWidth / 2},${arrowY + arrowHeight / 2}
            `}
            fill="gray"
            stroke="black"
            strokeWidth="1"
            pointerEvents="all"
          ></polygon>
          {!isSelected && (
            <text
              x={x + widthToConsider / 2} // Center the text horizontally
              y={y + heightToConsider / 2} // Center the text vertically
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize="14" // Adjust the font size as needed
              fill="black" // Adjust the text color as needed
              style={{ pointerEvents: "none" }} // Ensure the text does not interfere with click events
              transform={`rotate(${-rotationAngle * (180 / Math.PI)} ${
                x + widthToConsider / 2
              } ${y + heightToConsider / 2})`}
            >
              {name}
            </text>
          )}
        </g>

        {/* Rotation icon */}
        {isSelected && (
          <RotationIcon
            x={iconX - 30}
            y={iconY - 30}
            fill={rotationIconColor}
            rotationIconColor={rotationIconColor}
            onMouseMove={handleMouseMove}
            onTouchMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onTouchEnd={handleMouseUp}
            onTouchCancel={handleMouseUp}
            onMouseDown={(e) => {
              e.stopPropagation();
              setIsStaircaseRotating(true);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              setIsStaircaseRotating(true);
            }}
            iconTransform={`rotate(${
              (rotationAngle || 0) * (180 / Math.PI)
            } 128 128)`}
          />
        )}
      </>
    );
  }
);

export default LShapeStaircase;
