import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import MotionButton from "../UI/MotionButton";
import StaircaseShapeSelectModal from "./StaircaseTypeSelectModal";
import { Grid } from "@mui/material";
import { updateStaircases } from "../../redux/floorPlan";
import { getSteps } from "../../helper/svgHelper";

const ST_SHAPES = [
  {
    title: "Straight",
    key: "straight",
    iconUrl: "/images/straight-staircase.svg",
  },
  {
    title: "L Shape",
    key: "lshape",
    iconUrl: "/images/l_stair.png",
  },
];

const StaircaseShapeSelectModalContent = (props) => {
  const { onClose, mainModalOnClose, selectedStaircase } = props;

  const selectedFloor = useSelector((state) => state.floorPlan.selectedFloor);
  const singleCustomerData = useSelector(
    (state) => state.customerReducer.singleCustomer
  );

  const [selectedShape, setSelectedShape] = useState(null);
  const [openStaircaseTypeModal, setOpenStaircaseTypeModal] = useState(false);

  const dispatch = useDispatch();

  const selectShape = useCallback(
    (s) => {
      setSelectedShape(s);
    },
    [setSelectedShape]
  );

  const addStaircase = useCallback(() => {
    const staircase = _.find(
      singleCustomerData.staircases,
      (st) => st.id === selectedStaircase.id
    );
    const { steps, heightToSet } = getSteps(staircase);

    onClose();
    mainModalOnClose();

    let staircaseToAdd = {
      id: selectedStaircase?.id,
      name: selectedStaircase?.name,
      type: selectedShape,
    };

    if (selectedShape === "straight") {
      staircaseToAdd = {
        ...staircaseToAdd,
        steps: steps,
        height: heightToSet,
      };
    }

    const updateStaircasePayload = {
      TYPE: "ADD",
      floorName: selectedFloor.name,
      staircase: staircaseToAdd,
    };
    dispatch(updateStaircases(updateStaircasePayload));
  }, [selectedFloor, selectedStaircase, selectedShape]);

  return (
    <>
      <div className="space-y-2 flex flex-col items-center mt-4">
        <Grid
          container
          spacing={2}
          sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
        >
          {ST_SHAPES.map((shape) => {
            return (
              <Grid item xs={3} width={"fit-content"}>
                <div
                  className={`border-[1px] rounded-lg w-fit flex justify-center items-center
                      ${
                        selectedShape === shape.key
                          ? "border-[#1e2e5a]"
                          : "border-gray-300"
                      }
                  `}
                  onClick={() => selectShape(shape.key)}
                >
                  <img src={shape.iconUrl} className="w-24 h-24 rounded-lg" />
                </div>
                <div key={shape.title} className="mt-1 text-sm ">
                  {shape.title}
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
      <div className="flex gap-2 mt-10 mx-10">
        <MotionButton
          disabled={!selectedShape}
          className={`border-[1px] text-white border-gray-400 w-full ${
            selectedShape ? "bg-[#1e2e5a]" : "bg-gray-300 !text-black"
          }`}
          onClick={addStaircase}
        >
          ADD
        </MotionButton>
      </div>
      <StaircaseShapeSelectModal
        open={openStaircaseTypeModal}
        onClose={() => setOpenStaircaseTypeModal(false)}
      />
    </>
  );
};

export default StaircaseShapeSelectModalContent;
