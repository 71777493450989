import React, { useCallback } from "react";
import MotionButton from "../UI/MotionButton";
import { useDispatch, useSelector } from "react-redux";
import { setRoomToDraw } from "../../redux/floorPlan";

const RenderRooms = () => {
  const selectedFloor = useSelector((state) => state.floorPlan.selectedFloor);
  const drawingRooms = useSelector((state) => state.floorPlan.drawingRooms);
  const selectedRoomToDraw = useSelector(
    (state) => state.floorPlan.selectedRoomToDraw
  );
  const dispatch = useDispatch();

  const selectRoom = useCallback(
    (room) => {
      dispatch(setRoomToDraw(room));
    },
    [setRoomToDraw, dispatch]
  );

  return (
    <>
      <div className="flex items-center justify-center p-2 rounded-md text-sm border-[1px] border-gray-400">
        {selectedFloor.name}
      </div>
      <div className="space-y-1 mt-2 overflow-y-auto h-[600px]">
        {drawingRooms[selectedFloor.name]?.map((room) => (
          <MotionButton
            key={room.id}
            className={`border-[1px] text-white border-gray-400 w-full ${
              selectedRoomToDraw?.name === room.name
                ? "bg-[#1e2e5a]"
                : "bg-gray-200 !text-black border-[#1e2e5a]"
            }`}
            onClick={() => selectRoom(room)}
          >
            {room.name}
          </MotionButton>
        ))}
      </div>
    </>
  );
};

export default RenderRooms;
