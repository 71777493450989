import { v4 as uuidv4 } from "uuid";

// Function to update IDs within the checklist
export function updateIds(obj) {
  obj.id = uuidv4();

  if (obj.subQuestion && obj.subQuestion.length > 0) {
    for (const subQ of obj.subQuestion) {
      updateIds(subQ);
    }
  }
}

// Function to generate a new unique color
export function generateUniqueColor(existingColors, defaultColors) {
  if (existingColors.length < defaultColors.length) {
    const filteredColors = defaultColors.filter(
      (color) => !existingColors.includes(color)
    );
    return filteredColors[0];
  } else {
    function generateRandomColor(existingColors) {
      const characters = "0123456789ABCDEF";
      let color;
      let isUnique = false;

      // Function to generate a random color
      const getRandomColor = () => {
        let color = "#";
        for (let i = 0; i < 6; i++) {
          color += characters[Math.floor(Math.random() * 16)];
        }
        return color;
      };

      // Keep generating random colors until a unique color is found
      while (!isUnique) {
        color = getRandomColor();
        isUnique = !existingColors.includes(color);
      }

      return color;
    }

    return generateRandomColor(existingColors);
  }
}
