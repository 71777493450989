import { Box, Button, Stack, Typography } from "@mui/material";

import React from "react";

const ConfirmationPopUp = ({ onClick, label, description }) => {
  return (
    <Box
      sx={{
        p: 4,
        backgroundColor: "#fff",
        borderRadius: "5px",
        margin: "auto",
        width: "400px",
        top: "50%",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, -50%)"
      }}
    >
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {label}
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {description}
      </Typography>
      <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onClick(true)}
        >
          Confirm
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => onClick(false)}
        >
          Cancel
        </Button>
      </Stack>
    </Box>
  );
};

export default ConfirmationPopUp;
