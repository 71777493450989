import React, { useCallback } from "react";
import Slider from "react-slick";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import GetIconFile from "../../assets/GetIconFile";
import { useDispatch, useSelector } from "react-redux";
import { removeImageFromStore } from "../../helper/removeImgeHelper";

const ImageSliderPopup = React.memo(
  ({
    images,
    initialSlide,
    open,
    onClose,
    questionId,
    type,
    checkListIndex,
    storeKeyToUpdate,
    setPopupImages,
    roomId,
    updateImageFieldAnswer,
    queObj,
    module
  }) => {
    const singleCustomerData = useSelector(
      (state) => state.customerReducer.singleCustomer
    );
    const dispatch = useDispatch();

    const settings = {
      initialSlide,
      arrows: false,
      infinite: false,
      dots: true
    };

    const customDialogContentStyle = {
      height: "410px"
    };

    const customDotStyles = `
    .image-slider-popup .slick-dots {
      bottom: -22px;
    }
    .image-slider-popup .slick-dots li button:before {
      font-size: 16px !important;
    }
    .image-slider-popup .slick-slide {
        height: 366px;
    }
`;

    const removeImageHandler = useCallback(
      async (e, media) => {
        e.stopPropagation();
        removeImageFromStore(
          singleCustomerData,
          questionId,
          type,
          checkListIndex,
          storeKeyToUpdate,
          media,
          roomId,
          dispatch
        );

        let newAnswer = [];
        setPopupImages((prev) => {
          const newMedia = prev.filter((item) => {
            if (typeof item === "string") {
              return item !== media;
            } else {
              return item?.url !== media;
            }
          });

          newAnswer = newMedia;
          if (newMedia?.length === 0) {
            onClose();
          }
          return newMedia;
        });
        updateImageFieldAnswer?.(queObj, newAnswer);
      },
      [images, singleCustomerData, questionId, checkListIndex, storeKeyToUpdate]
    );

    const isVideo = (url) => {
      const videoExtensions = [
        ".mp4",
        ".MP4",
        ".MOV",
        ".mov",
        ".webm",
        ".WEBM"
      ];
      return videoExtensions.some(
        (ext) => url.endsWith(ext) || url.includes("video")
      );
    };

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent style={customDialogContentStyle}>
          <DialogContentText>
            <style>{customDotStyles}</style>

            <Slider className="image-slider-popup" {...settings}>
              {images?.map((media, index) => (
                <div
                  key={media?.url || media}
                  className="flex justify-center relative"
                >
                  {isVideo(media?.url || media) ? (
                    <video
                      src={media?.url || media}
                      controls
                      className="mx-auto rounded-xl"
                      style={{ width: "530px", height: "365px" }}
                    />
                  ) : (
                    <img
                      src={media?.url || media}
                      alt={`Media ${index}`}
                      className="mx-auto rounded-xl"
                      style={{ width: "530px", height: "365px" }}
                    />
                  )}
                  {module !== "repair" && (
                    <div
                      className={`absolute rounded-full bg-white padding-2.5 top-0 right-0`}
                      onClick={(e) =>
                        removeImageHandler(e, media?.url ? media?.url : media)
                      }
                    >
                      <GetIconFile
                        data={{ width: "24px", height: "24px" }}
                        iconName="remove-icon"
                      />
                    </div>
                  )}
                </div>
              ))}
            </Slider>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
);

export default ImageSliderPopup;
