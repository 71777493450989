import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import MotionButton from "../UI/MotionButton";
import StaircaseShapeSelectModal from "./StaircaseTypeSelectModal";
import { getLeftStaircases } from "../../helper/svgHelper";

const StaircaseSelectModalContent = (props) => {
  const { onClose } = props;

  const selectedFloor = useSelector((state) => state.floorPlan.selectedFloor);
  const floorStaircases = useSelector(
    (state) => state.floorPlan.floorStaircases
  );
  const singleCustomerData = useSelector(
    (state) => state.customerReducer.singleCustomer
  );
  const [selectedStaircase, setSelectedStaircase] = useState({});
  const [openStaircaseShapeModal, setOpenStaircaseShapeModal] = useState(false);

  const floorName = useMemo(() => {
    return selectedFloor.name;
  }, [selectedFloor]);

  const staircases = useMemo(() => {
    const leftStaircases = getLeftStaircases(
      floorStaircases,
      singleCustomerData
    );

    return leftStaircases;
  }, [singleCustomerData, floorStaircases]);

  const selectStaircase = useCallback(
    (st, stName) => {
      setSelectedStaircase({ ...st, name: stName });
    },
    [setSelectedStaircase]
  );

  const handleNextStep = useCallback(() => {
    setOpenStaircaseShapeModal(true);
  }, [setOpenStaircaseShapeModal, onClose]);

  const makeButtonActive = useMemo(() => {
    const selectedRoomIsInCurrentRooms = _.some(
      staircases,
      (s) => s?.id === selectedStaircase?.id
    );
    return selectedRoomIsInCurrentRooms;
  }, [staircases, selectedStaircase]);

  return (
    <>
      <div className="space-y-2 flex flex-col items-center mt-4">
        {staircases.map((s, idx) => {
          const stName =
            _.find(
              s.all_questions,
              (que) => que.question === "Name of Staircase"
            ).answer || `Staircase ${idx + 1}`;
          return (
            <MotionButton
              key={s.id}
              className={`border-[1px] text-black shadow-none w-1/2 ${
                selectedStaircase?.id === s.id && "border-[#1e2e5a]"
              }`}
              onClick={() => selectStaircase(s, stName)}
            >
              {stName}
            </MotionButton>
          );
        })}
      </div>
      <div className="flex gap-2 mt-5 justify-end">
        <MotionButton
          disabled={!makeButtonActive}
          className={`border-[1px] text-white border-gray-400 w-1/4 ${
            makeButtonActive ? "bg-[#1e2e5a]" : "bg-gray-300 !text-black"
          }`}
          onClick={handleNextStep}
        >
          Continue
        </MotionButton>
      </div>
      <StaircaseShapeSelectModal
        open={openStaircaseShapeModal}
        onClose={() => setOpenStaircaseShapeModal(false)}
        selectedStaircase={selectedStaircase}
        mainModalOnClose={onClose}
      />
    </>
  );
};

export default StaircaseSelectModalContent;
