export const updateDoorPositions = (
  room,
  newVertices,
  index,
  oldVertices,
  isSameLength
) => {
  return room.doors.map((door) => {
    let prevWallIndex, nextWallIndex;
    if (index === room.vertices.length - 1) {
      prevWallIndex = room.vertices.length - 2;
      nextWallIndex = 0;
    } else {
      prevWallIndex = (index - 1 + oldVertices.length) % oldVertices.length;
      nextWallIndex = (index + 1) % oldVertices.length;
    }

    const prevWallStart = oldVertices[prevWallIndex];
    const prevWallEnd = oldVertices[(prevWallIndex + 1) % oldVertices.length];
    const nextWallStart = oldVertices[nextWallIndex];
    const nextWallEnd = oldVertices[(nextWallIndex + 1) % oldVertices.length];

    const isDoorOnPrevWall = isDoorOnWall(door, prevWallStart, prevWallEnd);
    const isDoorOnNextWall = isDoorOnWall(door, nextWallStart, nextWallEnd);

    if (!isDoorOnPrevWall && !isDoorOnNextWall) {
      return door; // Return the door as is if it is not on the adjacent walls
    }

    if (isSameLength) {
      return door; // Skip if the length change is not more than 1 unit
    }

    const wallStart = isDoorOnPrevWall
      ? newVertices[prevWallIndex]
      : newVertices[nextWallIndex];
    const wallEnd = isDoorOnPrevWall
      ? newVertices[(prevWallIndex + 1) % newVertices.length]
      : newVertices[(nextWallIndex + 1) % newVertices.length];

    const doorLength = Math.sqrt(
      (door.end.x - door.start.x) ** 2 + (door.end.y - door.start.y) ** 2
    );

    const doorCenter = {
      x: door.start.x,
      y: door.start.y
    };

    let nearestWall = null;
    let minDistance = Infinity;
    let bestT = 0;

    // Find the nearest wall to the door center position
    newVertices.forEach((vertex, vertexIndex) => {
      const nextVertex = newVertices[(vertexIndex + 1) % newVertices.length];
      const dx = nextVertex.x - vertex.x;
      const dy = nextVertex.y - vertex.y;
      const wallLength = Math.sqrt(dx * dx + dy * dy);
      const t =
        ((doorCenter.x - vertex.x) * dx + (doorCenter.y - vertex.y) * dy) /
        (wallLength * wallLength);
      const clampedT = Math.max(0, Math.min(1, t));
      const closestPoint = {
        x: vertex.x + clampedT * dx,
        y: vertex.y + clampedT * dy
      };
      const distance = Math.sqrt(
        (doorCenter.x - closestPoint.x) ** 2 +
          (doorCenter.y - closestPoint.y) ** 2
      );
      if (distance < minDistance) {
        minDistance = distance;
        nearestWall = { start: vertex, end: nextVertex };
        bestT = clampedT;
      }
    });

    if (nearestWall) {
      const wallDirection = {
        x: nearestWall.end.x - nearestWall.start.x,
        y: nearestWall.end.y - nearestWall.start.y
      };
      const wallUnitVector = {
        x:
          wallDirection.x /
          Math.sqrt(wallDirection.x ** 2 + wallDirection.y ** 2),
        y:
          wallDirection.y /
          Math.sqrt(wallDirection.x ** 2 + wallDirection.y ** 2)
      };
      const doorStart = {
        x:
          nearestWall.start.x +
          bestT * wallDirection.x -
          (doorLength / 2) * wallUnitVector.x,
        y:
          nearestWall.start.y +
          bestT * wallDirection.y -
          (doorLength / 2) * wallUnitVector.y
      };

      const doorEnd = {
        x: doorStart.x + doorLength * wallUnitVector.x,
        y: doorStart.y + doorLength * wallUnitVector.y
      };

      return { ...door, start: doorStart, end: doorEnd };
    }

    return door;
  });
};

// Utility function to check if the door is on the given wall
export const isDoorOnWall = (door, wallStart, wallEnd) => {
  const tolerance = 20; // Adjust as needed
  const isStartMatch =
    pointToSegmentDistance(door.start, wallStart, wallEnd) < tolerance;
  const isEndMatch =
    pointToSegmentDistance(door.end, wallStart, wallEnd) < tolerance;

  return isStartMatch && isEndMatch;
};

// Utility function to calculate the distance from a point to a line segment
export const pointToSegmentDistance = (point, segStart, segEnd) => {
  const px = segEnd.x - segStart.x;
  const py = segEnd.y - segStart.y;
  const norm = px * px + py * py;
  let u = ((point.x - segStart.x) * px + (point.y - segStart.y) * py) / norm;
  u = Math.max(Math.min(u, 1), 0);
  const x = segStart.x + u * px;
  const y = segStart.y + u * py;
  const dx = x - point.x;
  const dy = y - point.y;
  return Math.sqrt(dx * dx + dy * dy);
};

export const isPointNearLine = (x, y, x1, y1, x2, y2, tolerance) => {
  const A = x - x1;
  const B = y - y1;
  const C = x2 - x1;
  const D = y2 - y1;

  const dot = A * C + B * D;
  const lenSq = C * C + D * D;
  const param = lenSq !== 0 ? dot / lenSq : -1;

  let xx, yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  } else if (param > 1) {
    xx = x2;
    yy = y2;
  } else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  const dx = x - xx;
  const dy = y - yy;
  return dx * dx + dy * dy <= tolerance * tolerance;
};

export const convertToFeetAndInches = (pixels) => {
  const gridSize = 20;
  const totalInches = Math.round((pixels / gridSize) * 12);
  const feet = Math.floor(totalInches / 12);
  const inches = totalInches % 12;
  return { feet, inches };
};

export const calculateAngle = (point1, point2) => {
  return Math.atan2(point2.y - point1.y, point2.x - point1.x) * (180 / Math.PI);
};
