import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import _ from "lodash";
import BooleanField from "../UI/QuestionTypes/BooleanField";
import NoteField from "../UI/QuestionTypes/NoteField";
import DropdownField from "../UI/QuestionTypes/DropdownField";
import { useReactHookForm } from "../../hooks/useReactHookForm";
import { findQueAndSetAnswer } from "../../helper/helper";
import { getSteps } from "../../helper/svgHelper";
import { setStaircasesForPlan } from "../../redux/floorPlan";
import { getUpdatedSingleCustomer } from "../../redux/customer";

const RenderStaircase = () => {
  const selectedST = useSelector((state) => state.floorPlan.selectedST);
  const selectedFloor = useSelector((state) => state.floorPlan.selectedFloor);
  const floorStaircases = useSelector(
    (state) => state.floorPlan.floorStaircases
  );
  const singleCustomerData = useSelector(
    (state) => state.customerReducer.singleCustomer
  );

  const dispatch = useDispatch();

  const staircaseQuestions = useMemo(() => {
    const curSt = singleCustomerData.staircases.find(
      (st) => st.id === selectedST.id
    );

    return curSt.all_questions;
  }, [singleCustomerData, selectedST]);

  const checkListIndex = useMemo(() => {
    return singleCustomerData.staircases?.findIndex(
      (st) => st.id === selectedST.id
    );
  }, [singleCustomerData, selectedST]);

  const handleClickBoolean = (answer, type) => {
    console.log(answer, type);
  };

  /**
   * Update the staircase steps and height based number of open and close treads
   */
  const changeStaircaseConfig = useCallback(() => {
    const updatedSingleCustomer = dispatch(getUpdatedSingleCustomer());
    let staircase = _.find(
      updatedSingleCustomer.staircases,
      (st) => st.id === selectedST.id
    );

    const { steps, heightToSet } = getSteps(staircase);

    let udpatedSts = { ...floorStaircases };
    udpatedSts[selectedFloor.name] = udpatedSts[selectedFloor.name]?.map((st) =>
      st.id === selectedST.id ? { ...st, steps, height: heightToSet } : st
    );

    dispatch(
      setStaircasesForPlan({
        staircases: udpatedSts,
        TYPE: "withoutInitialData",
      })
    );
  }, [
    singleCustomerData,
    setStaircasesForPlan,
    floorStaircases,
    selectedST,
    selectedFloor,
  ]);

  const handleNoteBlur = (event, queObj) => {
    findQueAndSetAnswer(
      singleCustomerData,
      queObj,
      checkListIndex,
      event.target.value,
      dispatch,
      "staircases"
    );

    if (
      queObj.question === "# of Open Treads" ||
      queObj.question === "# of Close Treads"
    ) {
      changeStaircaseConfig();
    }
  };

  const handleChangeDropdown = (value, queObj) => {
    findQueAndSetAnswer(
      singleCustomerData,
      queObj,
      checkListIndex,
      value,
      dispatch,
      "staircases"
    );
  };

  const getDefaultValues = useCallback(() => {
    return staircaseQuestions?.reduce((acc, que) => {
      if (que.type === "TEXT") {
        acc[que.id] = que.answer;
      }
      return acc;
    }, {});
  }, [staircaseQuestions, selectedST]);

  const { register, getValues, reset } = useReactHookForm({
    defaultValues: getDefaultValues(),
    mode: "onBlur",
  });

  useEffect(() => {
    reset(getDefaultValues());
  }, [staircaseQuestions]);

  const renderQuestions = (queObj, type) => {
    switch (type) {
      case "BOOLEAN":
        return (
          <BooleanField
            level={queObj?.level}
            question={queObj?.question}
            value={queObj?.answer}
            handleClickBoolean={() =>
              handleClickBoolean(queObj?.answer, "yesOrNo")
            }
            shouldSubQueIconShow={false}
            filledOut={queObj?.answer !== "none"}
            subQueFilledOut={true}
          />
        );
      case "TEXT":
        return (
          <NoteField
            question={queObj?.question}
            value={queObj?.answer}
            handleNoteBlur={(e) => handleNoteBlur(e, queObj)}
            register={register}
            id={queObj?.id}
            name={queObj?.id}
            type={queObj?.fieldType || "text"}
            latestValue={getValues(queObj?.id) || ""}
            filledOut={!!queObj?.answer}
          />
        );
      case "DROPDOWN":
        return (
          <DropdownField
            level={queObj?.level}
            question={queObj?.question}
            value={queObj?.answer}
            options={queObj?.option}
            handleChangeDropdown={(e) =>
              handleChangeDropdown(e.target.value, queObj)
            }
            filledOut={!!queObj?.answer}
            subQueFilledOut={true}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="flex items-center justify-center p-2 mb-2 rounded-md text-sm border-[1px] border-gray-400">
        {selectedST.name}
      </div>
      <div
        className="py-2 overflow-y-auto"
        style={{ height: "calc(100vh - 220px)" }}
      >
        <Grid container spacing={2}>
          {staircaseQuestions?.map((que) => {
            return (
              <Grid key={que.id} item xs={12}>
                {renderQuestions(que, que.type)}
                {/* {que.subQuestion && (
                  <div className="mt-3">
                    <Grid container spacing={2}>
                      {que.subQuestion.map((subQue) => {
                        return (
                          <Grid key={subQue.id} item xs={12}>
                            {renderQuestions(subQue, subQue.type)}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                )} */}
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default RenderStaircase;
