import {
  incrementUploadedFilesCount,
  resetUploadProgress,
  setQueueProcessing,
  setTotalFilesToUpload
} from "../redux/floorPlan";
import store from "../redux/store";

let uploadQueueInstance = null;

export class UploadQueue {
  constructor() {
    if (!uploadQueueInstance) {
      this.queue = [];
      this.processing = false;
      uploadQueueInstance = this;
    }
    return uploadQueueInstance;
  }
  addToQueue(uploadFunction) {
    const wasEmpty = this.queue.length === 0; // Check if the queue was empty

    this.queue.push(uploadFunction);

    if (wasEmpty) {
      // Update the total only if the queue was empty before adding this file
      store.dispatch(setTotalFilesToUpload(this.queue.length));
    } else {
      // Adjust total count to include the newly added file(s)
      const currentTotal = store.getState().floorPlan.totalFilesToUpload || 0;
      store.dispatch(setTotalFilesToUpload(currentTotal + 1));
    }
    this.processQueue();
  }

  async processQueue() {
    if (this.processing) return;
    this.processing = true;
    store.dispatch(setQueueProcessing(true)); // Queue started

    while (this.queue.length > 0) {
      const uploadFunction = this.queue.shift();
      await uploadFunction();
      store.dispatch(incrementUploadedFilesCount());
    }
    this.processing = false;
    store.dispatch(setQueueProcessing(false)); // Queue finished
    store.dispatch(resetUploadProgress());
  }
}

const uploadQueue = new UploadQueue();
export default uploadQueue;
