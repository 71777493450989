import React from "react";
import DialogModal from "./DialogModal";
import StaircaseSelectModalContent from "./StaircaseSelectModalContent";

const StaircaseSelectModal = (props) => {
  const { open, onClose } = props;

  return (
    <DialogModal
      width="35%"
      open={open}
      onClose={onClose}
      title="Select Staircase"
      ModalContent={StaircaseSelectModalContent}
      childComponentProps={{ onClose }}
    />
  );
};

export default StaircaseSelectModal;
