import { v4 as uuidv4 } from "uuid";

export const stairCaseCheckList = [
  {
    id: uuidv4(),
    level: 1,
    question: "Name of Staircase",
    answer: "",
    type: "TEXT",
    col: 6,
    mandatory: true,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Scope",
    answer: "",
    option: ["Install", "Refinishing"],
    type: "DROPDOWN",
    col: 6,
    mandatory: true,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "# of Close Treads",
    answer: "",
    type: "TEXT",
    col: 3,
    fieldType: "number",
    mandatory: false,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "# of Open Treads",
    answer: "",
    type: "TEXT",
    col: 3,
    fieldType: "number",
    mandatory: false,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "# of Risers",
    answer: "",
    type: "TEXT",
    col: 3,
    fieldType: "number",
    mandatory: false,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "# of Posts",
    answer: "",
    type: "TEXT",
    col: 3,
    fieldType: "number",
    mandatory: false,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "LF of Handrailing",
    answer: "",
    type: "TEXT",
    col: 4,
    fieldType: "number",
    mandatory: false,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Exposed/Covered",
    answer: "",
    option: ["Exposed", "Runner", "Wall to Wall", "W2W w/ stringers"],
    type: "DROPDOWN",
    col: 4,
    mandatory: true,
    subQuestion: [
      {
        id: uuidv4(),
        level: 2,
        question: "KASA Removing?",
        answer: "none",
        type: "BOOLEAN",
        col: 12,
        whenToShow: "Runner",
        mandatory: true,
      },
      {
        id: uuidv4(),
        level: 2,
        question: "KASA Removing?",
        answer: "none",
        type: "BOOLEAN",
        col: 12,
        whenToShow: "Wall to Wall",
        mandatory: true,
      },
      {
        id: uuidv4(),
        level: 2,
        question: "KASA Removing?",
        answer: "none",
        type: "BOOLEAN",
        col: 12,
        whenToShow: "W2W w/ stringers",
        mandatory: true,
      },
    ],
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Cove Molding",
    answer: "",
    option: ["No Cove Molding", "Cove Molding Painted", "Cove Molding Stained"],
    type: "DROPDOWN",
    col: 4,
    mandatory: true,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Species",
    answer: "",
    type: "DROPDOWN",
    option: [
      "White Oak",
      "Red Oak",
      "Southern Yellow Pine",
      "Heart Pine",
      "Eastern White Pine",
      "Pine - Unknown",
      "Maple",
      "Walnut",
      "Brazilian Cherry",
      "Ash",
      "Douglas Fir",
      "Oak - Unknown Type",
      "Other",
    ],
    col: 6,
    mandatory: true,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Notes",
    answer: "",
    type: "TEXT",
    col: 6,
    mandatory: false,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Images",
    answer: [],
    type: "IMAGE",
    col: 12,
    mandatory: true,
  },
];
