import { v4 as uuidv4 } from "uuid";

export const transitionCheckList = [
  {
    id: uuidv4(),
    level: 1,
    question: "Type",
    answer: "",
    type: "DROPDOWN",
    col: 6,
    mandatory: true,
    option: [
      "Flush Reducer",
      "Overlap Reducer",
      "Flush Stairnose",
      "Overlap Stairnose",
      "Threshold",
      "T-Molding",
      "Saddle",
      "Carpet Binder",
      "Quarter Round",
      "Vertical Cross Piece",
      "Other",
    ],
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Location",
    answer: "",
    type: "DROPDOWN",
    col: 6,
    mandatory: true,
    option: ["Doorway", "Fireplace", "Backdoor", "Staircase", "Other"],
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Length",
    answer: "",
    type: "TEXT",
    col: 6,
    fieldType: "number",
    mandatory: true,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Height Diffrence",
    answer: "",
    type: "TEXT",
    col: 6,
    fieldType: "number",
    mandatory: true,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "To Type of Flooring",
    answer: "",
    type: "DROPDOWN",
    col: 6,
    mandatory: true,
    option: [
      "Prefinished Hardwood",
      "Unfinished Hardwood",
      "Laminate",
      "LVT",
      "Tile",
      "Carpet",
      "Other",
    ],
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Notes",
    answer: "",
    type: "TEXT",
    col: 12,
    mandatory: false,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Images",
    answer: [],
    type: "IMAGE",
    col: 12,
    mandatory: true,
  },
];
