import { v4 as uuidv4 } from "uuid";

export const levelingCheckList = [
  {
    id: uuidv4(),
    level: 1,
    question:
      "How particular is the customer (1 doesn't care, 10 reason for doing project)",
    answer: 0,
    type: "RANGE",
    col: 12,
    mandatory: true,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "How bad is the leveling ( 1 Perfectly flat,  10 Disaster)",
    answer: 0,
    type: "RANGE",
    col: 12,
    mandatory: true,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "What type of leveling?",
    answer: "",
    type: "DROPDOWN",
    option: [
      "Slope to an outside corner",
      "Dip in the floor",
      "Hump in the floor",
      "Slope (may be level)",
      "Waves",
    ],
    col: 6,
    mandatory: true,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Leveling Details",
    answer: "",
    type: "DROPDOWN",
    option: [
      "Floors covered, but leveling not expected for project",
      "Floors covered, possible leveling required",
      "Leveling recommended. Customer chose not to and accepts consequences",
      "Minor Plywood leveling Required, shims or shingles",
      "Major Plywood Leveling Required, 2 by 4s and plywood",
      "Minor concrete leveling required",
      "Major concrete leveling required",
    ],
    col: 6,
    mandatory: true,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Notes",
    answer: "",
    type: "TEXT",
    col: 12,
    mandatory: false,
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Add Images",
    answer: [],
    type: "IMAGE",
    col: 12,
    mandatory: true,
  },
];
