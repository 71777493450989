import { distanceToLineSegment } from "../svgFunctions";

export const highlightNearestParallelWalls = (
  movedRoom,
  movedRoomIndex,
  pos,
  distance,
  rooms,
  setHighlightedWalls
) => {
  const nearestWalls = [];
  let minDistance = distance || 20; // Increased distance to detect near walls

  // Iterate over the walls of the moved room
  movedRoom?.vertices?.forEach((vertex, vertexIndex) => {
    const nextVertex =
      movedRoom.vertices[(vertexIndex + 1) % movedRoom.vertices.length];
    const movedWall = {
      x1: vertex.x,
      y1: vertex.y,
      x2: nextVertex.x,
      y2: nextVertex.y
    };

    // Iterate over all the rooms to find the nearest wall
    rooms.forEach((room, roomIndex) => {
      if (roomIndex !== movedRoomIndex) {
        room?.vertices?.forEach((otherVertex, otherVertexIndex) => {
          const otherNextVertex =
            room.vertices[(otherVertexIndex + 1) % room.vertices.length];
          const otherWall = {
            x1: otherVertex.x,
            y1: otherVertex.y,
            x2: otherNextVertex.x,
            y2: otherNextVertex.y
          };

          // Check if the walls are parallel
          if (areWallsParallel(movedWall, otherWall)) {
            // Calculate the distance between the walls
            const distance = Math.min(
              distanceBetweenLines(movedWall, otherWall),
              distanceToLineSegment(
                pos,
                otherWall.x1,
                otherWall.y1,
                otherWall.x2,
                otherWall.y2
              )
            );

            if (distance < minDistance) {
              minDistance = distance;
              nearestWalls.length = 0; // Clear previous nearest walls
              nearestWalls.push({
                roomIndex: roomIndex,
                vertexIndex: otherVertexIndex,
                movedWall: movedWall,
                otherWall: otherWall
              });
            }
          }
        });
      }
    });
  });

  setHighlightedWalls(nearestWalls);
};

const areWallsParallel = (wall1, wall2) => {
  const angle1 = Math.atan2(wall1.y2 - wall1.y1, wall1.x2 - wall1.x1);
  const angle2 = Math.atan2(wall2.y2 - wall2.y1, wall2.x2 - wall2.x1);
  const angleDifference = Math.abs(angle1 - angle2);
  return (
    angleDifference < 0.174533 || Math.abs(angleDifference - Math.PI) < 0.174533
  ); // 10 degrees in radians
};
const distanceBetweenLines = (line1, line2) => {
  const distance1 = distanceToLineSegment(
    { x: line1.x1, y: line1.y1 },
    line2.x1,
    line2.y1,
    line2.x2,
    line2.y2
  );
  const distance2 = distanceToLineSegment(
    { x: line1.x2, y: line1.y2 },
    line2.x1,
    line2.y1,
    line2.x2,
    line2.y2
  );
  const distance3 = distanceToLineSegment(
    { x: line2.x1, y: line2.y1 },
    line1.x1,
    line1.y1,
    line1.x2,
    line1.y2
  );
  const distance4 = distanceToLineSegment(
    { x: line2.x2, y: line2.y2 },
    line1.x1,
    line1.y1,
    line1.x2,
    line1.y2
  );

  return Math.min(distance1, distance2, distance3, distance4);
};

export const highlightNearestVertex = (
  movedRoom,
  movedRoomIndex,
  pos,
  distance,
  rooms,
  setNearestVertices
) => {
  const nearestVertices = [];
  let minDistance = distance || 18;

  // Iterate over the vertices of the moved room
  movedRoom?.vertices?.forEach((vertex, vertexIndex) => {
    rooms.forEach((room, roomIndex) => {
      if (roomIndex !== movedRoomIndex) {
        room?.vertices?.forEach((otherVertex, otherVertexIndex) => {
          const distance = Math.sqrt(
            Math.pow(vertex.x - otherVertex.x, 2) +
              Math.pow(vertex.y - otherVertex.y, 2)
          );

          if (distance < minDistance) {
            minDistance = distance;
            nearestVertices.push({
              roomIndex: roomIndex,
              vertexIndex: otherVertexIndex,
              movedVertex: vertex,
              otherVertex: otherVertex
            });
          }
        });
      }
    });
  });

  setNearestVertices(nearestVertices);
};
