import React from "react";
import { calculateCentroid } from "../../../utils/svgFunctions";

const Doors = ({
  rooms,
  handlePolygonClick,
  setSelectedDoor,
  setDraggingDoor,
  activeRoomIndex,
  isRotating,
  selectedDoor
}) => {
  return (
    <>
      {" "}
      {rooms?.map((room, roomIndex) => {
        const centroid = calculateCentroid(room.vertices);

        return (
          <g
            key={roomIndex}
            id={`group-${roomIndex}`}
            onClick={(e) => {
              e.stopPropagation();
              handlePolygonClick(rooms, roomIndex);
            }}
            transform={
              activeRoomIndex === roomIndex && isRotating
                ? `rotate(${room.rotationAngle || 0} ${centroid.x} ${
                    centroid.y
                  })`
                : ""
            }
          >
            {" "}
            {room?.doors &&
              room.doors.map((door, doorIndex) => {
                const isSelected =
                  selectedDoor &&
                  selectedDoor.roomIndex === roomIndex &&
                  selectedDoor.doorIndex === doorIndex;

                // Calculate direction vector
                const direction = {
                  x: door.end.x - door.start.x,
                  y: door.end.y - door.start.y
                };

                // Calculate the length of the direction vector
                const length = Math.sqrt(direction.x ** 2 + direction.y ** 2);

                // Normalize the direction vector
                const unitDirection = {
                  x: direction.x / length,
                  y: direction.y / length
                };

                // Calculate the perpendicular vector (for offsetting)
                const perpendicular = {
                  x: -unitDirection.y,
                  y: unitDirection.x
                };

                // Offset amount
                const offsetAmount = -6; // Adjust as needed

                // Calculate the offset start and end points
                const offsetStart = {
                  x: door.start.x + perpendicular.x * offsetAmount,
                  y: door.start.y + perpendicular.y * offsetAmount
                };

                const offsetEnd = {
                  x: door.end.x + perpendicular.x * offsetAmount,
                  y: door.end.y + perpendicular.y * offsetAmount
                };

                // Calculate the center of the door
                const centerX = (door.start.x + door.end.x) / 2;
                const centerY = (door.start.y + door.end.y) / 2;

                // Calculate the angle of rotation in degrees
                const angle =
                  Math.atan2(direction.y, direction.x) * (180 / Math.PI);

                // Calculate the width and height of the rectangle
                const margin = 8; // Margin amount on each side
                const rectWidth = length + margin * 2;
                const rectHeight = 16; // Adjust as needed

                return (
                  <g
                    key={doorIndex}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      setSelectedDoor({ roomIndex, doorIndex });
                      setDraggingDoor(true);
                    }}
                    onTouchStart={(e) => {
                      e.stopPropagation();
                      setSelectedDoor({ roomIndex, doorIndex });
                      setDraggingDoor(true);
                    }}
                  >
                    {isSelected && (
                      <rect
                        x={centerX - rectWidth / 2}
                        y={centerY - rectHeight / 2}
                        width={rectWidth}
                        height={rectHeight}
                        fill="none"
                        stroke="#3a80c7"
                        strokeWidth="1"
                        transform={`rotate(${angle}, ${centerX}, ${centerY})`}
                      />
                    )}
                    <line
                      x1={door.start.x}
                      y1={door.start.y}
                      x2={door.end.x}
                      y2={door.end.y}
                      stroke={activeRoomIndex !== null ? "white" : "#F5F5F5"}
                      strokeWidth={"8.5"}
                    />
                    <line
                      x1={offsetStart.x}
                      y1={offsetStart.y}
                      x2={offsetEnd.x}
                      y2={offsetEnd.y}
                      stroke={"#F5F5F5"}
                      strokeWidth={"7.6"}
                      clipPath={`url(#clipRoom${roomIndex})`}
                    />
                  </g>
                );
              })}
          </g>
        );
      })}
    </>
  );
};

export default Doors;
