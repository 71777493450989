import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SingleRoomSvg from "./SingleRoomSvg";
import MuiSnackbar from "../UI/MuiSnackbar";
import {
  calculateArea,
  calculateOffsetVertices
} from "../../utils/svgFunctions";

const SNAP_THRESHOLD = 4; // Proximity range to detect vertices

const LfAreaMeasure = () => {
  const selectedFloor = useSelector((state) => state.floorPlan.selectedFloor);
  const drawingRooms = useSelector((state) => state.floorPlan.drawingRooms);

  const rooms = drawingRooms[selectedFloor?.name];
  const svgRef = useRef(null);
  const groupRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [panning, setPanning] = useState(false);
  const [zooming, setZooming] = useState(false);
  const [panStart, setPanStart] = useState({ x: 0, y: 0 });
  const [hoveredRoomIndex, setHoveredRoomIndex] = useState(null);

  const [points, setPoints] = useState([]); // State to store points for measuring line
  const [lines, setLines] = useState([]); // State to store lines drawn
  const [lengths, setLengths] = useState([]); // State to store lengths of lines
  const [currentMousePosition, setCurrentMousePosition] = useState(null); // Track current mouse position
  const [elasticLineLength, setElasticLineLength] = useState(null); // Store the length of the elastic line

  const [undoStack, setUndoStack] = useState([]); // Stack to store previous states for undo
  const [redoStack, setRedoStack] = useState([]); // Stack to store undone states for redo
  // Mode state to switch between Linear Feet (LF) and Area calculation
  const [mode, setMode] = useState("LF"); // 'LF' or 'Area'

  const [hoveredVertex, setHoveredVertex] = useState(null); // Store hovered vertex for highlighting

  // opening an alert after prodcut add
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [type, setType] = React.useState("");

  useEffect(() => {
    function preventBehavior(e) {
      e.preventDefault();
    }

    const currentElement = svgRef.current;

    if (currentElement) {
      currentElement.addEventListener("touchmove", preventBehavior, {
        passive: false
      });
    }

    return () => {
      if (currentElement) {
        currentElement.removeEventListener("touchmove", preventBehavior);
      }
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (e) => {
      console.log("e-", e);
      // Check for both Control (Windows/Linux) and Command (Mac)
      if ((e.ctrlKey || e.metaKey) && e.key === "z") {
        e.preventDefault();
        handleUndo();
      } else if ((e.ctrlKey || e.metaKey) && e.key === "x") {
        e.preventDefault();
        handleRedo();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [points, undoStack, redoStack]);

  const handleUndo = () => {
    if (undoStack.length > 0) {
      const previousState = undoStack[undoStack.length - 1];
      setUndoStack(undoStack.slice(0, -1));
      setRedoStack([...redoStack, { points, lines, lengths }]);
      setPoints(previousState.points);
      setLines(previousState.lines);
      setLengths(previousState.lengths);
    }
  };

  const handleRedo = () => {
    if (redoStack.length > 0) {
      const nextState = redoStack[redoStack.length - 1];
      setRedoStack(redoStack.slice(0, -1));
      setUndoStack([...undoStack, { points, lines, lengths }]);
      setPoints(nextState.points);
      setLines(nextState.lines);
      setLengths(nextState.lengths);
    }
  };

  const calculateRoomsBoundingBox = () => {
    let minX = Infinity,
      minY = Infinity,
      maxX = -Infinity,
      maxY = -Infinity;

    rooms.forEach((room) => {
      room.vertices.forEach((v) => {
        if (v.x < minX) minX = v.x;
        if (v.y < minY) minY = v.y;
        if (v.x > maxX) maxX = v.x;
        if (v.y > maxY) maxY = v.y;
      });
    });

    return { minX, minY, maxX, maxY };
  };

  const resetScale = () => {
    const { minX, minY, maxX, maxY } = calculateRoomsBoundingBox();

    // calculate centroid of the rooms bounding box
    const bboxCenter = {
      x: (minX + maxX) / 2,
      y: (minY + maxY) / 2
    };

    const newTranslate = {
      x: svgRef.current?.clientWidth / 2 - bboxCenter.x,
      y: svgRef.current?.clientHeight / 2 - bboxCenter.y
    };

    setScale(1);
    setTranslate(newTranslate);
  };

  useEffect(() => {
    if (selectedFloor) {
      resetScale();
    }
  }, [selectedFloor]);

  const calculateCentroid = (vertices) => {
    let x = 0,
      y = 0;
    vertices.forEach((v) => {
      x += v.x;
      y += v.y;
    });
    return { x: x / vertices.length, y: y / vertices.length };
  };

  const calculatePolygonArea = (vertices) => {
    let area = 0;
    const n = vertices.length;
    for (let i = 0; i < n; i++) {
      const { x: x1, y: y1 } = vertices[i];
      const { x: x2, y: y2 } = vertices[(i + 1) % n];
      area += x1 * y2 - x2 * y1;
    }
    return Math.abs(area / 2) / 400; // Convert to square feet
  };
  const handleWheel = (e) => {
    e.preventDefault();
    setZooming(true);
    const scaleAmount = -e.deltaY * 0.001;
    const newScale = Math.max(0.1, scale + scaleAmount);

    const rect = e.target.getBoundingClientRect();
    const mousePos = { x: e.clientX - rect.left, y: e.clientY - rect.top };

    const newTranslate = {
      x: translate.x - (mousePos.x - translate.x) * (newScale / scale - 1),
      y: translate.y - (mousePos.y - translate.y) * (newScale / scale - 1)
    };

    setScale(newScale);
    setTranslate(newTranslate);
  };

  const handleMouseDown = (e) => {
    setPanning(true);
    setPanStart({
      x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY
    });
  };

  const handleMouseMove = (e) => {
    const rect = svgRef.current.getBoundingClientRect();
    const svgX = (e.clientX - rect.left - translate.x) / scale;
    const svgY = (e.clientY - rect.top - translate.y) / scale;
    setCurrentMousePosition({ x: svgX, y: svgY });

    let nearestVertex = null;
    let nearestDistance = SNAP_THRESHOLD;

    // Check if the mouse is near any room vertices
    rooms?.forEach((room) => {
      const offsetVertices = calculateOffsetVertices(room.vertices, -5);
      offsetVertices.forEach((vertex) => {
        const distance = calculateDistance({ x: svgX, y: svgY }, vertex);
        if (distance < nearestDistance) {
          nearestDistance = distance;
          nearestVertex = vertex;
        }
      });
    });

    // If a vertex is close enough, highlight it
    setHoveredVertex(nearestVertex);

    // If in LF mode, update the elastic line and length
    if (points.length > 0 && mode === "LF") {
      const lastPoint = points[points.length - 1];
      const targetPoint = nearestVertex || { x: svgX, y: svgY };
      const lengthPx = calculateDistance(lastPoint, targetPoint);
      const { feet, inches } = convertPxToFeetInches(lengthPx);
      setElasticLineLength({ feet, inches });
    }
  };

  const handleMouseUp = () => {
    setPanning(false);
  };

  const PIXELS_PER_FOOT = 20;
  const INCHES_PER_FOOT = 12;

  const convertPxToFeetInches = (px) => {
    // Convert pixels to total feet
    const totalFeet = px / PIXELS_PER_FOOT;

    // Convert total feet to integer feet and inches
    const feet = Math.floor(totalFeet);
    const remainingFeet = totalFeet - feet;

    // Convert the remaining feet to inches
    const inches = remainingFeet * INCHES_PER_FOOT;

    return { feet, inches: inches.toFixed(0) }; // Round inches to 2 decimal places
  };

  const clearSelections = () => {
    setUndoStack([...undoStack, { points, lines, lengths }]); // Save the current state in the undo stack
    setPoints([]); // Clear points
    setLines([]); // Clear lines
    setLengths([]); // Clear lengths
    setElasticLineLength(null); // Clear elastic line length
    setRedoStack([]); // Clear the redo stack
  };
  useEffect(() => {
    if (groupRef.current) {
      groupRef.current.style.transform = `translate(${translate.x}px, ${translate.y}px) scale(${scale})`;
      if (!panning && !zooming) {
        groupRef.current.style.transition = "transform 400ms ease-in-out";
      } else {
        setZooming(false);
        groupRef.current.style.transition = "none";
      }
    }
  }, [translate, scale, panning, zooming]);

  const [initialDistance, setInitialDistance] = useState(null);
  const [initialScale, setInitialScale] = useState(1);

  const calculateDistance = (point1, point2) => {
    if (!point1 || !point2) return 0; // Check if both points are valid
    const dx = point2.x - point1.x;
    const dy = point2.y - point1.y;
    return Math.sqrt(dx * dx + dy * dy); // Return a number
  };

  const handleTouchStart = (e) => {
    if (e.touches.length === 2) {
      const distance = calculateDistance(e.touches[0], e.touches[1]);
      setInitialDistance(distance);
      setInitialScale(scale);
    }
  };
  const handleTouchMove = (e) => {
    if (e.touches.length === 2) {
      const distance = calculateDistance(e.touches[0], e.touches[1]);
      if (initialDistance) {
        const scaleFactor = distance / initialDistance;
        const newScale = initialScale * scaleFactor;

        // Calculate the midpoint between the two touch points
        const midpoint = {
          x: (e.touches[0].clientX + e.touches[1].clientX) / 2,
          y: (e.touches[0].clientY + e.touches[1].clientY) / 2
        };

        // Adjust translate values to zoom around the midpoint
        const rect = svgRef.current.getBoundingClientRect();
        const svgMidpoint = {
          x: (midpoint.x - rect.left - translate.x) / scale,
          y: (midpoint.y - rect.top - translate.y) / scale
        };

        const newTranslate = {
          x: translate.x - svgMidpoint.x * (newScale - scale),
          y: translate.y - svgMidpoint.y * (newScale - scale)
        };

        setScale(newScale);
        setTranslate(newTranslate);
      }
    }
  };

  const handleTouchEnd = (e) => {
    if (e.touches.length < 2) {
      setInitialDistance(null);
    }
  };

  const handleSvgClick = (e) => {
    const rect = svgRef.current.getBoundingClientRect();
    const svgX = (e.clientX - rect.left - translate.x) / scale;
    const svgY = (e.clientY - rect.top - translate.y) / scale;

    const targetPoint = hoveredVertex || { x: svgX, y: svgY }; // Snap to the vertex if available

    setUndoStack([...undoStack, { points, lines, lengths }]); // Save current state in undo stack
    setRedoStack([]); // Clear redo stack after any new action

    if (mode === "LF") {
      setPoints((prevPoints) => [...prevPoints, targetPoint]);
      if (points.length > 0) {
        const newLine = {
          start: points[points.length - 1],
          end: targetPoint
        };
        setLines((prevLines) => [...prevLines, newLine]);
        const length = calculateDistance(
          points[points.length - 1],
          targetPoint
        );
        const { feet, inches } = convertPxToFeetInches(length);
        setLengths((prevLengths) => [...prevLengths, { feet, inches }]);
      }
      setElasticLineLength(null);
    }

    if (mode === "Area") {
      setPoints((prevPoints) => [...prevPoints, targetPoint]);
    }
  };

  const calculateTotalLength = () => {
    const totalPx = lines.reduce((total, line) => {
      return total + calculateDistance(line.start, line.end); // Correct calculation
    }, 0);

    return convertPxToFeetInches(totalPx); // Convert the total length in pixels to feet and inches
  };

  const calculateArea1 = () => {
    if (points.length < 3) return 0; // Need at least 3 points for a polygon
    return calculatePolygonArea([...points, currentMousePosition]);
  };

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      onWheel={handleWheel}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onClick={handleSvgClick}
      onTouchStart={(e) => {
        handleMouseDown(e);
        handleTouchStart(e);
      }}
      onTouchMove={(e) => {
        handleMouseMove(e);
        handleTouchMove(e);
      }}
      onTouchEnd={(e) => {
        handleMouseUp(e);
        handleTouchEnd(e);
      }}
    >
      {/* Tabs for Linear Feet and Area */}
      <div className="absolute top-0 left-0 flex space-x-4 p-2 bg-gray-200 z-[1000]">
        <button
          className={`px-4 py-2 ${
            mode === "LF" ? "bg-blue-500 text-white" : "bg-white"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            setMode("LF");
          }}
        >
          LF
        </button>
        <button
          className={`px-4 py-2 ${
            mode === "Area" ? "bg-blue-500 text-white" : "bg-white"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            setMode("Area");
          }}
        >
          Area
        </button>
      </div>

      <svg
        ref={svgRef}
        width="100%"
        height="100%"
        style={{ overflow: "hidden" }}
      >
        <defs>
          {rooms.map((room, roomIndex) => {
            // Create a composite mask that excludes the current room
            const otherRooms = rooms.filter((_, index) => index !== roomIndex);

            return (
              <mask id={`maskRoom-${roomIndex}`} key={roomIndex}>
                <rect width="100%" height="100%" fill="white" />
                {otherRooms.map((otherRoom, otherRoomIndex) => (
                  <polygon
                    key={otherRoomIndex}
                    points={otherRoom.vertices
                      .map((v) => `${v.x},${v.y}`)
                      .join(" ")}
                    fill="black"
                  />
                ))}
              </mask>
            );
          })}
        </defs>
        <g ref={groupRef}>
          {rooms?.map((room, roomIndex) => {
            const centroid = calculateCentroid(room.vertices);
            const offsetVertices = calculateOffsetVertices(room.vertices, 8); // Adjust the offset value as needed
            const verticeCircle = calculateOffsetVertices(room.vertices, -4);
            return (
              <SingleRoomSvg
                roomIndex={roomIndex}
                setHoveredRoomIndex={setHoveredRoomIndex}
                room={room}
                hoveredRoomIndex={hoveredRoomIndex}
                calculateCentroid={calculateCentroid}
                calculateArea={calculateArea}
                setOpen={setOpen}
                setMessage={setMessage}
                setType={setType}
                offsetVertices={offsetVertices}
              />
            );
          })}
          {rooms?.map((room, roomIndex) => {
            return (
              <g key={roomIndex} id={`group-${roomIndex}`}>
                {" "}
                {room?.doors &&
                  room.doors.map((door, doorIndex) => {
                    // Calculate direction vector
                    const direction = {
                      x: door.end.x - door.start.x,
                      y: door.end.y - door.start.y
                    };

                    // Calculate the length of the direction vector
                    const length = Math.sqrt(
                      direction.x ** 2 + direction.y ** 2
                    );

                    // Normalize the direction vector
                    const unitDirection = {
                      x: direction.x / length,
                      y: direction.y / length
                    };

                    // Calculate the perpendicular vector (for offsetting)
                    const perpendicular = {
                      x: -unitDirection.y,
                      y: unitDirection.x
                    };

                    // Offset amount
                    const offsetAmount = -6; // Adjust as needed

                    // Calculate the offset start and end points
                    const offsetStart = {
                      x: door.start.x + perpendicular.x * offsetAmount,
                      y: door.start.y + perpendicular.y * offsetAmount
                    };

                    const offsetEnd = {
                      x: door.end.x + perpendicular.x * offsetAmount,
                      y: door.end.y + perpendicular.y * offsetAmount
                    };

                    return (
                      <g key={doorIndex}>
                        <line
                          x1={door.start.x}
                          y1={door.start.y}
                          x2={door.end.x}
                          y2={door.end.y}
                          stroke={"#F5F5F5"}
                          strokeWidth={"8.5"}
                        />
                        <line
                          x1={offsetStart.x}
                          y1={offsetStart.y}
                          x2={offsetEnd.x}
                          y2={offsetEnd.y}
                          stroke={"#F5F5F5"}
                          strokeWidth={"7.6"}
                          clipPath={`url(#clipRoom${roomIndex})`}
                        />
                      </g>
                    );
                  })}
              </g>
            );
          })}

          {/* Render lines between points */}
          {lines.map((line, index) => (
            <g key={index}>
              <line
                x1={line.start.x}
                y1={line.start.y}
                x2={line.end.x}
                y2={line.end.y}
                stroke="#FF5733"
                strokeWidth="2"
              />
              <text
                x={(line.start.x + line.end.x) / 2}
                y={(line.start.y + line.end.y) / 2}
                fill="#FF5733"
              >
                {lengths[index].feet}' {lengths[index].inches}"
              </text>
            </g>
          ))}

          {/* Render circles at each point */}
          {points.map((point, index) => (
            <circle
              key={index}
              cx={point.x}
              cy={point.y}
              r="5"
              fill="blue"
              stroke="black"
              strokeWidth="1"
            />
          ))}

          {/* Highlight hovered vertex */}
          {hoveredVertex && (
            <circle
              cx={hoveredVertex.x}
              cy={hoveredVertex.y}
              r="5"
              fill="yellow"
              stroke="red"
              strokeWidth="2"
            />
          )}

          {/* Render elastic/stretching line */}
          {points.length > 0 && currentMousePosition && (
            <g>
              <line
                x1={points[points.length - 1].x}
                y1={points[points.length - 1].y}
                x2={currentMousePosition.x}
                y2={currentMousePosition.y}
                stroke="#0096FF"
                strokeWidth="2"
                strokeDasharray="5,5" // Dashed line for elastic effect
              />
              {elasticLineLength && (
                <text
                  x={(points[points.length - 1].x + currentMousePosition.x) / 2}
                  y={(points[points.length - 1].y + currentMousePosition.y) / 2}
                  fill="#0096FF"
                >
                  {elasticLineLength.feet}' {elasticLineLength.inches}"
                </text>
              )}
              {/* Render polygon area when in Area mode */}
              {mode === "Area" && points.length > 2 && (
                <polygon
                  points={[...points, currentMousePosition]
                    .map((p) => `${p.x},${p.y}`)
                    .join(" ")}
                  fill="rgba(0, 255, 0, 0.3)" // Transparent green
                  stroke="green"
                  strokeWidth="2"
                />
              )}
            </g>
          )}
        </g>
      </svg>

      <div className="absolute top-[5%] left-[45%] flex flex-col space-y-3 z-[1000]">
        {mode === "LF" ? (
          <div style={{ padding: "10px" }}>
            <strong>Total Length:</strong> {calculateTotalLength().feet}'{" "}
            {calculateTotalLength().inches}"
          </div>
        ) : (
          <div style={{ padding: "10px" }}>
            <strong>Area:</strong> {calculateArea1()} sq ft
          </div>
        )}
      </div>
      {/* Display total length below the SVG */}

      <div className="absolute top-[32%] left-0 flex flex-col space-y-3 z-[1000]">
        {/* Clear button */}
        <button
          onClick={(e) => {
            e.stopPropagation(); // Prevent click event from propagating to the SVG
            clearSelections();
          }}
          style={{
            padding: "10px",
            backgroundColor: "#f44336",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer"
          }}
        >
          Clear
        </button>
      </div>
      <MuiSnackbar
        open={open}
        message={message || ""}
        type={type || ""}
        onClose={() => setOpen(false)}
        duration={2000}
      />
    </div>
  );
};

export default LfAreaMeasure;
