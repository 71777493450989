import React from "react";
import { convertToFeetAndInches } from "../../../utils/FloorPlanFunc/FloorPlanFunc";

const drawDoorDimensions = (doors, doorDimensionOffset) => {
  const triangleSize = 3;

  const drawTriangle = (x, y, angle) => {
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);
    return `
      M ${x} ${y}
      L ${x - triangleSize * cos + triangleSize * sin} ${
      y - triangleSize * sin - triangleSize * cos
    }
      L ${x - triangleSize * cos - triangleSize * sin} ${
      y - triangleSize * sin + triangleSize * cos
    }
      Z
    `;
  };

  return doors.map((door, index) => {
    const length = Math.sqrt(
      Math.pow(door.end.x - door.start.x, 2) +
        Math.pow(door.end.y - door.start.y, 2)
    );
    const midX = (door.start.x + door.end.x) / 2;
    const midY = (door.start.y + door.end.y) / 2;
    const angle = Math.atan2(
      door.end.y - door.start.y,
      door.end.x - door.start.x
    );
    const offsetX = doorDimensionOffset * Math.cos(angle - Math.PI / 2);
    const offsetY = doorDimensionOffset * Math.sin(angle - Math.PI / 2);

    const { feet, inches } = convertToFeetAndInches(length);
    const dimensionText = `${feet}'${inches}"`;
    const textX = midX + offsetX * 1.5;
    const textY = midY + offsetY * 1.5;

    return (
      <g key={index}>
        {/* Reference dotted lines */}
        <line
          x1={door.start.x}
          y1={door.start.y}
          x2={door.start.x + offsetX}
          y2={door.start.y + offsetY}
          stroke="#5c5c5c"
          strokeWidth="0.5"
          strokeDasharray="1.5,1.2"
        />
        <line
          x1={door.end.x}
          y1={door.end.y}
          x2={door.end.x + offsetX}
          y2={door.end.y + offsetY}
          stroke="#5c5c5c"
          strokeWidth="0.5"
          strokeDasharray="1.5,1.2"
        />

        {/* Dimension line */}
        <line
          x1={door.start.x + offsetX}
          y1={door.start.y + offsetY}
          x2={door.end.x + offsetX}
          y2={door.end.y + offsetY}
          stroke="#5c5c5c"
          strokeWidth="1"
        />
        <path
          d={drawTriangle(
            door.start.x + offsetX,
            door.start.y + offsetY,
            angle + Math.PI
          )}
          fill="#5c5c5c"
        />
        <path
          d={drawTriangle(door.end.x + offsetX, door.end.y + offsetY, angle)}
          fill="#5c5c5c"
        />
        <text
          x={textX}
          y={textY}
          fontSize="8"
          fill="#5c5c5c"
          alignmentBaseline="middle"
          textAnchor="middle"
        >
          {dimensionText}
        </text>
      </g>
    );
  });
};

export default drawDoorDimensions;
