import React from "react";
import DialogModal from "./DialogModal";
import StaircaseShapeSelectModalContent from "./StaircaseShapeSelectModalContent";

const StaircaseShapeSelectModal = (props) => {
  const { open, onClose, selectedStaircase, mainModalOnClose } = props;

  return (
    <DialogModal
      width="40%"
      open={open}
      onClose={onClose}
      title="Select Staircase Shape"
      ModalContent={StaircaseShapeSelectModalContent}
      childComponentProps={{ onClose, selectedStaircase, mainModalOnClose }}
    />
  );
};

export default StaircaseShapeSelectModal;
