import { calculateCentroid } from "../svgFunctions";

const tolerance = 1;
export const mergeRoomsVertices = (room1, room2) => {
  const vertices1 = room1.vertices;
  const vertices2 = room2.vertices;

  const combinedVertices = [];
  const intersectionPoints = [];

  // Find and add intersection points
  for (let i = 0; i < vertices1.length; i++) {
    const vertex1 = vertices1[i];
    const nextVertex1 = vertices1[(i + 1) % vertices1.length];

    for (let j = 0; j < vertices2.length; j++) {
      const vertex2 = vertices2[j];
      const nextVertex2 = vertices2[(j + 1) % vertices2.length];

      if (doIntersect(vertex1, nextVertex1, vertex2, nextVertex2)) {
        // When calling findIntersection, pass the vertices of the rooms
        const intersection = findIntersection(
          vertex1,
          nextVertex1,
          vertex2,
          nextVertex2,
          [...vertices1, ...vertices2]
        );
        if (intersection) {
          intersectionPoints.push(intersection);
        }
      }
    }
  }

  // Integrate intersection points into vertices1
  const addIntersections = (vertices, intersections) => {
    const newVertices = [];

    const isBetween = (point, start, end) => {
      if (
        JSON.stringify(point) === JSON.stringify(start) ||
        JSON.stringify(point) === JSON.stringify(end)
      ) {
        return false;
      }

      const crossProduct =
        (point.y - start.y) * (end.x - start.x) -
        (point.x - start.x) * (end.y - start.y);
      if (Math.abs(crossProduct) > Number.EPSILON) return false;

      const dotProduct =
        (point.x - start.x) * (end.x - start.x) +
        (point.y - start.y) * (end.y - start.y);
      if (dotProduct < 0) return false;

      const squaredLength =
        (end.x - start.x) * (end.x - start.x) +
        (end.y - start.y) * (end.y - start.y);
      if (dotProduct > squaredLength) return false;

      return true;
    };

    const distance = (p1, p2) => {
      return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    };

    for (let i = 0; i < vertices.length; i++) {
      const vertex = vertices[i];
      const nextVertex = vertices[(i + 1) % vertices.length];
      newVertices.push(vertex);

      const pointsBetween = intersections.filter((point) =>
        isBetween(point, vertex, nextVertex)
      );

      pointsBetween.sort((a, b) => distance(vertex, a) - distance(vertex, b));

      pointsBetween.forEach((point) => {
        if (
          !newVertices.some((v) => JSON.stringify(v) === JSON.stringify(point))
        ) {
          newVertices.push(point);
        }
      });
    }

    return newVertices;
  };

  const newVertices1 = addIntersections(vertices1, intersectionPoints);
  const newVertices2 = addIntersections(vertices2, intersectionPoints);

  // Combine all vertices
  combinedVertices.push(...newVertices1, ...newVertices2);

  // Remove duplicate vertices
  const uniqueVertices1 = shiftVertices(
    Array.from(new Set(newVertices1.map((v) => JSON.stringify(v)))).map((str) =>
      JSON.parse(str)
    ),
    room2.vertices
  );
  const uniqueVertices2 = shiftVertices(
    Array.from(new Set(newVertices2.map((v) => JSON.stringify(v)))).map((str) =>
      JSON.parse(str)
    ),
    room1.vertices
  );

  // Function to merge vertices from two arrays based on common points
  const mergeVerticesBasedOnCommonPoints = (arr1, arr2) => {
    const mergedArray = [];

    for (let i = 0; i < arr1.length; i++) {
      const vertex1 = arr1[i];
      const vertex1Str = JSON.stringify(vertex1);

      // Check if the current vertex is found in arr2
      const indexInArr2 = arr2.findIndex(
        (vertex2) => JSON.stringify(vertex2) === vertex1Str
      );

      if (indexInArr2 === -1) {
        // If not found in arr2 and not already added in mergedArray, add the vertex to the mergedArray
        if (!mergedArray.some((v) => JSON.stringify(v) === vertex1Str)) {
          mergedArray.push(vertex1);
        }
      } else {
        // If found in arr2, add the current vertex
        const vertex2Str = JSON.stringify(arr2[indexInArr2]);
        if (!mergedArray.some((v) => JSON.stringify(v) === vertex2Str)) {
          mergedArray.push(arr2[indexInArr2]);
        }

        // Check the next vertex in arr2
        const nextIndex = (indexInArr2 + 1) % arr2.length;
        const nextVertexStr = JSON.stringify(arr2[nextIndex]);

        // If the next vertex is found in the intermediate vertices, skip the logic and return to the main loop
        if (arr1.some((vertex) => JSON.stringify(vertex) === nextVertexStr)) {
          continue;
        }

        // Add the remaining vertices from arr2 starting from the common point
        for (let j = nextIndex; j < arr2.length; j++) {
          const vertex2Str = JSON.stringify(arr2[j]);
          if (!mergedArray.some((v) => JSON.stringify(v) === vertex2Str)) {
            mergedArray.push(arr2[j]);
          }
        }
        // Then add the vertices from the start of arr2 to the common point
        for (let j = 0; j < indexInArr2; j++) {
          const vertex2Str = JSON.stringify(arr2[j]);
          if (!mergedArray.some((v) => JSON.stringify(v) === vertex2Str)) {
            mergedArray.push(arr2[j]);
          }
        }
      }
    }

    return mergedArray;
  };

  // Merge vertices from both arrays based on common points
  const combinedVerticesFinal = mergeVerticesBasedOnCommonPoints(
    uniqueVertices1,
    uniqueVertices2
  );
  const finalVertex = Array.from(
    new Set(combinedVerticesFinal.map((v) => JSON.stringify(v)))
  ).map((str) => JSON.parse(str));

  // Sort vertices in clockwise order
  const sortedVertices = sortVerticesClockwise(finalVertex);

  return combinedVerticesFinal;
};

export const findIntersection = (p1, q1, p2, q2, vertices) => {
  const A1 = q1.y - p1.y;
  const B1 = p1.x - q1.x;
  const C1 = A1 * p1.x + B1 * p1.y;

  const A2 = q2.y - p2.y;
  const B2 = p2.x - q2.x;
  const C2 = A2 * p2.x + B2 * p2.y;

  const determinant = A1 * B2 - A2 * B1;

  if (determinant === 0) {
    // Lines are parallel
    return null;
  } else {
    const x = (B2 * C1 - B1 * C2) / determinant;
    const y = (A1 * C2 - A2 * C1) / determinant;
    const intersection = { x, y };

    // Check if the intersection is close to any of the room vertices
    const closeVertex = isCloseToVertex(intersection, vertices);
    return closeVertex || intersection;
  }
};
const isCloseToVertex = (point, vertices) => {
  return vertices.find((vertex) => {
    const distance = Math.sqrt(
      Math.pow(vertex.x - point.x, 2) + Math.pow(vertex.y - point.y, 2)
    );
    return distance < tolerance;
  });
};

export const doIntersect = (p1, q1, p2, q2) => {
  const o1 = orientation(p1, q1, p2);
  const o2 = orientation(p1, q1, q2);
  const o3 = orientation(p2, q2, p1);
  const o4 = orientation(p2, q2, q1);

  // General case
  if (o1 !== o2 && o3 !== o4) return true;

  // Special Cases
  if (o1 === 0 && onSegment(p1, p2, q1)) return true;
  if (o2 === 0 && onSegment(p1, q2, q1)) return true;
  if (o3 === 0 && onSegment(p2, p1, q2)) return true;
  if (o4 === 0 && onSegment(p2, q1, q2)) return true;

  return false; // Doesn't fall in any of the above cases
};

export const orientation = (p, q, r) => {
  const val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
  if (val === 0) return 0; // collinear
  return val > 0 ? 1 : 2; // clock or counterclock wise
};

export const shiftVertices = (vertices, otherVertices) => {
  if (vertices.length === 0) return vertices;

  let shiftedVertices = vertices;
  const otherVerticesStrSet = new Set(
    otherVertices.map((v) => JSON.stringify(v))
  );

  let newVertex = shiftedVertices[shiftedVertices.length - 1];
  if (otherVerticesStrSet.has(JSON.stringify(newVertex))) {
    // Rotate by two steps if the newVertex is found
    shiftedVertices = [
      shiftedVertices[shiftedVertices.length - 2],
      shiftedVertices[shiftedVertices.length - 1],
      ...shiftedVertices.slice(0, shiftedVertices.length - 2)
    ];
  } else {
    // Rotate by one step if the newVertex is not found
    shiftedVertices = [
      shiftedVertices[shiftedVertices.length - 1],
      ...shiftedVertices.slice(0, shiftedVertices.length - 1)
    ];
  }

  return shiftedVertices;
};

export const sortVerticesClockwise = (vertices) => {
  const center = calculateCentroid(vertices);

  return vertices.slice().sort((a, b) => {
    const angleA = Math.atan2(a.y - center.y, a.x - center.x);
    const angleB = Math.atan2(b.y - center.y, b.x - center.x);

    if (angleA === angleB) {
      // This case handles collinear points
      const distanceA = Math.sqrt(
        (a.x - center.x) ** 2 + (a.y - center.y) ** 2
      );
      const distanceB = Math.sqrt(
        (b.x - center.x) ** 2 + (b.y - center.y) ** 2
      );
      return distanceA - distanceB;
    }

    return angleA - angleB;
  });
};

export const onSegment = (p, q, r) => {
  return (
    q.x <= Math.max(p.x, r.x) &&
    q.x >= Math.min(p.x, r.x) &&
    q.y <= Math.max(p.y, r.y) &&
    q.y >= Math.min(p.y, r.y)
  );
};
