import React from "react";
import WallLengthField from "../../UI/WallLengthField";
import { Button, Stack } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

const WallLengthControls = ({
  feet,
  inches,
  handleLengthChange,
  unlockWall,
  lockedWall,
  setFeet,
  setInches
}) => (
  <div className="absolute top-[10px] left-[10px] w-[170px] bg-white p-[10px] border-[1px] border-gray-300 rounded-md space-y-3">
    <div className="!text-[16px]">Wall Length</div>
    <Stack direction="row" spacing={1}>
      <WallLengthField
        label="Feet"
        type="number"
        value={feet}
        onChange={(e) => setFeet(e.target.value)}
        disabled={lockedWall}
      />
      <WallLengthField
        label="Inches"
        type="number"
        value={inches}
        onChange={(e) => setInches(e.target.value)}
        disabled={lockedWall}
      />
    </Stack>
    <Button
      onClick={handleLengthChange}
      variant="contained"
      disabled={lockedWall}
      sx={{ fontSize: "10px", gap: "6px", mt: "10px" }}
    >
      <LockIcon className="!w-4 !h-4" />
      Lock Wall
    </Button>
    {lockedWall && (
      <Button
        onClick={unlockWall}
        variant="contained"
        sx={{ fontSize: "10px", gap: "6px", mt: "10px" }}
      >
        Unlock Wall
      </Button>
    )}
  </div>
);

export default WallLengthControls;
