import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import GetIconFile from "../../assets/GetIconFile";

const DropZone = ({
  className,
  setImagePreview,
  style = {},
  type = "image",
  accept
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        setImagePreview(file);
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const dataURL = reader.result;

          // Set the image preview using setImagePreview function
        };

        reader.readAsDataURL(file); // Read the file as a data URL
      });
    },
    [setImagePreview]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      type === "image"
        ? {
            "image/*": []
          }
        : { "video/*": [] }
  });
  return (
    <div {...getRootProps()} className={className || ""} style={style}>
      <input {...getInputProps()} />
      <div className="flex flex-col relative">
        <GetIconFile
          data={{ width: "70px", height: "70px" }}
          iconName="camera-icon"
        />
        {/* <img src="/images/takePhotoIcon.svg" /> */}
        <p className="text-[#D8D8D8] absolute -bottom-[4px] w-[120px] -right-[23px]">
          {type === "image" ? "Upload Photo" : "Upload Video"}
        </p>
      </div>
    </div>
  );
};

export default DropZone;
