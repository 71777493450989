import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { CircularProgress } from "@mui/material";
import MotionButton from "../UI/MotionButton";
import RoomSelectModal from "./RoomSelectModal";
import RenderRooms from "./RenderRooms";
import RenderStaircase from "./RenderStaircase";

const FloorWithRooms = (props) => {
  const { isSaving, saveFloorPlanData, closeMainModal } = props;

  const [openSelectRoomModal, setOpenSelectRoomModal] = useState(false);

  const selectedFloor = useSelector((state) => state.floorPlan.selectedFloor);
  const drawingRooms = useSelector((state) => state.floorPlan.drawingRooms);
  const selectedST = useSelector((state) => state.floorPlan.selectedST);
  const floorStaircases = useSelector(
    (state) => state.floorPlan.floorStaircases
  );
  const initialFloorStaircases = useSelector(
    (state) => state.floorPlan.initialFloorStaircases
  );
  const initialDrawingRooms = useSelector(
    (state) => state.floorPlan.initialDrawingRooms
  );

  const handleSelectRoomForDraw = useCallback(() => {
    setOpenSelectRoomModal(true);
  }, [setOpenSelectRoomModal]);

  const isDataChanged = useMemo(() => {
    return (
      !_.isEqual(drawingRooms, initialDrawingRooms) ||
      !_.isEqual(floorStaircases, initialFloorStaircases)
    );
  }, [
    drawingRooms,
    initialDrawingRooms,
    floorStaircases,
    initialFloorStaircases,
  ]);

  const makeSaveBtnDisable = useMemo(() => {
    return !isDataChanged || isSaving;
  }, [isDataChanged, isSaving]);

  return (
    <div>
      {selectedFloor && (
        <div className="mt-2">
          {selectedST ? <RenderStaircase /> : <RenderRooms />}
        </div>
      )}
      <div className="flex flex-col justify-end space-y-2 absolute w-full bottom-2">
        {selectedFloor && (
          <MotionButton
            className="border-[1px] bg-[#1e2e5a] text-white border-gray-400 w-full"
            onClick={handleSelectRoomForDraw}
          >
            Draw A Room
          </MotionButton>
        )}
        <div className="flex gap-2 w-full">
          <MotionButton
            className={`flex justify-center items-center border-[1px] border-[#1e2e5a] text-black !min-w-[105px] ${
              makeSaveBtnDisable && "bg-gray-400 text-white border-none"
            }`}
            onClick={saveFloorPlanData}
            disabled={makeSaveBtnDisable}
          >
            {isSaving ? <CircularProgress size={15} /> : "Save"}
          </MotionButton>
          <MotionButton
            className="border-[1px] border-[#1e2e5a] text-black !min-w-[102px]"
            onClick={closeMainModal}
          >
            Close
          </MotionButton>
        </div>
      </div>
      <RoomSelectModal
        open={openSelectRoomModal}
        onClose={() => setOpenSelectRoomModal(false)}
      />
    </div>
  );
};

export default FloorWithRooms;
