import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Constants } from "../../utils/Constants";

const StyledInput = styled(TextField)(({ hasFixWidth, isValid }) => ({
  "&.MuiTextField-root": {
    width: hasFixWidth && "64px",
    "& .MuiInputBase-input": {
      padding: "14px",
      fontSize: "13px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      borderColor: !isValid ? Constants.MANDATE_BORDER_COLOR : undefined,
    },
    "& .css-lccy1c-MuiGrid-root": {
      paddingRight: "0",
    },
    "& .MuiInputLabel-root": {
      color: "#202020",
      fontSize: "13px",
      marginTop: "-2px",
    },
    "& .MuiInputLabel-shrink": {
      marginTop: "2px",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset>legend": {
      fontSize: "10px",
    },
    // Adding styles for disabled state
    "&.Mui-disabled": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#d3d3d3", // Light gray border color
      },
      "& .MuiInputBase-input": {
        backgroundColor: "#cccccc", // Light gray background color
        color: "#a9a9a9", // Light gray text color
        borderRadius: "10px",
      },
      "& .MuiInputLabel-root": {
        color: "#a9a9a9", // Light gray label color
      },
    },
  },
}));

const StaircaseToNameInput = ({
  inputVal,
  id,
  name,
  type,
  placeholder,
  label,
  rules,
  errors,
  register,
  disabled,
  errorMsg,
  value,
  readOnlyType,
  onFocusEvent,
  inputGroupText,
  copyIcon,
  groupClassName,
  className,
  pattern,
  option,
  variant = "outlined",
  height,
  hasPadding,
  error,
  hasFixWidth,
  onBlur,
  ...restVal
}) => {
  const { ref, ...rest } = register?.(name, rules || {});
  const [focused, setFocused] = useState(Boolean(value));

  useEffect(() => {
    if (value) {
      setFocused(true);
    } else {
      setFocused(false);
    }
  }, [value]);

  const onBlurLocal = (e) => {
    if (!e.target.value) {
      setFocused(false);
    }
    onBlur(e);
  };

  const onFocus = (e) => {
    setFocused(true);
    if (onFocusEvent) {
      onFocusEvent(e);
    }
  };

  return (
    <>
      {label ? <label className="form-label">{label || ""}</label> : null}
      <div className={groupClassName || ""}>
        <StyledInput
          hasFixWidth={hasFixWidth}
          label={placeholder}
          hasPadding={hasPadding}
          variant={variant}
          id={id}
          name={name}
          type={type}
          autoComplete="off"
          inputRef={ref}
          disabled={disabled}
          readOnly={readOnlyType || false}
          onFocus={onFocus}
          onBlur={onBlurLocal}
          className={`${className || ""} ${
            type === "textarea" ? "textarea" : ""
          }`}
          sx={{
            width: "100%",
            "& .MuiFormHelperText-root": {
              color: "red",
            },
          }}
          // {...rest}
          {...restVal}
          InputLabelProps={{ shrink: focused }}
          helperText={error?.message || ""}
        />
      </div>
    </>
  );
};

export default StaircaseToNameInput;
