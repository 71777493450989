import _ from "lodash";
import { STAIRCASE_STEP_HEIGHT } from "../utils/Constants";

export const getIncreamentedAngle = (
  pos,
  center,
  initialAngle,
  setRotationIconColor
) => {
  let angle = Math.atan2(pos.y - center.y, pos.x - center.x);

  if (angle < 0) {
    angle += 2 * Math.PI;
  }
  const angleIncrement = angle - initialAngle;
  const angleDegrees = Math.abs(angle * (180 / Math.PI));

  changeRotationIconColor(angleDegrees, setRotationIconColor);

  const rotatePoint = (point, center, angle) => {
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);
    const dx = point.x - center.x;
    const dy = point.y - center.y;

    return {
      x: center.x + dx * cos - dy * sin,
      y: center.y + dx * sin + dy * cos,
    };
  };

  return { angle, angleIncrement, rotatePoint };
};

export const changeRotationIconColor = (angleDegrees, setRotationIconColor) => {
  const angleInDegreesCondition = getAngleInDergreeCondition(angleDegrees);
  if (angleInDegreesCondition) {
    setRotationIconColor("#9DDE8B");
  } else {
    setRotationIconColor("#3a80c7");
  }
};

export const getAngleInDergreeCondition = (angleDegrees) => {
  return (
    angleDegrees >= 350 ||
    angleDegrees <= 10 ||
    (angleDegrees >= 35 && angleDegrees <= 55) ||
    (angleDegrees >= 80 && angleDegrees <= 100) ||
    (angleDegrees >= 125 && angleDegrees <= 145) ||
    (angleDegrees >= 170 && angleDegrees <= 190) ||
    (angleDegrees >= 215 && angleDegrees <= 235) ||
    (angleDegrees >= 260 && angleDegrees <= 280) ||
    (angleDegrees >= 305 && angleDegrees <= 325)
  );
};

export const getBoundingBox = (vertices) => {
  const xs = vertices.map((v) => v.x);
  const ys = vertices.map((v) => v.y);
  return {
    x1: Math.min(...xs),
    y1: Math.min(...ys),
    x2: Math.max(...xs),
    y2: Math.max(...ys),
  };
};

const isPointInPolygon = (point, vertices) => {
  const { x, y } = point;
  let isInside = false;
  for (let i = 0, j = vertices?.length - 1; i < vertices?.length; j = i++) {
    const xi = vertices[i].x,
      yi = vertices[i].y;
    const xj = vertices[j].x,
      yj = vertices[j].y;
    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) isInside = !isInside;
  }
  return isInside;
};

export const doBoundingBoxesIntersect = (staircase, roomVertices) => {
  const roomBoundingBox = getBoundingBox(roomVertices);
  const staircaseBoundingBox = {
    x1: staircase.x,
    y1: staircase.y,
    x2: staircase.x + staircase.width,
    y2: staircase.y + staircase.height,
  };

  return !(
    staircaseBoundingBox.x2 < roomBoundingBox.x1 ||
    staircaseBoundingBox.x1 > roomBoundingBox.x2 ||
    staircaseBoundingBox.y2 < roomBoundingBox.y1 ||
    staircaseBoundingBox.y1 > roomBoundingBox.y2
  );
};

export const isStaircaseInRoom = (staircase, roomVertices) => {
  return (
    areAllCornersInPolygon(staircase, roomVertices) ||
    doBoundingBoxesIntersect(staircase, roomVertices)
  );
};

export const areAllCornersInPolygon = (staircase, roomVertices) => {
  const corners = [
    { x: staircase.x, y: staircase.y }, // top-left
    { x: staircase.x + staircase.width, y: staircase.y }, // top-right
    { x: staircase.x, y: staircase.y + staircase.height }, // bottom-left
    { x: staircase.x + staircase.width, y: staircase.y + staircase.height }, // bottom-right
  ];

  const centroid = {
    x: staircase.x + staircase.width / 2,
    y: staircase.y + staircase.height / 2,
  };

  const isCentroidInPolygon = isPointInPolygon(centroid, roomVertices);
  const areCornersInPolygon = corners.every((corner) =>
    isPointInPolygon(corner, roomVertices)
  );

  return isCentroidInPolygon || areCornersInPolygon;
};

export const getLeftStaircases = (floorStaircases, singleCustomerData) => {
  let allStaircases = [];
  Object.keys(floorStaircases).forEach((st) => {
    if (floorStaircases[st]?.length > 0) {
      allStaircases.push(...floorStaircases[st]);
    }
  });

  const leftStaircases = _.filter(
    singleCustomerData?.staircases,
    (staircase) => !_.some(allStaircases, (st) => st.id === staircase.id)
  );

  return leftStaircases;
};

export const getSteps = (staircase) => {
  if (!staircase) return null;

  let steps = 8;
  const openTreads = _.find(
    staircase?.all_questions,
    (s) => s.question === "# of Open Treads"
  );
  const closeTreads = _.find(
    staircase?.all_questions,
    (s) => s.question === "# of Close Treads"
  );

  if (openTreads.answer && Number(openTreads.answer) > 0) {
    steps = Number(openTreads.answer);
  } else if (closeTreads.answer && Number(closeTreads.answer) > 0) {
    steps = Number(closeTreads.answer);
  }

  const heightToSet = steps * STAIRCASE_STEP_HEIGHT;

  return { steps, heightToSet };
};

export const updateStraightStaircaseDimensions = (
  selectedStaircase,
  resizeDirection,
  initialDimensions,
  newDimensions,
  scale,
  updatedSts,
  floorName,
  setSelectedStaircase
) => {
  let newSteps = selectedStaircase.steps;
  if (resizeDirection === "horizontal") {
    newDimensions.width = initialDimensions.width * scale;
  } else if (resizeDirection === "vertical") {
    newDimensions.height = initialDimensions.height * scale;
    newSteps = Math.floor(newDimensions.height / 20); // Ensure minimum step height is 20
  }

  updatedSts[floorName] = updatedSts[floorName]?.map((staircase, i) =>
    i === selectedStaircase.index
      ? { ...staircase, ...newDimensions, steps: newSteps }
      : staircase
  );

  setSelectedStaircase((prev) => ({
    ...prev,
    ...newDimensions,
    steps: newSteps,
  }));

  return updatedSts;
};

export const updateLshapeDimensions = (
  selectedStaircase,
  resizeDirection,
  initialDimensions,
  newDimensions,
  scale,
  updatedSts,
  floorName,
  setSelectedStaircase
) => {
  const newWidth = initialDimensions.width * scale;
  const newHeight = initialDimensions.height * scale;

  let updatedStaircase = {
    ...updatedSts[floorName]?.find((st) => st.id === selectedStaircase.id),
  };

  if (resizeDirection === "incline" || resizeDirection === "horizontal") {
    const newHSteps = Math.floor(newWidth / 20);
    newDimensions.width = newWidth;
    updatedStaircase.horizontalSegment = {
      ...updatedStaircase.horizontalSegment,
      width: newWidth,
      steps: newHSteps,
    };
  }

  if (resizeDirection === "incline" || resizeDirection === "vertical") {
    const newVSteps = Math.floor(newHeight / 20);
    newDimensions.height = newHeight;
    updatedStaircase.verticalSegment = {
      ...updatedStaircase.verticalSegment,
      height: newHeight,
      steps: newVSteps,
    };
  }

  updatedSts[floorName] = updatedSts[floorName]?.map((staircase, i) =>
    i === selectedStaircase.index ? updatedStaircase : staircase
  );

  setSelectedStaircase((prev) => ({
    ...prev,
    ...updatedStaircase,
  }));

  return updatedSts;
};

// check if L shape staircase is inside the room or not
export const isLShapedStaircaseInRoom = (staircase, roomVertices) => {
  return areAllCornersInPolygonForLshape(staircase, roomVertices);
};

export const areAllCornersInPolygonForLshape = (staircase, roomVertices) => {
  const horizontalSegmentCorners = [
    { x: staircase.x, y: staircase.y }, // top-left
    { x: staircase.x + staircase.horizontalSegment.width, y: staircase.y }, // top-right
    { x: staircase.x, y: staircase.y + staircase.horizontalSegment.height }, // bottom-left
    {
      x: staircase.x + staircase.horizontalSegment.width,
      y: staircase.y + staircase.horizontalSegment.height,
    }, // bottom-right
  ];

  const verticalSegmentCorners = [
    { x: staircase.x + staircase.horizontalSegment.width, y: staircase.y }, // top-left
    {
      x:
        staircase.x +
        staircase.horizontalSegment.width +
        staircase.verticalSegment.width,
      y: staircase.y,
    }, // top-right
    {
      x: staircase.x + staircase.horizontalSegment.width,
      y: staircase.y + staircase.verticalSegment.height,
    }, // bottom-left
    {
      x:
        staircase.x +
        staircase.horizontalSegment.width +
        staircase.verticalSegment.width,
      y: staircase.y + staircase.verticalSegment.height,
    }, // bottom-right
  ];

  const allCorners = horizontalSegmentCorners.concat(verticalSegmentCorners);

  const areCornersInPolygon = allCorners.every((corner) =>
    isPointInPolygon(corner, roomVertices)
  );

  return areCornersInPolygon;
};

export const getResizeDirection = (e, resizeDirection) => {
  const touch1 = e.touches[0];
  const touch2 = e.touches[1];
  const deltaX = Math.abs(touch2.clientX - touch1.clientX);
  const deltaY = Math.abs(touch2.clientY - touch1.clientY);

  let newResizeDirection = resizeDirection;
  const threshold = 20; // Adjust the threshold value as needed

  if (!resizeDirection) {
    if (Math.abs(deltaX - deltaY) < threshold) {
      newResizeDirection = "incline";
    } else if (deltaX > deltaY) {
      newResizeDirection = "horizontal";
    } else {
      newResizeDirection = "vertical";
    }
  }

  return { newResizeDirection, deltaX, deltaY };
};

export const calculateCentroidForStaircase = (x, y, width, height) => {
  return {
    x: x + width / 2,
    y: y + height / 2,
  };
};
