import React from "react";
import MergeIcon from "@mui/icons-material/Merge";

import CancelIcon from "@mui/icons-material/Cancel";
import FloorPlanButton from "../../UI/FloorPlanButton";

const DrawingPageSideButtons = ({
  selectedWall,
  activeRoomIndex,
  rotateRoom,
  isMoving,
  moveRoom,
  setIsMoving,
  prepareMergeRoom,
  rooms,
  addDoor,
  handleDeleteDoor,
  shiftRoomLayerDown,
  shiftRoomLayerUp,
  undoStack,
  redoStack,
  undo,
  redo,
  addStaircase,
  shouldAddStaircaseBtnVisible,
  deleteStaircase,
  selectedStaircase,
  openCancelDrawingRoomModal,
  selectedMethod,
  addVertex,
  selectedDoor,
  restoreWall,
  hideWall,
  deleteRoom
}) => {
  return (
    <div className="absolute top-[32%] left-0 flex flex-col space-y-3 z-[1000]">
      {selectedWall && (
        <FloorPlanButton
          onClick={addVertex}
          disabled={!selectedWall}
          className="!justify-start gap-1"
        >
          <img src="/images/corner.svg" className="w-5 h-5" />
          Add Corner
        </FloorPlanButton>
      )}

      {activeRoomIndex !== null && (
        <>
          <FloorPlanButton
            onClick={rotateRoom}
            disabled={activeRoomIndex === null}
            className="gap-1"
          >
            <img src="/images/rotate-icon.svg" className="!w-4 !h-4" />
            Rotate Room
          </FloorPlanButton>
          {!isMoving && (
            <FloorPlanButton
              onClick={moveRoom}
              disabled={activeRoomIndex === null}
              className="!justify-start "
            >
              <img src="/images/move-icon.svg" className="!w-4 !h-4 mr-2" />
              Move Room
            </FloorPlanButton>
          )}
          {isMoving && (
            <FloorPlanButton
              onClick={() => setIsMoving(false)}
              disabled={activeRoomIndex === null}
              className="!justify-start "
            >
              <img
                src="/images/editFloorplan-icon.svg"
                className="!w-4 !h-4 mr-2"
              />
              Editable view
            </FloorPlanButton>
          )}

          {rooms?.length > 1 && (
            <FloorPlanButton
              onClick={prepareMergeRoom}
              disabled={activeRoomIndex === null}
              className="!justify-start !px-2"
            >
              <MergeIcon className="!w-5 !h-5" />
              Merge Room
            </FloorPlanButton>
          )}
          {!isMoving && (
            <FloorPlanButton
              onClick={addDoor}
              disabled={activeRoomIndex === null}
              className="!justify-start !px-2"
            >
              <img src="/images/door.svg" className="w-5 h-5" />
              Add Door
            </FloorPlanButton>
          )}

          {activeRoomIndex !== null && selectedDoor && (
            <FloorPlanButton
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteDoor(
                  selectedDoor?.roomIndex,
                  selectedDoor?.doorIndex
                );
              }}
              disabled={activeRoomIndex === null}
              className="gap-1 text-red-500 !justify-start"
            >
              <img src="/images/delete-icon.svg" className="!w-4 !h-4" />
              Delete Door...
            </FloorPlanButton>
          )}
        </>
      )}
      {activeRoomIndex !== null && (
        <>
          {activeRoomIndex !== rooms.length - 1 && (
            <FloorPlanButton
              onClick={shiftRoomLayerDown}
              disabled={activeRoomIndex === rooms.length - 1}
              className={`!justify-start gap-1  `}
            >
              <img src="/images/layerUp.svg" className="w-5 h-5" />
              Layer Up
            </FloorPlanButton>
          )}{" "}
          {activeRoomIndex !== 0 && (
            <FloorPlanButton
              onClick={shiftRoomLayerUp}
              disabled={activeRoomIndex === 0}
              className={`!justify-start gap-1 `}
            >
              <img src="/images/layerDown.svg" className="w-5 h-5" />
              Layer Down
            </FloorPlanButton>
          )}
        </>
      )}
      {selectedWall && (
        <FloorPlanButton
          onClick={
            selectedWall &&
            rooms[selectedWall.roomIndex].hiddenWalls.includes(
              selectedWall.index
            )
              ? restoreWall
              : hideWall
          }
          disabled={!selectedWall}
          className="gap-1 text-red-500 !justify-start"
        >
          {selectedWall &&
          rooms[selectedWall.roomIndex].hiddenWalls.includes(
            selectedWall.index
          ) ? (
            <>
              <img src="/images/restore-icon.svg" className="!w-4 !h-4" />
              Restore Wall
            </>
          ) : (
            <>
              <img src="/images/delete-icon.svg" className="!w-4 !h-4" />
              Delete Wall
            </>
          )}
        </FloorPlanButton>
      )}

      {(undoStack.length !== 0 || redoStack.length !== 0) && (
        <>
          <FloorPlanButton
            onClick={undo}
            disabled={undoStack.length === 0}
            className={`gap-1  !justify-start ${
              undoStack?.length === 0 && "text-gray-400"
            }`}
          >
            <img src="/images/undo.svg" className="!w-4 !h-4" />
            Undo
          </FloorPlanButton>
          <FloorPlanButton
            onClick={redo}
            disabled={redoStack.length === 0}
            className={`gap-1 !justify-start ${
              redoStack?.length === 0 && "text-gray-400"
            }`}
          >
            <img src="/images/redo.svg" className="!w-4 !h-4" />
            Redo
          </FloorPlanButton>
        </>
      )}

      {activeRoomIndex !== null && (
        <FloorPlanButton
          onClick={deleteRoom}
          disabled={activeRoomIndex === null}
          className="gap-1 text-red-500 !justify-start"
        >
          <img src="/images/delete-icon.svg" className="!w-4 !h-4" />
          Delete Room...
        </FloorPlanButton>
      )}

      <FloorPlanButton
        onClick={addStaircase}
        disabled={activeRoomIndex === -1 || !shouldAddStaircaseBtnVisible}
        className={`!justify-center gap-1 ${
          (activeRoomIndex === -1 || !shouldAddStaircaseBtnVisible) &&
          "!bg-gray-300"
        }`}
      >
        <img src="/images/stairs-icon.svg" className="w-5 h-5" />
        Add Staircase
      </FloorPlanButton>

      {selectedStaircase && (
        <FloorPlanButton
          onClick={deleteStaircase}
          disabled={!selectedStaircase}
          className={`!justify-center gap-1 text-red-500`}
        >
          <>
            <img src="/images/delete-icon.svg" className="!w-4 !h-4" />
            Delete Staircase
          </>
        </FloorPlanButton>
      )}

      {selectedMethod === "corner" && (
        <FloorPlanButton
          onClick={openCancelDrawingRoomModal}
          className="gap-1 !justify-start"
        >
          <CancelIcon className="!w-5 !h-5" />
          Cancel...
        </FloorPlanButton>
      )}
    </div>
  );
};

export default DrawingPageSideButtons;
