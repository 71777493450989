import React from "react";

export const circlePointIcon = (centroid) => {
  return (
    <svg
      x={centroid.x - 20}
      y={centroid.y - 20}
      width="40"
      height="40"
      viewBox="0 0 256 256"
      fill="#d80e0e"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fill="#d80e0e"
          d="M128,99.45c-15.77,0-28.55,12.78-28.55,28.55s12.78,28.55,28.55,28.55,28.55-12.78,28.55-28.55-12.78-28.55-28.55-28.55Z"
        />
        <path
          fill="#d80e0e"
          d="M128,57.37c-38.94,0-70.63,31.68-70.63,70.63s31.68,70.63,70.63,70.63,70.63-31.68,70.63-70.63-31.68-70.63-70.63-70.63ZM128,179.69c-28.55,0-51.69-23.14-51.69-51.69s23.14-51.69,51.69-51.69,51.69,23.14,51.69,51.69-23.14,51.69-51.69,51.69Z"
        />
        <path
          fill="#d80e0e"
          d="M128,17.1c-61.15,0-110.9,49.75-110.9,110.9s49.75,110.9,110.9,110.9,110.9-49.75,110.9-110.9S189.15,17.1,128,17.1ZM128,219.96c-50.79,0-91.96-41.17-91.96-91.96s41.17-91.96,91.96-91.96,91.96,41.17,91.96,91.96-41.17,91.96-91.96,91.96Z"
        />
      </g>
    </svg>
  );
};
