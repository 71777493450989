import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  selectMethod,
  setRoomToDraw,
  setSelectedST,
  setStaircasesForPlan,
  setUpdateFloors
} from "../../redux/floorPlan";
import WallLengthField from "../UI/WallLengthField";
import RoomWithCorners from "./RoomWithCorners";
import CancelConfirmationModal from "./CancelConfirmationModal";
import { Constants } from "../../utils/Constants";
import StraightStaircase from "./StraightStaircase";
import {
  calculateArea,
  calculateCenters,
  calculateCentroid,
  calculateDistance,
  calculateOffsetVertices,
  calculateRoomsBoundingBox,
  distanceToLineSegment,
  isPointInPolygon,
  isPointNearLine,
  rotatePoint,
  snapRoomToHighlightedWall,
  snapToNearest45
} from "../../utils/svgFunctions";
import {
  getAngleInDergreeCondition,
  getIncreamentedAngle,
  getLeftStaircases,
  getResizeDirection,
  isLShapedStaircaseInRoom,
  isStaircaseInRoom,
  updateLshapeDimensions,
  updateStraightStaircaseDimensions
} from "../../helper/svgHelper";
import RotationIcon from "./RotationIcon";
import StaircaseSelectModal from "./StaircaseSelectModal";
import LShapeStaircase from "./LShapeStaircase";
import {
  calculateAngle,
  convertToFeetAndInches,
  isDoorOnWall,
  updateDoorPositions
} from "../../utils/FloorPlanFunc/FloorPlanFunc";
import ConfirmationPopUp from "./ConfirmationPopUp";
import DrawingPageSideButtons from "./SvgCanvasChilds/DrawingPageSideButtons";
import Doors from "./SvgCanvasChilds/Doors";
import NearestWallAndVertex from "./SvgCanvasChilds/NearestWallAndVertex";
import SelectedWalls from "./SvgCanvasChilds/Walls";
import { mergeRoomsVertices } from "../../utils/FloorPlanFunc/MergeRoomsVertices";
import {
  highlightNearestParallelWalls,
  highlightNearestVertex
} from "../../utils/FloorPlanFunc/HighlightWallVertex";
import { drawDimensions } from "./SvgCanvasChilds/DrawDimensions";
import drawDoorDimensions from "./SvgCanvasChilds/DrawDoorDimensions";
import drawTextInsideRoom from "./SvgCanvasChilds/DrawTextInsideRoom";
import doorSideWallDimension from "./SvgCanvasChilds/DoorSideWallDimension";
import { circlePointIcon } from "./SvgCanvasChilds/CirclePointIcon";
import WallLengthControls from "./SvgCanvasChilds/WallLengthControls";

export const SvgCanvas = () => {
  const dispatch = useDispatch();
  const selectedFloor = useSelector((state) => state.floorPlan.selectedFloor);
  const selectedMethod = useSelector((state) => state.floorPlan.selectedMethod);
  const drawingRooms = useSelector((state) => state.floorPlan.drawingRooms);
  const selectedRoomToDraw = useSelector(
    (state) => state.floorPlan.selectedRoomToDraw
  );
  const floorStaircases = useSelector(
    (state) => state.floorPlan.floorStaircases
  );
  const singleCustomerData = useSelector(
    (state) => state.customerReducer.singleCustomer
  );

  const rooms = drawingRooms?.[selectedFloor?.name];

  const setRooms = (rooms) => {
    dispatch(
      setUpdateFloors({
        rooms: rooms,
        floorName: selectedFloor?.name
      })
    );
    // handleStateChange(rooms)
  };

  const svgRef = useRef(null);
  const groupRef = useRef(null);
  const staircaseRef = useRef(null);

  const [dragging, setDragging] = useState(false);
  const [currentPoint, setCurrentPoint] = useState(null);
  const [selectedWall, setSelectedWall] = useState(null);
  const [selectedVertex, setSelectedVertex] = useState(null);
  const [hoveredVertex, setHoveredVertex] = useState(null);
  const [activeRoomIndex, setActiveRoomIndex] = useState(null);
  const [scale, setScale] = useState(1);
  const [zooming, setZooming] = useState(false);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  const [isRotating, setIsRotating] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [manualWallLength, setManualWallLength] = useState(null);
  const [confirmLengthChange, setConfirmLengthChange] = useState(false);
  const [confirmLockWallChange, setConfirmLockWallChange] = useState(false);
  const [clickPosition, setClickPosition] = useState(null);
  const [panning, setPanning] = useState(false);
  const [panStart, setPanStart] = useState({ x: 0, y: 0 });
  const [feet, setFeet] = useState("");
  const [inches, setInches] = useState("");
  const [gridLines, setGridLines] = useState([]);
  const [highlightedWalls, setHighlightedWalls] = useState([]);
  const selectionRadius = 6;
  const redDotRadius = 3;
  const dimensionOffset = 26;
  const doorDimensionOffset = 13;
  const closeRadius = 20;
  const doorWidth = 16;
  const innerOffset = 4;

  const defaultRotationalColor = "#3a80c7";
  const [originalVertices, setOriginalVertices] = useState(null);
  console.log("originalVertices", originalVertices);

  const [draggingMoveIcon, setDraggingMoveIcon] = useState(false);
  const [elasticLine, setElasticLine] = useState(null);
  const [mergeCandidates, setMergeCandidates] = useState([]);
  const [nearestVertices, setNearestVertices] = useState([]);
  const [draggingIconPosition, setDraggingIconPosition] = useState(null);
  const [draggingIconColor, setDraggingIconColor] = useState("#3a80c7");
  const [oldRooms, setOldRooms] = useState([]);

  const [initialDistance, setInitialDistance] = useState(null);
  const [initialScale, setInitialScale] = useState(1);
  const [rotationIconColor, setRotationIconColor] = useState(
    defaultRotationalColor
  );

  const [resizingDoor, setResizingDoor] = useState(false);
  const [initialTouchDistance, setInitialTouchDistance] = useState(null);
  const [initialTouchDoorLength, setInitialTouchDoorLength] = useState(null);

  const [manualChangeRooms, setManualChangeRooms] = useState({
    rooms: [],
    roomIndex: null,
    wallIndex: null,
    lengthDiff: null,
    innerOffset
  });

  /**
   * States for the drawing room with defined corners
   */
  const [cancelDrawingRoomModal, setCancelDrawingRoomModal] = useState(false);
  const [definedCorners, setDefinedCorners] = useState([]);
  const [clickedOutside, setClickedOutside] = useState([]);

  const [selectedDoor, setSelectedDoor] = useState(null);
  const [draggingDoor, setDraggingDoor] = useState(false);

  const [openAddStaircaseModal, setOpenAddStaircaseModal] = useState(false);
  // const [staircases, setStaircases] = useState([]);
  const [selectedStaircase, setSelectedStaircase] = useState(null);
  const [initialDimensions, setInitialDimensions] = useState({});
  const [isStaircaseDragging, setIsStaircaseDragging] = useState(false);
  const [resizeDirection, setResizeDirection] = useState(null);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [selectedWallDoors, setSelectedWallDoors] = useState([]); // doors on selected wall

  const floorName = useMemo(() => {
    return selectedFloor?.name || "";
  }, [selectedFloor]);

  const setStaircases = (staircases) => {
    dispatch(
      setStaircasesForPlan({
        staircases,
        TYPE: "withoutInitialData"
      })
    );
  };

  const resetScale = (rooms) => {
    const { minX, minY, maxX, maxY } = calculateRoomsBoundingBox(rooms);

    // calculate centroid of the rooms bounding box
    const bboxCenter = {
      x: (minX + maxX) / 2,
      y: (minY + maxY) / 2
    };

    const newTranslate = {
      x: svgRef.current?.clientWidth / 2 - bboxCenter.x,
      y: svgRef.current?.clientHeight / 2 - bboxCenter.y
    };

    setScale(1);
    setTranslate(newTranslate);
  };

  const setRoomIndex = useCallback(() => {
    if (
      selectedRoomToDraw &&
      selectedFloor &&
      (selectedMethod === "square" || selectedMethod === null)
    ) {
      const selectedRoomIndex = rooms?.findIndex(
        (room) => room.id === selectedRoomToDraw?.id
      );

      handlePolygonClick(rooms, selectedRoomIndex);
    }
  }, [rooms, selectedFloor, selectedRoomToDraw, selectedMethod]);

  useEffect(() => {
    if (selectedFloor) {
      setRoomIndex();
    }
  }, [selectedRoomToDraw]);

  useEffect(() => {
    if (selectedMethod === "corner") {
      setActiveRoomIndex(null);
      setScale(1);
      setTranslate({ x: 0, y: 0 });
    }
  }, [selectedMethod]);

  useEffect(() => {
    if (selectedFloor) {
      setActiveRoomIndex(null);
      dispatch(setRoomToDraw(null));
      dispatch(selectMethod(null));
      setSelectedWall(null);
      setSelectedStaircase(null);
      resetScale(rooms);
    }
  }, [selectedFloor]);

  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  const handleStateChange = (newRooms) => {
    setUndoStack([...undoStack, oldRooms]);

    setRooms(newRooms);
    setOldRooms(newRooms);
    setRedoStack([]); // Clear redo stack on new action
  };

  const undo = () => {
    setUndoStack((prevUndoStack) => {
      if (prevUndoStack.length > 0) {
        return prevUndoStack.slice(0, prevUndoStack.length - 1);
      }
      return prevUndoStack;
    });
    const previousState = undoStack[undoStack.length - 1];

    setRooms(previousState);
    setRedoStack((prevRedoStack) => [...prevRedoStack, rooms]);
  };

  const redo = () => {
    setRedoStack((prevRedoStack) => {
      if (prevRedoStack.length > 0) {
        return prevRedoStack.slice(0, prevRedoStack.length - 1);
      }
      return prevRedoStack;
    });
    const nextState = redoStack[redoStack.length - 1];
    setRooms(nextState);
    setUndoStack((prevUndoStack) => [...prevUndoStack, rooms]);
  };

  const handleTouchStart = (e) => {
    // if (selectedStaircase === null) return;
    if (e.touches.length === 2) {
      const distance = calculateDistance(e.touches[0], e.touches[1]);

      if (selectedStaircase) {
        const touch1 = e.touches[0];
        const touch2 = e.touches[1];
        const distance = Math.hypot(
          touch2.clientX - touch1.clientX,
          touch2.clientY - touch1.clientY
        );
        setInitialDistance(distance);

        if (selectedStaircase.type === "straight") {
          setInitialDimensions({
            width: selectedStaircase?.width,
            height: selectedStaircase?.height
          });
        } else if (selectedStaircase.type === "lshape") {
          const deltaX = Math.abs(touch1.clientX - touch2.clientX);
          const deltaY = Math.abs(touch1.clientY - touch2.clientY);
          const threshold = 20;
          const isInclinedTouch = Math.abs(deltaX - deltaY) < threshold;
          const isHorizontal = deltaX > deltaY;

          if (isInclinedTouch) {
            setInitialDimensions({
              width: selectedStaircase?.horizontalSegment?.width,
              height: selectedStaircase?.verticalSegment?.height
            });
          } else if (isHorizontal) {
            setInitialDimensions({
              width: selectedStaircase?.horizontalSegment?.width,
              height: selectedStaircase?.horizontalSegment?.height
            });
          } else {
            setInitialDimensions({
              width: selectedStaircase?.verticalSegment?.width,
              height: selectedStaircase?.verticalSegment?.height
            });
          }
        }

        return;
      }

      if (selectedDoor) {
        const { roomIndex, doorIndex } = selectedDoor;
        const door = rooms[roomIndex].doors[doorIndex];
        const doorLength = Math.sqrt(
          (door.end.x - door.start.x) ** 2 + (door.end.y - door.start.y) ** 2
        );
        setInitialTouchDistance(distance);
        setInitialTouchDoorLength(doorLength);
        setResizingDoor(true);
      } else {
        setInitialDistance(distance);
        setInitialScale(scale);
      }
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 2) {
      if (selectedStaircase && initialDistance !== null) {
        let { newResizeDirection, deltaX, deltaY } = getResizeDirection(
          e,
          resizeDirection
        );
        if (!resizeDirection) {
          setResizeDirection(newResizeDirection);
        }

        const distance = Math.hypot(deltaX, deltaY);
        const scale = distance / initialDistance;

        let newDimensions = { ...initialDimensions };
        let updatedSts = { ...floorStaircases };

        if (selectedStaircase.type === "straight") {
          updatedSts = updateStraightStaircaseDimensions(
            selectedStaircase,
            newResizeDirection,
            initialDimensions,
            newDimensions,
            scale,
            updatedSts,
            floorName,
            setSelectedStaircase
          );
        } else if (selectedStaircase.type === "lshape") {
          updatedSts = updateLshapeDimensions(
            selectedStaircase,
            newResizeDirection,
            initialDimensions,
            newDimensions,
            scale,
            updatedSts,
            floorName,
            setSelectedStaircase
          );
        }

        setStaircases(updatedSts);

        return;
      }

      const distance = calculateDistance(e.touches[0], e.touches[1]);
      const scaleFactor = distance / initialDistance;

      if (resizingDoor && initialTouchDistance && selectedDoor) {
        const newDoorLength =
          initialTouchDoorLength * (distance / initialTouchDistance);

        const { roomIndex, doorIndex } = selectedDoor;
        const room = rooms[roomIndex];
        const door = room.doors[doorIndex];

        const wallDirection = {
          x: door.end.x - door.start.x,
          y: door.end.y - door.start.y
        };
        const wallLength = Math.sqrt(
          wallDirection.x ** 2 + wallDirection.y ** 2
        );

        const wallUnitVector = {
          x: wallDirection.x / wallLength,
          y: wallDirection.y / wallLength
        };

        const doorCenter = {
          x: (door.start.x + door.end.x) / 2,
          y: (door.start.y + door.end.y) / 2
        };

        let newLength = Math.min(newDoorLength, wallLength); // Ensure new door length does not exceed wall length

        const halfLengthVector = {
          x: (newDoorLength / 2) * wallUnitVector.x,
          y: (newDoorLength / 2) * wallUnitVector.y
        };

        let newDoorStart = {
          x: doorCenter.x - halfLengthVector.x,
          y: doorCenter.y - halfLengthVector.y
        };
        let newDoorEnd = {
          x: doorCenter.x + halfLengthVector.x,
          y: doorCenter.y + halfLengthVector.y
        };

        const updatedRooms = rooms.map((r, i) =>
          i === roomIndex
            ? {
                ...r,
                doors: r.doors.map((d, j) =>
                  j === doorIndex
                    ? { ...d, start: newDoorStart, end: newDoorEnd }
                    : d
                )
              }
            : r
        );

        setRooms(updatedRooms);
        return; // Return early to skip the zoom functionality
      }

      // Zoom functionality if not resizing the door
      if (!resizingDoor && initialDistance) {
        const newScale = initialScale * scaleFactor;

        // Calculate the midpoint between the two touch points
        const midpoint = {
          x: (e.touches[0].clientX + e.touches[1].clientX) / 2,
          y: (e.touches[0].clientY + e.touches[1].clientY) / 2
        };

        // Adjust translate values to zoom around the midpoint
        const rect = svgRef.current.getBoundingClientRect();
        const svgMidpoint = {
          x: (midpoint.x - rect.left - translate.x) / scale,
          y: (midpoint.y - rect.top - translate.y) / scale
        };

        const newTranslate = {
          x: translate.x - svgMidpoint.x * (newScale - scale),
          y: translate.y - svgMidpoint.y * (newScale - scale)
        };

        setScale(newScale);
        setTranslate(newTranslate);
      }
    }
  };

  const handleTouchEnd = (e) => {
    if (e.touches.length < 2) {
      setInitialDistance(null);
      setInitialTouchDistance(null);
      setInitialTouchDoorLength(null);
      setResizingDoor(false);
      setIsStaircaseDragging(false);
    }
    setResizeDirection(null);
  };

  const prepareMergeRoom = () => {
    if (activeRoomIndex === null) return;

    const selectedRoom = rooms[activeRoomIndex];
    const candidates = [];

    const checkVerticesNearWalls = (room1, room2, status) => {
      const intersectionPoints = [];

      for (let vertex of room1.vertices) {
        for (let i = 0; i < room2.vertices.length; i++) {
          const vertex1 = room2.vertices[i];
          const vertex2 = room2.vertices[(i + 1) % room2.vertices.length];
          if (
            isPointNearLine(
              vertex.x,
              vertex.y,
              vertex1.x,
              vertex1.y,
              vertex2.x,
              vertex2.y,
              10
            )
          ) {
            intersectionPoints.push({ x: vertex.x, y: vertex.y });
          }
        }
      }
      // Remove duplicate points
      const uniqueIntersectionPoints = intersectionPoints.filter(
        (point, index, self) =>
          index === self.findIndex((p) => p.x === point.x && p.y === point.y)
      );

      if (intersectionPoints.length == 0) return false;
      if (
        intersectionPoints.length == 2 &&
        uniqueIntersectionPoints?.length === 1
      )
        return false;

      return true;
    };

    rooms.forEach((room, roomIndex) => {
      if (roomIndex !== activeRoomIndex) {
        // Check if any vertex of the current room is near the walls of the active room
        let isCandidate = checkVerticesNearWalls(room, selectedRoom);

        // Check if any vertex of the active room is near the walls of the current room
        let isCandidatReverse = checkVerticesNearWalls(selectedRoom, room);

        if (isCandidate || isCandidatReverse) {
          candidates.push({
            roomIndex,
            centroid: calculateCentroid(room.vertices)
          });
        }
      }
    });

    setMergeCandidates(candidates);
  };

  const getMousePosition = (evt) => {
    const CTM = svgRef.current.getScreenCTM();
    const clientX = evt.clientX || evt.touches[0].clientX;
    const clientY = evt.clientY || evt.touches[0].clientY;
    return {
      x: (clientX - CTM.e - translate.x) / (CTM.a * scale),
      y: (clientY - CTM.f - translate.y) / (CTM.d * scale)
    };
  };

  // Mouse Down
  const handleMouseDown = (e) => {
    setOldRooms(rooms);

    if (selectedStaircase) {
      const isTouchEvent = e.type === "touchstart";
      const clientX = isTouchEvent ? e.touches[0].clientX : e.clientX;
      const clientY = isTouchEvent ? e.touches[0].clientY : e.clientY;

      setDragOffset({
        x: clientX - selectedStaircase.x,
        y: clientY - selectedStaircase.y
      });

      setIsStaircaseDragging(true);
      return;
    }

    const pos = getMousePosition(e);
    if (activeRoomIndex === null) {
      setPanning(true);
      setPanStart({
        x: e.clientX || e.touches[0].clientX,
        y: e.clientY || e.touches[0].clientY
      });
      setIsRotating(false);
      return;
    } else if (activeRoomIndex !== null && !isMoving) {
      setPanning(true);
      setPanStart({
        x: e.clientX || e.touches[0].clientX,
        y: e.clientY || e.touches[0].clientY
      });
      setIsRotating(false);
    }

    let pointSelected = false;
    const room = rooms[activeRoomIndex];
    const center = calculateCentroid(room?.vertices);

    // Check if a door is clicked
    room?.doors?.forEach((door, doorIndex) => {
      if (
        isPointNearLine(
          pos.x,
          pos.y,
          door.start.x,
          door.start.y,
          door.end.x,
          door.end.y,
          selectionRadius
        )
      ) {
        setSelectedDoor({ roomIndex: activeRoomIndex, doorIndex });
        setDraggingDoor(true);
        pointSelected = true;
      }
    });

    // Check if the mouse is down on the rotate icon
    if (isRotating) {
      // if (selectedStaircase) {
      //   const stCenter = {
      //     x: selectedStaircase.x + selectedStaircase.width / 2,
      //     y: selectedStaircase.y + selectedStaircase.height / 2,
      //   };
      //   const rotateIconPosition = {
      //     x:
      //       stCenter.x +
      //       50 * Math.cos(selectedStaircase.rotationAngle || 0) -
      //       50 * Math.sin(selectedStaircase.rotationAngle || 0),
      //     y:
      //       stCenter.y +
      //       50 * Math.sin(selectedStaircase.rotationAngle || 0) +
      //       50 * Math.cos(selectedStaircase.rotationAngle || 0),
      //   };

      //   if (
      //     Math.abs(pos.x - rotateIconPosition.x) < selectionRadius &&
      //     Math.abs(pos.y - rotateIconPosition.y) < selectionRadius
      //   ) {
      //     setDragging(true);
      //     setCurrentPoint({
      //       type: "rotate",
      //       stIndex: selectedStaircase.index,
      //       offsetX: pos.x - center.x,
      //       offsetY: pos.y - center.y,
      //     });
      //     pointSelected = true;
      //   }
      //   return;
      // }

      const rotateIconPosition = {
        x:
          center.x +
          50 * Math.cos(room.rotationAngle || 0) -
          50 * Math.sin(room.rotationAngle || 0),
        y:
          center.y +
          50 * Math.sin(room.rotationAngle || 0) +
          50 * Math.cos(room.rotationAngle || 0)
      };

      if (
        Math.abs(pos.x - rotateIconPosition.x) < selectionRadius &&
        Math.abs(pos.y - rotateIconPosition.y) < selectionRadius
      ) {
        setDragging(true);
        setCurrentPoint({
          type: "rotate",
          roomIndex: activeRoomIndex,
          offsetX: pos.x - center.x,
          offsetY: pos.y - center.y
        });
        pointSelected = true;
      }
    }
    let selectedDoors = [];
    room?.vertices?.forEach((vertex, vertexIndex) => {
      if (
        Math.abs(pos.x - vertex.x) < selectionRadius &&
        Math.abs(pos.y - vertex.y) < selectionRadius
      ) {
        setDragging(true);
        setCurrentPoint({
          type: "vertex",
          roomIndex: activeRoomIndex,
          index: vertexIndex,
          offsetX: pos.x - vertex.x,
          offsetY: pos.y - vertex.y
        });
        pointSelected = true;
        const nextVertex =
          rooms[activeRoomIndex]?.vertices[
            (vertexIndex + 1) % rooms[activeRoomIndex].vertices.length
          ];
        rooms[activeRoomIndex]?.doors?.forEach((door, doorIndex) => {
          if (isDoorOnWall(door, vertex, nextVertex)) {
            selectedDoors.push(doorIndex);
          }
        });

        setSelectedWallDoors(selectedDoors);
      }
    });

    if (!pointSelected) {
      room?.vertices?.forEach((vertex, vertexIndex) => {
        const nextVertex =
          room.vertices[(vertexIndex + 1) % room.vertices.length];
        if (
          isPointNearLine(
            pos.x,
            pos.y,
            vertex.x,
            vertex.y,
            nextVertex.x,
            nextVertex.y,
            selectionRadius
          )
        ) {
          setDragging(true);
          setCurrentPoint({
            type: "wall",
            roomIndex: activeRoomIndex,
            index: vertexIndex,
            offsetX: pos.x,
            offsetY: pos.y
          });
          pointSelected = true;
        }
      });
    }

    if (!pointSelected && isMoving) {
      const moveIconPosition = calculateCentroid(room.vertices);

      if (
        Math.abs(pos.x - moveIconPosition.x) < selectionRadius &&
        Math.abs(pos.y - moveIconPosition.y) < selectionRadius
      ) {
        setDragging(true);
        setDraggingMoveIcon(true);

        setCurrentPoint({
          type: "room",
          roomIndex: activeRoomIndex,
          offsetX: pos.x - moveIconPosition.x,
          offsetY: pos.y - moveIconPosition.y
        });
        pointSelected = true;
      }
    }

    if (!pointSelected && isRotating) {
      const rotateIconPosition = calculateCentroid(room.vertices);

      if (
        Math.abs(pos.x - rotateIconPosition.x) < selectionRadius &&
        Math.abs(pos.y - rotateIconPosition.y) < selectionRadius
      ) {
        setDragging(true);
        setCurrentPoint({
          type: "rotate",
          roomIndex: activeRoomIndex,
          offsetX: pos.x - rotateIconPosition.x,
          offsetY: pos.y - rotateIconPosition.y
        });
        pointSelected = true;
      }
    }

    if (!pointSelected) {
      setDragging(false);
      setCurrentPoint(null);
      setSelectedDoor(null);
    }
  };

  //MouseMove
  const handleMouseMove = (e) => {
    if (selectedStaircase && isStaircaseDragging) {
      const isTouchEvent = e.type === "touchmove";
      const clientX = isTouchEvent ? e.touches[0].clientX : e.clientX;
      const clientY = isTouchEvent ? e.touches[0].clientY : e.clientY;

      const newX = clientX - dragOffset.x;
      const newY = clientY - dragOffset.y;

      let updatedSts = { ...floorStaircases };
      updatedSts[floorName] = updatedSts[selectedFloor.name]?.map(
        (staircase, i) =>
          i === selectedStaircase.index
            ? { ...staircase, x: newX, y: newY }
            : staircase
      );

      setStaircases(updatedSts);

      setSelectedStaircase((prev) => ({
        ...prev,
        x: newX,
        y: newY
      }));
      return;
    }

    if (activeRoomIndex === null) {
      if (panning) {
        e.preventDefault();
        const isTouch = e.touches ? true : false;

        const event = isTouch ? e.touches?.[0] : e;
        const dx = event.clientX - panStart.x;
        const dy = event.clientY - panStart.y;

        const newTranslate = {
          x: translate.x + dx,
          y: translate.y + dy
        };

        setTranslate(newTranslate);
        setPanStart({ x: event.clientX, y: event.clientY });
      }

      return;
    } else if (activeRoomIndex !== null && !isMoving && !currentPoint) {
      if (panning) {
        e.preventDefault();
        const isTouch = e.touches ? true : false;

        const event = isTouch ? e.touches?.[0] : e;
        const dx = event.clientX - panStart.x;
        const dy = event.clientY - panStart.y;

        const newTranslate = {
          x: translate.x + dx,
          y: translate.y + dy
        };

        setTranslate(newTranslate);
        setPanStart({ x: event.clientX, y: event.clientY });
      }
    }

    if (draggingDoor && selectedDoor) {
      const pos = getMousePosition(e);
      const { roomIndex, doorIndex } = selectedDoor;
      const room = rooms[roomIndex];
      const door = room.doors[doorIndex];

      let nearestWall = null;
      let minDistance = Infinity;
      let bestT = 0;

      // Find the nearest wall to the cursor position
      room?.vertices?.forEach((vertex, vertexIndex) => {
        const nextVertex =
          room.vertices[(vertexIndex + 1) % room.vertices.length];
        const dx = nextVertex.x - vertex.x;
        const dy = nextVertex.y - vertex.y;
        const wallLength = Math.sqrt(dx * dx + dy * dy);
        const t =
          ((pos.x - vertex.x) * dx + (pos.y - vertex.y) * dy) /
          (wallLength * wallLength);
        const clampedT = Math.max(0, Math.min(1, t));
        const closestPoint = {
          x: vertex.x + clampedT * dx,
          y: vertex.y + clampedT * dy
        };
        const distance = Math.sqrt(
          (pos.x - closestPoint.x) ** 2 + (pos.y - closestPoint.y) ** 2
        );
        if (distance < minDistance) {
          minDistance = distance;
          nearestWall = { start: vertex, end: nextVertex };
          bestT = clampedT;
        }
      });

      if (nearestWall) {
        const doorLength = Math.sqrt(
          (door.end.x - door.start.x) ** 2 + (door.end.y - door.start.y) ** 2
        );
        const wallDirection = {
          x: nearestWall.end.x - nearestWall.start.x,
          y: nearestWall.end.y - nearestWall.start.y
        };
        const wallUnitVector = {
          x:
            wallDirection.x /
            Math.sqrt(wallDirection.x ** 2 + wallDirection.y ** 2),
          y:
            wallDirection.y /
            Math.sqrt(wallDirection.x ** 2 + wallDirection.y ** 2)
        };
        const doorStart = {
          x:
            nearestWall.start.x +
            bestT * wallDirection.x -
            (doorLength / 2) * wallUnitVector.x,
          y:
            nearestWall.start.y +
            bestT * wallDirection.y -
            (doorLength / 2) * wallUnitVector.y
        };

        const doorEnd = {
          x: doorStart.x + doorLength * wallUnitVector.x,
          y: doorStart.y + doorLength * wallUnitVector.y
        };

        const updatedRooms = rooms.map((r, i) =>
          i === roomIndex
            ? {
                ...r,
                doors: r.doors.map((d, j) =>
                  j === doorIndex ? { ...d, start: doorStart, end: doorEnd } : d
                )
              }
            : r
        );

        setRooms(updatedRooms);
      }
      return;
    }

    if (!dragging || !currentPoint) return;
    const pos = getMousePosition(e);
    if (currentPoint?.type === "rotate") {
      const room = rooms[currentPoint.roomIndex];
      const center = calculateCentroid(room.vertices);
      const initialAngle = room.rotationAngle || 0;

      const { angle, angleIncrement, rotatePoint } = getIncreamentedAngle(
        pos,
        center,
        initialAngle,
        setRotationIconColor
      );

      const updatedVertices = room.vertices.map((vertex) =>
        rotatePoint(vertex, center, angleIncrement)
      );
      const updatedDoors = room?.doors?.map((door) => {
        const rotatedStart = rotatePoint(door.start, center, angleIncrement);
        const rotatedEnd = rotatePoint(door.end, center, angleIncrement);

        return {
          ...door,
          start: rotatedStart,
          end: rotatedEnd
        };
      });

      const updatedRooms = rooms.map((r, i) =>
        i === currentPoint.roomIndex
          ? {
              ...r,
              vertices: updatedVertices,
              doors: updatedDoors,
              centers: calculateCenters(updatedVertices),
              rotationAngle: angle
            }
          : r
      );

      setRooms(updatedRooms);

      let updatedSts = { ...floorStaircases };
      updatedSts[floorName] = updatedSts[floorName]?.map((staircase) => {
        if (staircase.roomId === room.id) {
          const stCentroid = {
            x: staircase.x + staircase.width / 2,
            y: staircase.y + staircase.height / 2
          };
          const rotatedStaircaseCenter = rotatePoint(
            stCentroid,
            center,
            angleIncrement
          );
          return {
            ...staircase,
            x: rotatedStaircaseCenter.x - staircase.width / 2,
            y: rotatedStaircaseCenter.y - staircase.height / 2,
            rotationAngle: (staircase.rotationAngle || 0) + angleIncrement
          };
        }
        return staircase;
      });

      setStaircases(updatedSts);

      currentPoint.offsetX = pos.x;
      currentPoint.offsetY = pos.y;

      return;
    }

    const updateFeetAndInches = (vertex1, vertex2) => {
      const length = Math.sqrt(
        Math.pow(vertex2.x - vertex1.x, 2) + Math.pow(vertex2.y - vertex1.y, 2)
      );
      const angle = Math.atan2(vertex2.y - vertex1.y, vertex2.x - vertex1.x);
      const innerOffsetX = innerOffset * Math.cos(angle);
      const innerOffsetY = innerOffset * Math.sin(angle);

      // Adjusted start and end points
      const adjustedStart = {
        x: vertex1.x + innerOffsetX,
        y: vertex1.y + innerOffsetY
      };
      const adjustedEnd = {
        x: vertex2.x - innerOffsetX,
        y: vertex2.y - innerOffsetY
      };
      // Calculate the adjusted length
      const adjustedLength = Math.sqrt(
        Math.pow(adjustedEnd.x - adjustedStart.x, 2) +
          Math.pow(adjustedEnd.y - adjustedStart.y, 2)
      );
      const { feet, inches } = convertToFeetAndInches(adjustedLength);
      setFeet(feet);
      setInches(inches);
    };

    let updatedRooms;
    if (
      currentPoint?.type === "wall" &&
      selectedWall &&
      selectedWall.roomIndex === currentPoint.roomIndex &&
      selectedWall.index === currentPoint.index
    ) {
      updatedRooms = rooms.map((room, roomIndex) => {
        if (roomIndex === currentPoint.roomIndex) {
          let newVertices = [...room.vertices];
          if (!originalVertices) {
            setOriginalVertices(JSON.parse(JSON.stringify(newVertices)));
          }
          const prevVertexIndex = currentPoint.index;
          const nextVertexIndex =
            (currentPoint.index + 1) % room.vertices.length;

          const dx = pos.x - currentPoint.offsetX;
          const dy = pos.y - currentPoint.offsetY;

          const dirX =
            room.vertices[nextVertexIndex].x - room.vertices[prevVertexIndex].x;
          const dirY =
            room.vertices[nextVertexIndex].y - room.vertices[prevVertexIndex].y;

          const length = Math.sqrt(dirX * dirX + dirY * dirY);
          const unitDirX = dirX / length;
          const unitDirY = dirY / length;

          const perpDirX = -unitDirY;
          const perpDirY = unitDirX;

          const perpMoveDist = dx * perpDirX + dy * perpDirY;

          const newPrevVertex = {
            x: newVertices[prevVertexIndex].x + perpMoveDist * perpDirX,
            y: newVertices[prevVertexIndex].y + perpMoveDist * perpDirY
          };

          const newNextVertex = {
            x: newVertices[nextVertexIndex].x + perpMoveDist * perpDirX,
            y: newVertices[nextVertexIndex].y + perpMoveDist * perpDirY
          };

          newVertices[prevVertexIndex] = newPrevVertex;
          newVertices[nextVertexIndex] = newNextVertex;

          if (currentPoint.type === "wall") {
            currentPoint.offsetX = pos.x;
            currentPoint.offsetY = pos.y;
          }

          // Update the door positions based on the moved wall vertices
          const updatedDoors = room?.doors?.map((door) => {
            const doorCenter = {
              x: (door.start.x + door.end.x) / 2,
              y: (door.start.y + door.end.y) / 2
            };

            let nearestWall = null;
            let minDistance = Infinity;
            let bestT = 0;

            // Check both previous to current and current to next walls
            const checkWall = (startVertex, endVertex) => {
              const dx = endVertex.x - startVertex.x;
              const dy = endVertex.y - startVertex.y;
              const wallLength = Math.sqrt(dx * dx + dy * dy);
              const t =
                ((doorCenter.x - startVertex.x) * dx +
                  (doorCenter.y - startVertex.y) * dy) /
                (wallLength * wallLength);
              const clampedT = Math.max(0, Math.min(1, t));
              const closestPoint = {
                x: startVertex.x + clampedT * dx,
                y: startVertex.y + clampedT * dy
              };
              const distance = Math.sqrt(
                (doorCenter.x - closestPoint.x) ** 2 +
                  (doorCenter.y - closestPoint.y) ** 2
              );

              if (distance < minDistance) {
                minDistance = distance;
                nearestWall = { start: startVertex, end: endVertex };
                bestT = clampedT;
              }
            };

            const isDoorOnRelevantWall =
              isDoorOnWall(
                door,
                room.vertices[prevVertexIndex],
                room.vertices[currentPoint.index]
              ) ||
              isDoorOnWall(
                door,
                room.vertices[currentPoint.index],
                room.vertices[nextVertexIndex]
              ) ||
              isDoorOnWall(
                door,
                room.vertices[
                  (prevVertexIndex - 1 + room.vertices.length) %
                    room.vertices.length
                ],
                room.vertices[prevVertexIndex]
              ) ||
              isDoorOnWall(
                door,
                room.vertices[nextVertexIndex],
                room.vertices[(nextVertexIndex + 1) % room.vertices.length]
              );

            if (isDoorOnRelevantWall) {
              checkWall(
                newVertices[prevVertexIndex],
                newVertices[currentPoint.index]
              );
              checkWall(
                newVertices[currentPoint.index],
                newVertices[nextVertexIndex]
              );
              checkWall(
                newVertices[
                  (prevVertexIndex - 1 + room.vertices.length) %
                    room.vertices.length
                ],
                newVertices[prevVertexIndex]
              );
              checkWall(
                newVertices[nextVertexIndex],
                newVertices[(nextVertexIndex + 1) % room.vertices.length]
              );
            }

            if (nearestWall) {
              const doorLength = Math.sqrt(
                (door.end.x - door.start.x) ** 2 +
                  (door.end.y - door.start.y) ** 2
              );
              const wallDirection = {
                x: nearestWall.end.x - nearestWall.start.x,
                y: nearestWall.end.y - nearestWall.start.y
              };
              const wallUnitVector = {
                x:
                  wallDirection.x /
                  Math.sqrt(wallDirection.x ** 2 + wallDirection.y ** 2),
                y:
                  wallDirection.y /
                  Math.sqrt(wallDirection.x ** 2 + wallDirection.y ** 2)
              };
              const doorStart = {
                x:
                  nearestWall.start.x +
                  bestT * wallDirection.x -
                  (doorLength / 2) * wallUnitVector.x,
                y:
                  nearestWall.start.y +
                  bestT * wallDirection.y -
                  (doorLength / 2) * wallUnitVector.y
              };
              const doorEnd = {
                x: doorStart.x + doorLength * wallUnitVector.x,
                y: doorStart.y + doorLength * wallUnitVector.y
              };

              return { ...door, start: doorStart, end: doorEnd };
            }

            return door;
          });

          updateFeetAndInches(newPrevVertex, newNextVertex);

          return {
            ...room,
            vertices: newVertices,
            doors: updatedDoors,
            centers: calculateCenters(newVertices)
          };
        }
        return room;
      });

      setRooms(updatedRooms);
      highlightNearestVertex(
        updatedRooms[currentPoint.roomIndex],
        currentPoint.roomIndex,
        pos,
        6,
        rooms,
        setNearestVertices
      );
      highlightNearestParallelWalls(
        updatedRooms[currentPoint.roomIndex],
        currentPoint.roomIndex,
        pos,
        3,
        rooms,
        setHighlightedWalls
      );
    } else if (
      currentPoint?.type === "vertex" ||
      currentPoint.type === "room"
    ) {
      // if (currentPoint.type === "room" && !draggingMoveIcon) return;
      updatedRooms = rooms.map((room, roomIndex) => {
        if (roomIndex === activeRoomIndex) {
          let newVertices = [...room.vertices];
          let oldVertices = JSON.parse(JSON.stringify([...room.vertices]));
          let updatedDoors = room?.doors ? [...room?.doors] : [];

          if (currentPoint.type === "vertex" && !isMoving) {
            setSelectedWall(null);
            if (!originalVertices) {
              setOriginalVertices(JSON.parse(JSON.stringify(newVertices)));
            }
            newVertices[currentPoint.index] = {
              x: pos.x - currentPoint.offsetX,
              y: pos.y - currentPoint.offsetY
            };

            const prevVertexIndex =
              (currentPoint.index - 1 + room.vertices.length) %
              room.vertices.length;
            const nextVertexIndex =
              (currentPoint.index + 1) % room.vertices.length;

            const prevVertex = newVertices[prevVertexIndex];
            const nextVertex = newVertices[nextVertexIndex];

            if (
              Math.abs(newVertices[currentPoint.index].x - prevVertex.x) <
              selectionRadius
            ) {
              newVertices[currentPoint.index].x = prevVertex.x;
            } else if (
              Math.abs(newVertices[currentPoint.index].y - prevVertex.y) <
              selectionRadius
            ) {
              newVertices[currentPoint.index].y = prevVertex.y;
            } else if (
              Math.abs(newVertices[currentPoint.index].x - nextVertex.x) <
              selectionRadius
            ) {
              newVertices[currentPoint.index].x = nextVertex.x;
            } else if (
              Math.abs(newVertices[currentPoint.index].y - nextVertex.y) <
              selectionRadius
            ) {
              newVertices[currentPoint.index].y = nextVertex.y;
            }

            // Update the door positions based on the moved vertices
            updatedDoors = room?.doors?.map((door, doorIndex) => {
              const doorCenter = {
                x: (door.start.x + door.end.x) / 2,
                y: (door.start.y + door.end.y) / 2
              };

              let nearestWall = null;
              let minDistance = Infinity;
              let bestT = 0;

              // Check both previous to current and current to next walls
              const checkWall = (startVertex, endVertex) => {
                const dx = endVertex.x - startVertex.x;
                const dy = endVertex.y - startVertex.y;
                const wallLength = Math.sqrt(dx * dx + dy * dy);
                const t =
                  ((doorCenter.x - startVertex.x) * dx +
                    (doorCenter.y - startVertex.y) * dy) /
                  (wallLength * wallLength);
                const clampedT = Math.max(0, Math.min(1, t));
                const closestPoint = {
                  x: startVertex.x + clampedT * dx,
                  y: startVertex.y + clampedT * dy
                };
                const distance = Math.sqrt(
                  (doorCenter.x - closestPoint.x) ** 2 +
                    (doorCenter.y - closestPoint.y) ** 2
                );

                if (distance < minDistance) {
                  minDistance = distance;
                  nearestWall = { start: startVertex, end: endVertex };
                  bestT = clampedT;
                }
              };

              const isDoorOnRelevantWall =
                isDoorOnWall(
                  door,
                  newVertices[prevVertexIndex],
                  newVertices[currentPoint.index]
                ) ||
                isDoorOnWall(
                  door,
                  newVertices[currentPoint.index],
                  newVertices[nextVertexIndex]
                );

              if (isDoorOnRelevantWall) {
                checkWall(
                  newVertices[prevVertexIndex],
                  newVertices[currentPoint.index]
                );
                checkWall(
                  newVertices[currentPoint.index],
                  newVertices[nextVertexIndex]
                );
              }

              if (nearestWall) {
                const doorLength = Math.sqrt(
                  (door.end.x - door.start.x) ** 2 +
                    (door.end.y - door.start.y) ** 2
                );
                const wallDirection = {
                  x: nearestWall.end.x - nearestWall.start.x,
                  y: nearestWall.end.y - nearestWall.start.y
                };
                const wallUnitVector = {
                  x:
                    wallDirection.x /
                    Math.sqrt(wallDirection.x ** 2 + wallDirection.y ** 2),
                  y:
                    wallDirection.y /
                    Math.sqrt(wallDirection.x ** 2 + wallDirection.y ** 2)
                };
                const doorStart = {
                  x:
                    nearestWall.start.x +
                    bestT * wallDirection.x -
                    (doorLength / 2) * wallUnitVector.x,
                  y:
                    nearestWall.start.y +
                    bestT * wallDirection.y -
                    (doorLength / 2) * wallUnitVector.y
                };
                const doorEnd = {
                  x: doorStart.x + doorLength * wallUnitVector.x,
                  y: doorStart.y + doorLength * wallUnitVector.y
                };

                return { ...door, start: doorStart, end: doorEnd };
              }

              return door;
            });

            updateFeetAndInches(newVertices[currentPoint.index], nextVertex);
          } else if (isMoving && currentPoint.type === "room") {
            const dx = pos.x - calculateCentroid(newVertices).x;
            const dy = pos.y - calculateCentroid(newVertices).y;
            const deltaX =
              pos.x -
              (currentPoint.offsetX +
                calculateCentroid(rooms[currentPoint.roomIndex].vertices).x);
            const deltaY =
              pos.y -
              (currentPoint.offsetY +
                calculateCentroid(rooms[currentPoint.roomIndex].vertices).y);

            let updatedSts = { ...floorStaircases };
            updatedSts[floorName] = updatedSts[floorName]?.map((st) => {
              if (st.roomId === room.id) {
                return {
                  ...st,
                  x: st.x + deltaX,
                  y: st.y + deltaY
                };
              }
              return st;
            });

            setStaircases(updatedSts);

            newVertices = newVertices.map((vertex) => ({
              x: vertex.x + deltaX,
              y: vertex.y + deltaY
            }));
            // Update door positions based on room movement
            updatedDoors = room?.doors?.map((door) => ({
              ...door,
              start: { x: door.start.x + deltaX, y: door.start.y + deltaY },
              end: { x: door.end.x + deltaX, y: door.end.y + deltaY }
            }));

            setSelectedWall(null);
            setClickPosition(null);
          }

          return {
            ...room,
            vertices: newVertices,
            doors: updatedDoors,
            centers: calculateCenters(newVertices)
          };
        }
        return room;
      });

      setRooms(updatedRooms);
      if (currentPoint.type === "room") {
        highlightNearestParallelWalls(
          updatedRooms[currentPoint.roomIndex],
          currentPoint.roomIndex,
          pos,
          20,
          rooms,
          setHighlightedWalls
        );
        highlightNearestVertex(
          updatedRooms[currentPoint.roomIndex],
          currentPoint.roomIndex,
          pos,
          18,
          rooms,
          setNearestVertices
        );
      }
    }

    // Check if vertex is near another room vertex
    if (currentPoint.type === "vertex") {
      let isNearOtherVertex = false;
      rooms.forEach((room, roomIndex) => {
        if (roomIndex !== currentPoint.roomIndex) {
          room?.vertices?.forEach((otherVertex) => {
            const distance = Math.sqrt(
              Math.pow(pos.x - otherVertex.x, 2) +
                Math.pow(pos.y - otherVertex.y, 2)
            );
            if (distance < selectionRadius) {
              isNearOtherVertex = true;
            }
          });
        }
      });

      // Change color based on proximity
      setDraggingIconColor(isNearOtherVertex ? "#9DDE8B" : "#3a80c7");

      // Show SVG icon when dragging a vertex
      setDraggingIconPosition({ x: pos.x, y: pos.y });
    }

    // Update elastic line coordinates
    if (highlightedWalls.length > 0 && currentPoint.type === "room") {
      const { movedWall, otherWall } = highlightedWalls[0];

      setElasticLine({
        start: {
          x: (movedWall.x1 + movedWall.x2) / 2,
          y: (movedWall.y1 + movedWall.y2) / 2
        },
        end: {
          x: (otherWall.x1 + otherWall.x2) / 2,
          y: (otherWall.y1 + otherWall.y2) / 2
        }
      });
    } else {
      setElasticLine(null);
    }

    let hovered = false;
    rooms.forEach((room, roomIndex) => {
      room?.vertices?.forEach((vertex, vertexIndex) => {
        if (
          Math.abs(pos.x - vertex.x) < selectionRadius &&
          Math.abs(pos.y - vertex.y) < selectionRadius &&
          !(
            currentPoint &&
            currentPoint.roomIndex === roomIndex &&
            currentPoint.index === vertexIndex
          )
        ) {
          setHoveredVertex({ roomIndex, index: vertexIndex, ...vertex });
          hovered = true;
        }
      });
    });

    if (!hovered) {
      setHoveredVertex(null);
    }
  };

  // Example of wrapping setRooms with handleStateChange
  const stopDragging = () => {
    setDragging(false);
    setPanning(false);
    setDraggingMoveIcon(false);
    setDraggingIconPosition(null);
    setDraggingIconColor("#3a80c7");
    let originalRoom = _.cloneDeep(rooms[currentPoint?.roomIndex]);

    if (!currentPoint) return;
    let isLockedWallChanged = false;
    let updatedRooms = JSON.parse(JSON.stringify(rooms)); // Deep copy of rooms
    let modifiedLockedWalls = {};

    const updateDoors = (room, offsetX, offsetY) => {
      return room?.doors?.map((door) => {
        const newDoorStart = {
          x: door.start.x + offsetX,
          y: door.start.y + offsetY
        };
        const newDoorEnd = {
          x: door.end.x + offsetX,
          y: door.end.y + offsetY
        };

        return { ...door, start: newDoorStart, end: newDoorEnd };
      });
    };

    const snapVertices = (room, offsets) => {
      const averageOffsetX =
        offsets.reduce((acc, offset) => acc + offset.offsetX, 0) /
        offsets.length;
      const averageOffsetY =
        offsets.reduce((acc, offset) => acc + offset.offsetY, 0) /
        offsets.length;

      return {
        averageOffsetX,
        averageOffsetY,
        newVertices: room.vertices.map((vertex) => ({
          x: vertex.x + averageOffsetX,
          y: vertex.y + averageOffsetY
        }))
      };
    };

    const rotateDoors = (doors, center, angleIncrement) => {
      return doors?.map((door) => {
        const rotatedStart = rotatePoint(door.start, center, angleIncrement);
        const rotatedEnd = rotatePoint(door.end, center, angleIncrement);

        return { ...door, start: rotatedStart, end: rotatedEnd };
      });
    };

    if (currentPoint.type === "room" && nearestVertices.length > 0) {
      const offsets = nearestVertices.map((nearestVertex) => ({
        offsetX: nearestVertex.otherVertex.x - nearestVertex.movedVertex.x,
        offsetY: nearestVertex.otherVertex.y - nearestVertex.movedVertex.y
      }));

      updatedRooms = updatedRooms.map((room, roomIndex) => {
        if (roomIndex === activeRoomIndex) {
          const { averageOffsetX, averageOffsetY, newVertices } = snapVertices(
            room,
            offsets
          );
          const updatedDoors = updateDoors(
            room,
            averageOffsetX,
            averageOffsetY
          );
          return {
            ...room,
            vertices: newVertices,
            centers: calculateCenters(newVertices),
            doors: updatedDoors
          };
        }
        return room;
      });
    } else if (
      currentPoint &&
      (currentPoint.type === "vertex" ||
        currentPoint.type === "wall" ||
        currentPoint.type === "room")
    ) {
      // let originalRoom = rooms[currentPoint.roomIndex];
      let originalLengths = calculateWallLengths(originalVertices);

      updatedRooms = updatedRooms.map((room, roomIndex) => {
        if (roomIndex === currentPoint.roomIndex) {
          let newVertices = [...room.vertices];
          let averageOffsetX = 0;
          let averageOffsetY = 0;

          rooms?.forEach((otherRoom, otherRoomIndex) => {
            if (otherRoomIndex !== roomIndex) {
              otherRoom?.vertices?.forEach((vertex) => {
                const distance = Math.sqrt(
                  Math.pow(vertex.x - newVertices[currentPoint.index]?.x, 2) +
                    Math.pow(vertex.y - newVertices[currentPoint.index]?.y, 2)
                );

                if (distance < selectionRadius) {
                  averageOffsetX = vertex.x - newVertices[currentPoint.index].x;
                  averageOffsetY = vertex.y - newVertices[currentPoint.index].y;
                  newVertices[currentPoint.index] = {
                    x: vertex.x,
                    y: vertex.y
                  };
                }
              });
            }
          });

          const updatedDoors = updateDoors(
            room,
            averageOffsetX,
            averageOffsetY
          );

          return {
            ...room,
            vertices: newVertices,
            centers: calculateCenters(newVertices),
            doors: updatedDoors
          };
        }

        return room;
      });

      let newLengths = calculateWallLengths(
        updatedRooms[currentPoint.roomIndex].vertices
      );
      let lockedWalls = originalRoom.lockedWalls || {};

      for (let index in lockedWalls) {
        const newLength = newLengths[index];
        const originalLength = originalLengths[index];
        const difference = Math.abs(newLength - originalLength);

        console.log(
          `Wall ${index}: newLength = ${newLength}, originalLength = ${originalLength}, difference = ${difference}`
        );

        if (difference > 0.01) {
          // Adjust the threshold for more sensitivity
          isLockedWallChanged = true;
          modifiedLockedWalls[index] = newLength;
        }
      }
    }

    if (currentPoint.type === "rotate") {
      const room = rooms[currentPoint.roomIndex];
      const angleInDegrees = room.rotationAngle * (180 / Math.PI);

      if (getAngleInDergreeCondition(angleInDegrees)) {
        const snappedAngle = snapToNearest45(angleInDegrees) * (Math.PI / 180);

        const center = calculateCentroid(room.vertices);

        const updatedVertices = room.vertices.map((vertex) =>
          rotatePoint(vertex, center, snappedAngle - room.rotationAngle)
        );

        const updatedDoors = rotateDoors(
          room.doors,
          center,
          snappedAngle - room.rotationAngle
        );

        updatedRooms = updatedRooms.map((r, i) =>
          i === currentPoint.roomIndex
            ? {
                ...r,
                vertices: updatedVertices,
                centers: calculateCenters(updatedVertices),
                rotationAngle: 0,
                doors: updatedDoors
              }
            : r
        );

        let updatedSts = { ...floorStaircases };
        updatedSts[floorName] = updatedSts[floorName]?.map((staircase, i) => {
          if (staircase.roomId === room.id) {
            const stCentroid = {
              x: staircase.x + staircase.width / 2,
              y: staircase.y + staircase.height / 2
            };
            const rotatedStaircaseCenter = rotatePoint(
              stCentroid,
              center,
              snappedAngle - room.rotationAngle
            );
            const newRotationAngle =
              staircase.rotationAngle + snappedAngle - room.rotationAngle;
            return {
              ...staircase,
              x: rotatedStaircaseCenter.x - staircase.width / 2,
              y: rotatedStaircaseCenter.y - staircase.height / 2,
              rotationAngle: newRotationAngle
            };
          }
          return staircase;
        });

        setStaircases(updatedSts);

        setRotationIconColor(defaultRotationalColor);
      } else {
        updatedRooms = rooms.map((r, i) =>
          i === currentPoint.roomIndex ? { ...r, rotationAngle: 0 } : r
        );
      }

      setRooms(updatedRooms);
      setIsRotating(false);
    }

    if (currentPoint.type === "wall" && nearestVertices.length > 0) {
      const offsets = nearestVertices.map((nearestVertex) => ({
        offsetX: nearestVertex.otherVertex.x - nearestVertex.movedVertex.x,
        offsetY: nearestVertex.otherVertex.y - nearestVertex.movedVertex.y
      }));

      updatedRooms = updatedRooms.map((room, roomIndex) => {
        if (roomIndex === activeRoomIndex) {
          const { averageOffsetX, averageOffsetY, newVertices } = snapVertices(
            room,
            offsets
          );
          const updatedDoors = updateDoors(
            room,
            averageOffsetX,
            averageOffsetY
          );
          return {
            ...room,
            vertices: newVertices,
            centers: calculateCenters(newVertices),
            doors: updatedDoors
          };
        }
        return room;
      });
    }

    if (highlightedWalls.length > 0) {
      updatedRooms = snapRoomToHighlightedWall(
        updatedRooms,
        highlightedWalls,
        rooms,
        activeRoomIndex
      );

      // Update doors positions after snapping to highlighted walls
      updatedRooms = updatedRooms.map((room) => {
        const updatedDoors = updateDoors(room, 0, 0);
        return { ...room, doors: updatedDoors };
      });
    }

    if (isLockedWallChanged) {
      setManualWallLength({
        roomIndex: currentPoint.roomIndex,
        lengths: modifiedLockedWalls
      });
      setConfirmLengthChange(true);
    } else {
      if (!_.isEqual(updatedRooms, oldRooms)) {
        handleStateChange(updatedRooms); // Use handleStateChange instead of setRooms
      } // Use handleStateChange instead of setRooms

      if (
        currentPoint &&
        (currentPoint.type === "vertex" ||
          currentPoint.type === "wall" ||
          currentPoint.type === "room")
      ) {
        setOriginalVertices(updatedRooms[currentPoint.roomIndex].vertices);
      }
    }

    setHighlightedWalls([]);
    setNearestVertices([]);
    setCurrentPoint(null);
  };

  console.log("originalVertices", originalVertices);

  const handleMouseUp = () => {
    setDraggingDoor(false);
    setIsStaircaseDragging(false);
    stopDragging();

    if (selectedStaircase) {
      let curStaircases = _.cloneDeep(floorStaircases);
      rooms?.forEach((r) => {
        curStaircases[floorName]?.forEach((st) => {
          const offsetVerticesofRoom = calculateOffsetVertices(r.vertices, 8);
          const isStInsideRoom =
            st.type === "straight"
              ? isStaircaseInRoom(st, offsetVerticesofRoom)
              : isLShapedStaircaseInRoom(st, offsetVerticesofRoom);

          if (isStInsideRoom) {
            st.roomId = r.id;
          } else {
            st.roomId = null;
          }
        });
      });

      setStaircases(curStaircases);
    }
    // setSelectedDoor(null)
  };

  const handleWheel = (e) => {
    if (selectedMethod === "square" || selectedMethod === null) {
      setZooming(true);
      e.preventDefault();
      const scaleAmount = -e.deltaY * 0.001;
      const newScale = Math.max(0.1, scale + scaleAmount);

      const rect = e.target.getBoundingClientRect();
      const mousePos = { x: e.clientX - rect.left, y: e.clientY - rect.top };

      const newTranslate = {
        x: translate.x - (mousePos.x - translate.x) * (newScale / scale - 1),
        y: translate.y - (mousePos.y - translate.y) * (newScale / scale - 1)
      };

      setScale(newScale);
      setTranslate(newTranslate);
    }
  };

  const handleLengthChange = () => {
    if (selectedWall) {
      setManualChangeRooms({ rooms: [] });
      const lengthInFeet = parseInt(feet) * 12;
      const lengthInInches = parseInt(inches);
      const totalLengthInInches = lengthInFeet + lengthInInches;
      const lengthInPixels = (totalLengthInInches / 12) * 20;
      handleWallLengthChange(lengthInPixels);
    }
  };

  console.log("Roomss", rooms);
  const handleWallLengthChange = (length) => {
    if (
      selectedWall &&
      rooms[selectedWall.roomIndex].lockedWalls[selectedWall.index] ===
        undefined
    ) {
      const { roomIndex, index } = selectedWall;
      const room = rooms[roomIndex];
      const vertex1 = room.vertices[index];
      const vertex2 = room.vertices[(index + 1) % room.vertices.length];
      const originalLength = Math.sqrt(
        Math.pow(vertex2.x - vertex1.x, 2) + Math.pow(vertex2.y - vertex1.y, 2)
      );

      const angle = Math.atan2(vertex2.y - vertex1.y, vertex2.x - vertex1.x);

      // Define the inner offset value
      const innerOffset = -4;

      // Calculate the length change for each side
      const lengthChange = (length + 8 - originalLength) / 2;

      // Calculate new positions for both vertices with inner offset
      const newVertex1 = {
        x: vertex1.x - lengthChange * Math.cos(angle),
        y: vertex1.y - lengthChange * Math.sin(angle)
      };
      const newVertex2 = {
        x: vertex2.x + lengthChange * Math.cos(angle),
        y: vertex2.y + lengthChange * Math.sin(angle)
      };

      const newVertices = [...room.vertices];
      newVertices[index] = newVertex1;
      newVertices[(index + 1) % newVertices.length] = newVertex2;

      // Store old room vertices for door position check
      const oldVertices = [...room.vertices];

      // Correctly determine the indices of adjacent walls
      const leftWallIndex =
        (index - 1 + room.vertices.length) % room.vertices.length;
      const rightWallIndex = (index + 1) % room.vertices.length;

      // Check lock status of adjacent walls
      const isLeftWallLocked = room.lockedWalls[leftWallIndex] !== undefined;
      const isRightWallLocked = room.lockedWalls[rightWallIndex] !== undefined;

      // Check lock status of adjacent walls
      const isLeftToLeftWallLocked =
        room.lockedWalls[
          leftWallIndex === 0 ? room.vertices?.length - 1 : leftWallIndex - 1
        ] !== undefined;
      const isRightToRightWallLocked =
        room.lockedWalls[rightWallIndex + 1] !== undefined;

      // Update the vertices based on adjacent wall lock status
      if (isLeftWallLocked && isRightWallLocked) {
        newVertices[index] = { ...vertex1 };
        newVertices[(index + 1) % newVertices.length] = { ...vertex2 };
        // If both adjacent walls are locked, do nothing
      } else if (isLeftWallLocked) {
        const totalLengthChange = length + 8 - originalLength;

        // If the left wall is locked, move only the right vertex
        newVertices[(index + 1) % newVertices.length] = {
          x: vertex2.x + totalLengthChange * Math.cos(angle),
          y: vertex2.y + totalLengthChange * Math.sin(angle)
        };
        newVertices[index] = { ...vertex1 };
      } else if (isRightWallLocked) {
        const totalLengthChange = length + 8 - originalLength;

        // If the right wall is locked, move only the left vertex
        newVertices[index] = {
          x: vertex1.x - totalLengthChange * Math.cos(angle),
          y: vertex1.y - totalLengthChange * Math.sin(angle)
        };
        newVertices[(index + 1) % newVertices.length] = { ...vertex2 };
      } else if (isLeftToLeftWallLocked && isRightToRightWallLocked) {
        // If neither adjacent wall is locked, adjust both vertices
        newVertices[index] = newVertex1;
        newVertices[(index + 1) % newVertices.length] = newVertex2;
      } else if (isLeftToLeftWallLocked) {
        const totalLengthChange = length + 8 - originalLength;

        // If the left wall is locked, move only the right vertex
        newVertices[(index + 1) % newVertices.length] = {
          x: vertex2.x + totalLengthChange * Math.cos(angle),
          y: vertex2.y + totalLengthChange * Math.sin(angle)
        };
        newVertices[index] = { ...vertex1 };
      } else if (isRightToRightWallLocked) {
        const totalLengthChange = length + 8 - originalLength;

        // If the right wall is locked, move only the left vertex
        newVertices[index] = {
          x: vertex1.x - totalLengthChange * Math.cos(angle),
          y: vertex1.y - totalLengthChange * Math.sin(angle)
        };
        newVertices[(index + 1) % newVertices.length] = { ...vertex2 };
      } else {
        // If neither adjacent wall is locked, adjust both vertices
        newVertices[index] = newVertex1;
        newVertices[(index + 1) % newVertices.length] = newVertex2;
      }

      // Propagate changes to other vertices to maintain shape
      propagateVertexChanges(
        newVertices,
        oldVertices,
        index,
        (index + 1) % newVertices.length,
        room.lockedWalls
      );

      const isSameLength = Math.abs(length + 8 - originalLength) <= 1;

      const updatedDoors = updateDoorPositions(
        room,
        newVertices,
        index,
        oldVertices
      );

      const updatedRooms = rooms.map((r, i) =>
        i === roomIndex
          ? {
              ...r,
              vertices: newVertices,
              centers: calculateCenters(newVertices),
              doors: updatedDoors,
              lockedWalls: { ...r.lockedWalls, [index]: length + 8 }
            }
          : r
      );

      if (!_.isEqual(updatedRooms, rooms)) {
        handleStateChange(updatedRooms); // Use handleStateChange instead of setRooms
      }

      setRooms(updatedRooms);
    }
  };

  const propagateVertexChanges = (
    newVertices,
    oldVertices,
    startIndex,
    endIndex,
    lockedWalls // Object indicating locked walls with wall index as key
  ) => {
    const numVertices = newVertices.length;

    // Forward propagation: update vertices from endIndex to the end of the array
    let index = (endIndex + 1) % numVertices;

    while (index !== startIndex) {
      const prevIndex = (index - 1 + numVertices) % numVertices;

      const oldVertex = oldVertices[index];
      const newVertex = { ...newVertices[index] }; // Create a shallow copy
      const prevNewVertex = newVertices[prevIndex];
      const prevOldVertex = oldVertices[prevIndex];

      // Check if the current or previous vertex is part of a locked wall
      // A wall is locked if either current or previous vertex is part of a locked wall
      const currentWallIndex = prevIndex; // The wall before the current vertex
      const nextWallIndex = index; // The wall after the current vertex

      if (!lockedWalls[currentWallIndex] && !lockedWalls[nextWallIndex]) {
        if (startIndex === 0) {
          if (prevOldVertex?.x !== prevNewVertex?.x) {
            if (Math.abs(prevOldVertex.x - oldVertex.x) < 0.000001) {
              // Compare with the old and new state of the previous vertex
              newVertex.x = prevNewVertex.x;
            }
          } else if (prevOldVertex?.y !== prevNewVertex?.y) {
            if (Math.abs(prevOldVertex.y - oldVertex.y) < 0.000001) {
              newVertex.y = prevNewVertex.y;
            }
          }
        } else {
          if (prevOldVertex?.x !== prevNewVertex?.x) {
            if (Math.abs(prevOldVertex.x - oldVertex.x) < 0.000001) {
              // Compare with the old and new state of the previous vertex
              newVertex.x = prevNewVertex.x;
            }
          } else if (prevOldVertex?.y !== prevNewVertex?.y) {
            if (Math.abs(prevOldVertex.y - oldVertex.y) < 0.000001) {
              newVertex.y = prevNewVertex.y;
            }
          }
        }
      }

      // Update the current vertex with modified values, if changed
      newVertices[index] = newVertex;

      // Move to the next vertex in the array
      index = (index + 1) % numVertices;

      if (index === startIndex) break;
    }
    // Backward propagation: update vertices from startIndex to the beginning of the array
    index = (startIndex - 1 + numVertices) % numVertices;
    let stop = false;

    while (!stop) {
      const nextIndex = (index + 1) % numVertices;

      const oldVertex = oldVertices[index];
      const newVertex = { ...newVertices[index] }; // Create a shallow copy
      const nextNewVertex = newVertices[nextIndex];
      const nextOldVertex = oldVertices[nextIndex];

      // Check if the current or next vertex is part of a locked wall
      const currentWallIndex = index; // The wall before the current vertex
      const nextWallIndex = nextIndex; // The wall after the current vertex

      if (
        !lockedWalls[currentWallIndex] &&
        !lockedWalls[nextWallIndex] &&
        !lockedWalls[
          currentWallIndex === 0
            ? newVertices?.length - 1
            : currentWallIndex - 1
        ]
      ) {
        // debugger;
        // If nextIndex equals startIndex, check for changes in nextNewVertex and nextOldVertex
        if (nextIndex === startIndex) {
          if (nextOldVertex.x !== nextNewVertex.x) {
            if (Math.abs(nextOldVertex.x - oldVertex.x) < 0.000001) {
              newVertex.x = nextNewVertex.x;
            }
          }
          if (nextOldVertex.y !== nextNewVertex.y) {
            if (Math.abs(nextOldVertex.y - oldVertex.y) < 0.000001) {
              console.log("Updated y:", newVertex.y);
              newVertex.y = nextNewVertex.y;
              console.log("Updated y:1", newVertex.y);
            }
          }
        }
      }

      // Update the current vertex with modified values
      newVertices[index] = newVertex;

      // Check if the loop should stop
      if (index === endIndex) {
        stop = true;
      }

      // Move to the previous vertex in the array
      index = (index - 1 + numVertices) % numVertices;
    }
  };

  const handleWallChangeConfirmation = (confirm) => {
    if (manualWallLength && manualWallLength.roomIndex !== null) {
      const { roomIndex } = manualWallLength;
      const room = rooms[roomIndex];

      if (confirm) {
        if (manualChangeRooms?.rooms?.length > 0) {
          setRooms(manualChangeRooms?.rooms);

          checkAndUpdateParallelWall(
            manualChangeRooms?.rooms,
            manualChangeRooms?.roomIndex,
            manualChangeRooms?.wallIndex,
            manualChangeRooms?.lengthDiff,
            manualChangeRooms?.innerOffset
          );
          setOriginalVertices(null);
          setManualChangeRooms(null);
        } else {
          const updatedRooms = rooms.map((r, i) => {
            if (i === roomIndex) {
              const newLockedWalls = { ...r.lockedWalls };
              for (let index in manualWallLength.lengths) {
                newLockedWalls[index] = manualWallLength.lengths[index];
              }
              return { ...r, lockedWalls: newLockedWalls };
            }
            return r;
          });
          setRooms(updatedRooms);
          setOriginalVertices(null);
        }
        setManualWallLength(null);
        setConfirmLengthChange(false);
      } else {
        const updatedRooms = rooms?.map((r, i) =>
          i === roomIndex
            ? {
                ...r,
                vertices: originalVertices,
                centers: calculateCenters(originalVertices)
              }
            : r
        );

        setRooms(updatedRooms);
        setOriginalVertices(null);
        setManualWallLength(null);
        setConfirmLengthChange(false);
      }
    }
  };

  const checkAndUpdateParallelWall = (
    rooms,
    roomIndex,
    wallIndex,
    lengthDiff,
    innerOffset
  ) => {
    const room = rooms[roomIndex];
    const currentWall = getWallVertices(room.vertices, wallIndex);

    const oldVertices = [...room.vertices];

    // Get the next wall index
    const nextWallIndex = (wallIndex + 2) % room.vertices.length;

    const nextWall = getWallVertices(room.vertices, nextWallIndex);

    const parallelWallIndex = findParallelWall(currentWall, [
      { ...nextWall, index: nextWallIndex }
    ]);

    if (parallelWallIndex !== -1 && !room.lockedWalls[parallelWallIndex]) {
      const parallelWall = getWallVertices(room.vertices, parallelWallIndex);
      const angle = Math.atan2(
        parallelWall.end.y - parallelWall.start.y,
        parallelWall.end.x - parallelWall.start.x
      );

      // Calculate the length change for each side
      const lengthChange = lengthDiff / 2;

      // Calculate new positions for both vertices of the parallel wall with inner offset
      const newParallelStart = {
        x: parallelWall.start.x - lengthChange * Math.cos(angle),
        y: parallelWall.start.y - lengthChange * Math.sin(angle)
      };
      const newParallelEnd = {
        x: parallelWall.end.x + lengthChange * Math.cos(angle),
        y: parallelWall.end.y + lengthChange * Math.sin(angle)
      };

      const newVertices = [...room.vertices];
      newVertices[parallelWallIndex] = newParallelStart;
      newVertices[(parallelWallIndex + 1) % newVertices.length] =
        newParallelEnd;

      const updatedRooms = rooms.map((r, i) =>
        i === roomIndex
          ? {
              ...r,
              vertices: newVertices,
              centers: calculateCenters(newVertices)
            }
          : r
      );

      // Update the door positions after all walls have been updated
      const updatedDoors = room?.doors?.map((door) => {
        const prevWallIndex =
          (selectedWall.index - 1 + oldVertices.length) % oldVertices.length;
        const nextWallIndex = (selectedWall.index + 1) % oldVertices.length;

        const prevWallStart = oldVertices[prevWallIndex];
        const prevWallEnd =
          oldVertices[(prevWallIndex + 1) % oldVertices.length];
        const nextWallStart = oldVertices[nextWallIndex];
        const nextWallEnd =
          oldVertices[(nextWallIndex + 1) % oldVertices.length];

        const isDoorOnPrevWall = isDoorOnWall(door, prevWallStart, prevWallEnd);
        const isDoorOnNextWall = isDoorOnWall(door, nextWallStart, nextWallEnd);

        if (!isDoorOnPrevWall && !isDoorOnNextWall) {
          return door; // Return the door as is if it is not on the adjacent walls
        }

        const wallStart = isDoorOnPrevWall
          ? oldVertices[prevWallIndex]
          : oldVertices[nextWallIndex];
        const wallEnd = isDoorOnPrevWall
          ? oldVertices[(prevWallIndex + 1) % oldVertices.length]
          : newVertices[(nextWallIndex + 1) % newVertices.length];

        const doorCenter = {
          x: door.start.x,
          y: door.start.y
        };

        let nearestWall = null;
        let minDistance = Infinity;
        let bestT = 0;

        // Find the nearest wall to the door center position
        newVertices?.forEach((vertex, vertexIndex) => {
          const nextVertex =
            newVertices[(vertexIndex + 1) % newVertices.length];
          const dx = nextVertex.x - vertex.x;
          const dy = nextVertex.y - vertex.y;
          const wallLength = Math.sqrt(dx * dx + dy * dy);
          const t =
            ((doorCenter.x - vertex.x) * dx + (doorCenter.y - vertex.y) * dy) /
            (wallLength * wallLength);
          const clampedT = Math.max(0, Math.min(1, t));
          const closestPoint = {
            x: vertex.x + clampedT * dx,
            y: vertex.y + clampedT * dy
          };
          const distance = Math.sqrt(
            (doorCenter.x - closestPoint.x) ** 2 +
              (doorCenter.y - closestPoint.y) ** 2
          );
          if (distance < minDistance) {
            minDistance = distance;
            nearestWall = { start: vertex, end: nextVertex };
            bestT = clampedT;
          }
        });

        if (nearestWall) {
          const doorLength = Math.sqrt(
            (door.end.x - door.start.x) ** 2 + (door.end.y - door.start.y) ** 2
          );
          const wallDirection = {
            x: nearestWall.end.x - nearestWall.start.x,
            y: nearestWall.end.y - nearestWall.start.y
          };
          const wallUnitVector = {
            x:
              wallDirection.x /
              Math.sqrt(wallDirection.x ** 2 + wallDirection.y ** 2),
            y:
              wallDirection.y /
              Math.sqrt(wallDirection.x ** 2 + wallDirection.y ** 2)
          };
          const doorStart = {
            x:
              nearestWall.start.x +
              bestT * wallDirection.x -
              (doorLength / 2) * wallUnitVector.x,
            y:
              nearestWall.start.y +
              bestT * wallDirection.y -
              (doorLength / 2) * wallUnitVector.y
          };

          const doorEnd = {
            x: doorStart.x + doorLength * wallUnitVector.x,
            y: doorStart.y + doorLength * wallUnitVector.y
          };

          return { ...door, start: doorStart, end: doorEnd };
        }

        return door;
      });

      const finalRooms = updatedRooms.map((r, i) =>
        i === roomIndex
          ? {
              ...r,
              doors: updatedDoors
            }
          : r
      );

      setRooms(finalRooms);
    }
  };

  const getWallVertices = (vertices, index) => {
    const start = vertices[index];
    const end = vertices[(index + 1) % vertices.length];
    const length = Math.sqrt(
      Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2)
    );
    return { start, end, length };
  };

  const findParallelWall = (currentWall, candidates) => {
    const angleTolerance = 1; // Adjust tolerance as needed
    const lengthTolerance = 20; // Adjust tolerance as needed

    for (const candidate of candidates) {
      const angleDiff = Math.abs(
        Math.atan2(
          candidate.end.y - candidate.start.y,
          candidate.end.x - candidate.start.x
        ) -
          Math.atan2(
            currentWall.end.y - currentWall.start.y,
            currentWall.end.x - currentWall.start.x
          )
      );

      if (
        angleDiff < angleTolerance ||
        Math.abs(angleDiff - Math.PI) < angleTolerance
      ) {
        const distance = pointToSegmentDistance1(
          candidate.start,
          currentWall.start,
          currentWall.end
        );

        if (distance > lengthTolerance) {
          return candidate.index;
        }
      }
    }

    return -1;
  };

  const pointToSegmentDistance1 = (p, v, w) => {
    const l2 = Math.pow(v.x - w.x, 2) + Math.pow(v.y - w.y, 2);
    if (l2 === 0)
      return Math.sqrt(Math.pow(p.x - v.x, 2) + Math.pow(p.y - v.y, 2));
    const t = Math.max(
      0,
      Math.min(1, ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2)
    );
    return Math.sqrt(
      Math.pow(p.x - (v.x + t * (w.x - v.x)), 2) +
        Math.pow(p.y - (v.y + t * (w.y - v.y)), 2)
    );
  };

  const addVertex = () => {
    if (selectedWall) {
      const { roomIndex, index } = selectedWall;
      const room = rooms[roomIndex];

      // Check if the selected wall is locked
      const isWallLocked =
        room.lockedWalls && room.lockedWalls[index] !== undefined;

      if (isWallLocked) {
        // Open confirmation popup if the wall is locked
        setConfirmLockWallChange(true);
      } else {
        // Directly add the vertex if the wall is not locked
        addVertexDirectly();
      }
    }
  };
  const addVertexDirectly = () => {
    if (selectedWall && clickPosition) {
      const { roomIndex, index } = selectedWall;
      const room = rooms[roomIndex];

      const newVertex1 = {
        x: clickPosition.x,
        y: clickPosition.y
      };
      const newVertex2 = {
        x: clickPosition.x,
        y: clickPosition.y
      };

      const newVertices = [
        ...room.vertices.slice(0, index + 1),
        newVertex1,
        newVertex2,
        ...room.vertices.slice(index + 1)
      ];

      const updatedRooms = rooms.map((r, i) => {
        if (i === roomIndex) {
          return {
            ...r,
            vertices: newVertices,
            centers: calculateCenters(newVertices)
          };
        }
        return r;
      });

      if (!_.isEqual(updatedRooms, oldRooms)) {
        handleStateChange(updatedRooms); // Use handleStateChange instead of setRooms
      }
      setSelectedWall({ roomIndex, index: index + 1 });
      setClickPosition(null);
    }
  };
  const handleLockWallChangeConfirmation = (confirm) => {
    if (confirm) {
      if (selectedWall && clickPosition) {
        const { roomIndex, index } = selectedWall;
        const room = rooms[roomIndex];

        const newVertex1 = {
          x: clickPosition.x,
          y: clickPosition.y
        };
        const newVertex2 = {
          x: clickPosition.x,
          y: clickPosition.y
        };

        const newVertices = [
          ...room.vertices.slice(0, index + 1),
          newVertex1,
          newVertex2,
          ...room.vertices.slice(index + 1)
        ];

        // Remove the selected wall from lockedWalls
        const updatedLockedWalls = { ...room.lockedWalls };
        delete updatedLockedWalls[index];

        const updatedRooms = rooms.map((r, i) => {
          if (i === roomIndex) {
            return {
              ...r,
              vertices: newVertices,
              centers: calculateCenters(newVertices),
              lockedWalls: updatedLockedWalls // Update lockedWalls
            };
          }
          return r;
        });

        // setRooms(updatedRooms);
        if (!_.isEqual(updatedRooms, oldRooms)) {
          handleStateChange(updatedRooms); // Use handleStateChange instead of setRooms
        }
        setSelectedWall({ roomIndex, index: index + 1 });
        // setSelectedVertex({ roomIndex, index: index + 1 });
        setClickPosition(null);
      }
    }
    setConfirmLockWallChange(false);
  };

  const rotateRoom = () => {
    if (activeRoomIndex !== null) {
      setIsRotating(true);
      setIsMoving(false);
      setSelectedStaircase(null);
    }
  };
  const moveRoom = () => {
    if (activeRoomIndex !== null) {
      setIsRotating(false);
      setIsMoving(true);
      setSelectedStaircase(null);
    }
  };

  const hideWall = () => {
    if (selectedWall) {
      const { roomIndex, index } = selectedWall;
      const updatedRooms = rooms.map((room, i) => {
        if (i === roomIndex) {
          const hiddenWalls = room.hiddenWalls ? [...room.hiddenWalls] : [];
          if (!hiddenWalls.includes(index)) {
            hiddenWalls.push(index);
          } else {
            const hiddenIndex = hiddenWalls.indexOf(index);
            hiddenWalls.splice(hiddenIndex, 1);
          }
          return { ...room, hiddenWalls };
        }
        return room;
      });

      setRooms(updatedRooms);
      if (!_.isEqual(updatedRooms, oldRooms)) {
        handleStateChange(updatedRooms); // Use handleStateChange instead of setRooms
      }
      setSelectedWall(null);
    }
  };

  const deleteRoom = () => {
    if (activeRoomIndex !== null) {
      const updatedRooms = rooms.filter(
        (_, index) => index !== activeRoomIndex
      );
      setRooms(updatedRooms);
      setActiveRoomIndex(null);
      resetScale(updatedRooms);

      let updatedSts = { ...floorStaircases };
      updatedSts[floorName] = updatedSts[floorName]?.map((st) => {
        return { ...st, shouldStaircaseVisible: true };
      });

      setStaircases(updatedSts);
    }
  };

  const dispatchSeletedRoom = useCallback(
    (roomIndex) => {
      const selectedRoom = rooms[roomIndex];
      dispatch(setRoomToDraw(selectedRoom));
    },
    [rooms, setRoomToDraw]
  );

  const handlePolygonClick = (
    rooms,
    roomIndex,
    drawMethod = selectedMethod
  ) => {
    setSelectedStaircase(null);
    dispatch(setSelectedST(null));
    if (drawMethod === "corner") {
      setClickedOutside(true);
      return;
    }

    const room = rooms[roomIndex];

    let updatedSts = { ...floorStaircases };
    updatedSts[floorName] = updatedSts[floorName]?.map((st) => {
      const offsetVerticesofRoom = calculateOffsetVertices(room.vertices, 8);
      const isStInsideRoom =
        st.type === "straight"
          ? isStaircaseInRoom(st, offsetVerticesofRoom)
          : isLShapedStaircaseInRoom(st, offsetVerticesofRoom);

      if (isStInsideRoom) {
        return { ...st, roomId: room.id, shouldStaircaseVisible: true };
      }
      return { ...st, roomId: null, shouldStaircaseVisible: false };
    });

    setStaircases(updatedSts);

    if (mergeCandidates.length > 0) return;
    setZooming(false);
    // e.stopPropagation();
    setActiveRoomIndex(roomIndex);
    // setIsMoving(true);

    dispatchSeletedRoom(roomIndex);

    const centroid = calculateCentroid(room?.vertices || []);
    const newScale = 1.5; // Adjust the scale value as needed for the zoom level
    const newTranslate = {
      x: svgRef.current.clientWidth / 2 - centroid.x * newScale,
      y: svgRef.current.clientHeight / 2 - centroid.y * newScale
    };

    setScale(newScale);
    setTranslate(newTranslate);
    // setTargetScale(newScale);
    // setTargetTranslate(newTranslate);
    const groupElement = document.getElementById(`group-${roomIndex}`);
    if (groupElement) {
      groupElement.classList.add("zoomable-group");
    }
  };

  const drawRoomWithCornerDefine = useCallback(
    (e) => {
      const newCorner = getMousePosition(e);

      const updatedVertices = [...definedCorners, newCorner];
      setDefinedCorners(updatedVertices);

      // Check if the first and last vertices are close to form a polygon
      if (
        updatedVertices.length > 2 &&
        distanceToLineSegment(
          updatedVertices[0],
          newCorner.x,
          newCorner.y,
          newCorner.x,
          newCorner.y
        ) < Constants.VERTEX_THRESHOLD_RADIUS
      ) {
        const newRoom = {
          id: selectedRoomToDraw.id,
          name: selectedRoomToDraw.name,
          vertices: definedCorners,
          centers: [],
          lockedWalls: {},
          rotationAngle: null,
          hiddenWalls: []
        };
        dispatch(selectMethod("square"));
        const newRooms = [...rooms, newRoom];
        setRooms(newRooms);
        setDefinedCorners([]);

        const roomIndex = newRooms.length - 1;
        handlePolygonClick(newRooms, roomIndex, "square");
      }
    },
    [
      getMousePosition,
      setRooms,
      definedCorners,
      selectedRoomToDraw,
      selectedMethod
    ]
  );

  const handleSvgClick = (e) => {
    setSelectedStaircase(null);
    dispatch(setSelectedST(null));

    let updatedSts = { ...floorStaircases };
    updatedSts[floorName] = updatedSts[floorName]?.map((st) => {
      return { ...st, shouldStaircaseVisible: true };
    });

    setStaircases(updatedSts);

    if (selectedMethod === "corner" && selectedRoomToDraw) {
      drawRoomWithCornerDefine(e);
      setClickedOutside(false);
      return;
    }

    const pos = getMousePosition(e);

    let isInsideAnyRoom = false;
    rooms.forEach((room, index) => {
      if (isPointInPolygon(pos, room.vertices)) {
        isInsideAnyRoom = true;
      } else {
        const groupElement = document.getElementById(`group-${index}`);
        if (groupElement) {
          groupElement.classList.remove("zoomable-group");
        }
      }
    });

    if (!isInsideAnyRoom) {
      setActiveRoomIndex(null);
      setSelectedWall(null);
      setIsMoving(false);
      setSelectedVertex(null);
      setMergeCandidates([]);
      setIsRotating(false);

      dispatch(setRoomToDraw(null));
      resetScale(rooms);
    }

    if (isRotating && activeRoomIndex !== null) {
      const room = rooms[activeRoomIndex];
      const center = calculateCentroid(room.vertices);
      const angle = Math.atan2(pos.y - center.y, pos.x - center.x);
      const initialAngle = angle;

      const rotate = (e) => {
        if (!dragging) return; // Only rotate if dragging
        const newPos = getMousePosition(e);
        const newAngle = Math.atan2(newPos.y - center.y, newPos.x - center.x);
        const angleDiff = newAngle - initialAngle;
        const rotatedVertices = room.vertices.map((vertex) =>
          rotatePoint(vertex, center, angleDiff)
        );
        const updatedRooms = rooms.map((r, i) =>
          i === activeRoomIndex
            ? {
                ...r,
                vertices: rotatedVertices,
                centers: calculateCenters(rotatedVertices),
                rotationAngle: angleDiff
              }
            : r
        );
        setRooms(updatedRooms);
      };

      const stopRotating = () => {
        window.removeEventListener("mousemove", rotate);
        window.removeEventListener("mouseup", stopRotating);
        window.removeEventListener("touchmove", rotate);
        window.removeEventListener("touchend", stopRotating);
      };

      window.addEventListener("mousemove", rotate);
      window.addEventListener("mouseup", stopRotating);
      window.addEventListener("touchmove", rotate);
      window.addEventListener("touchend", stopRotating);
    }
  };

  const selectWall = (e) => {
    if (activeRoomIndex === null) return;
    if (isMoving) return;

    const pos = getMousePosition(e);
    let wallSelected = false;
    const selectedDoors = [];
    rooms[activeRoomIndex]?.vertices?.forEach((vertex, vertexIndex) => {
      const nextVertex =
        rooms[activeRoomIndex].vertices[
          (vertexIndex + 1) % rooms[activeRoomIndex].vertices.length
        ];
      if (
        isPointNearLine(
          pos.x,
          pos.y,
          vertex.x,
          vertex.y,
          nextVertex.x,
          nextVertex.y,
          selectionRadius
        )
      ) {
        setSelectedWall({ roomIndex: activeRoomIndex, index: vertexIndex });
        rooms[activeRoomIndex]?.doors?.forEach((door, doorIndex) => {
          if (isDoorOnWall(door, vertex, nextVertex)) {
            selectedDoors.push(doorIndex);
          }
        });

        setSelectedWallDoors(selectedDoors);

        // setOriginalVertices(JSON.parse(JSON.stringify(rooms[activeRoomIndex].vertices)));
        const dx = nextVertex.x - vertex.x;
        const dy = nextVertex.y - vertex.y;
        const t =
          ((pos.x - vertex.x) * dx + (pos.y - vertex.y) * dy) /
          (dx * dx + dy * dy);
        const clampedT = Math.max(0, Math.min(1, t));
        const newClickPosition = {
          x: vertex.x + clampedT * dx,
          y: vertex.y + clampedT * dy
        };
        setClickPosition(newClickPosition);

        setSelectedVertex(null);
        wallSelected = true;
      }
    });

    if (!wallSelected) {
      setSelectedWall(null);
      setClickPosition(null);
      setSelectedWallDoors([]);
    }
  };

  const drawGrid = useCallback(() => {
    const gridSize = 40;

    // const svg = svgRef.current;
    // if (!svg) return;
    // const width = svg.clientWidth;
    // const height = svg.clientHeight;
    const width = 1000; // Set a specific width
    const height = 1000; // Set a specific height

    const lines = [];
    const plusIcons = [];
    const dots = [];

    for (let x = 0; x <= width; x += gridSize) {
      for (let y = 0; y <= height; y += gridSize) {
        plusIcons.push(
          <text
            key={`plus${x}-${y}`}
            x={x}
            y={y}
            fill="gray"
            fontSize="8"
            textAnchor="middle"
            alignmentBaseline="central"
          >
            +
          </text>
        );

        if (x + gridSize <= width) {
          dots.push(
            <circle
              key={`dotx${x}-${y}`}
              cx={x + gridSize / 2}
              cy={y}
              r={0.8}
              fill="gray"
            />
          );
        }
        if (y + gridSize <= height) {
          dots.push(
            <circle
              key={`doty${x}-${y}`}
              cx={x}
              cy={y + gridSize / 2}
              r={0.8}
              fill="gray"
            />
          );
        }
        if (x + gridSize <= width && y + gridSize <= height) {
          dots.push(
            <circle
              key={`dotxy${x}-${y}`}
              cx={x + gridSize / 2}
              cy={y + gridSize / 2}
              r={0.8}
              fill="gray"
            />
          );
        }
      }
    }

    setGridLines([...lines, ...plusIcons, ...dots]);
  }, [selectedFloor]);

  useEffect(() => {
    drawGrid();
  }, [drawGrid, selectedFloor]);

  /**
   * Apply the transition animation while click the polygon
   */
  useEffect(() => {
    if (groupRef.current) {
      groupRef.current.style.transform = `translate(${translate.x}px, ${translate.y}px) scale(${scale})`;
      staircaseRef.current.style.transform = `translate(${translate.x}px, ${translate.y}px) scale(${scale})`;
      if (activeRoomIndex !== null && isMoving) {
        groupRef.current.style.transition = "none";
        staircaseRef.current.style.transition = "none";
      } else {
        if (!panning && !zooming) {
          groupRef.current.style.transition = `transform ${
            scale === 1 ? "800ms" : "400ms"
          } ease-in-out`;
          staircaseRef.current.style.transition = `transform ${
            scale === 1 ? "800ms" : "400ms"
          } ease-in-out`;
        } else {
          setZooming(false);
          groupRef.current.style.transition = "none";
          staircaseRef.current.style.transition = "none";
        }
      }
    }
  }, [translate, scale, panning, zooming, isMoving]);

  useEffect(() => {
    if (selectedWall) {
      const { roomIndex, index } = selectedWall;
      const room = rooms[roomIndex];
      const vertex1 = room.vertices[index];
      const vertex2 = room.vertices[(index + 1) % room.vertices.length];
      const angle = Math.atan2(vertex2.y - vertex1.y, vertex2.x - vertex1.x);

      const length = Math.sqrt(
        Math.pow(vertex2.x - vertex1.x, 2) + Math.pow(vertex2.y - vertex1.y, 2)
      );
      const innerOffsetX = innerOffset * Math.cos(angle);
      const innerOffsetY = innerOffset * Math.sin(angle);

      // Adjusted start and end points
      const adjustedStart = {
        x: vertex1.x + innerOffsetX,
        y: vertex1.y + innerOffsetY
      };
      const adjustedEnd = {
        x: vertex2.x - innerOffsetX,
        y: vertex2.y - innerOffsetY
      };
      // Calculate the adjusted length
      const adjustedLength = Math.sqrt(
        Math.pow(adjustedEnd.x - adjustedStart.x, 2) +
          Math.pow(adjustedEnd.y - adjustedStart.y, 2)
      );
      const { feet, inches } = convertToFeetAndInches(adjustedLength);
      setFeet(feet);
      setInches(inches);
    }
  }, [selectedWall]);

  const unlockWall = () => {
    if (selectedWall) {
      const { roomIndex, index } = selectedWall;
      const updatedRooms = rooms.map((room, i) => {
        if (i === roomIndex) {
          const newLockedWalls = { ...room.lockedWalls };
          delete newLockedWalls[index];
          return { ...room, lockedWalls: newLockedWalls };
        }
        return room;
      });
      setRooms(updatedRooms);
    }
  };

  const calculateWallLengths = (vertices) => {
    const lengths = [];

    vertices?.forEach((vertex, index) => {
      const nextVertex = vertices[(index + 1) % vertices.length];
      const length = Math.sqrt(
        Math.pow(nextVertex.x - vertex.x, 2) +
          Math.pow(nextVertex.y - vertex.y, 2)
      );
      lengths[index] = length;
    });
    console.log("Calculated wall lengths:", lengths, vertices); // Check lengths here
    return lengths;
  };

  const mergeRoom = (targetRoomIndex) => {
    if (activeRoomIndex === null) return;

    const mergedVertices = mergeRoomsVertices(
      rooms[activeRoomIndex],
      rooms[targetRoomIndex]
    );

    const mergedDoors = [
      ...rooms[activeRoomIndex]?.doors,
      ...rooms[targetRoomIndex].doors
    ];

    const updatedRooms = rooms
      .filter(
        (_, index) => index !== activeRoomIndex && index !== targetRoomIndex
      )
      .concat([
        {
          ...rooms[activeRoomIndex],
          vertices: mergedVertices,
          centers: calculateCenters(mergedVertices),
          doors: mergedDoors
        }
      ]);

    setRooms(updatedRooms);
    setActiveRoomIndex(updatedRooms.length - 1);
    setMergeCandidates([]);
  };

  const restoreWall = () => {
    if (selectedWall) {
      const { roomIndex, index } = selectedWall;
      const updatedRooms = rooms.map((room, i) => {
        if (i === roomIndex) {
          const hiddenWalls = room.hiddenWalls ? [...room.hiddenWalls] : [];
          const hiddenIndex = hiddenWalls.indexOf(index);
          if (hiddenIndex !== -1) {
            hiddenWalls.splice(hiddenIndex, 1);
          }
          return { ...room, hiddenWalls };
        }
        return room;
      });

      setRooms(updatedRooms);
      if (!_.isEqual(updatedRooms, oldRooms)) {
        handleStateChange(updatedRooms); // Use handleStateChange instead of setRooms
      }
      setSelectedWall(null);
    }
  };

  const openCancelDrawingRoomModal = () => {
    setCancelDrawingRoomModal(true);
  };

  const closeCancelDrawingRoomModal = () => {
    setCancelDrawingRoomModal(false);
  };

  const cancelDrawingRoomWithCorner = useCallback(() => {
    dispatch(selectMethod("square"));
    setDefinedCorners([]);
    closeCancelDrawingRoomModal();
  }, [setDefinedCorners, selectMethod]);

  useEffect(() => {
    function preventBehavior(e) {
      e.preventDefault();
    }
    setRedoStack([]);
    setUndoStack([]);

    document.addEventListener("touchmove", preventBehavior, {
      passive: false
    });

    return () => {
      document.removeEventListener("touchmove", preventBehavior);
    };
  }, []);

  const shiftRoomLayerUp = () => {
    if (activeRoomIndex !== null && activeRoomIndex > 0) {
      const updatedRooms = [...rooms];
      const temp = updatedRooms[activeRoomIndex];
      updatedRooms[activeRoomIndex] = updatedRooms[activeRoomIndex - 1];
      updatedRooms[activeRoomIndex - 1] = temp;
      setRooms(updatedRooms);

      if (!_.isEqual(updatedRooms, oldRooms)) {
        handleStateChange(updatedRooms); // Use handleStateChange instead of setRooms
      }
      setActiveRoomIndex(activeRoomIndex - 1);
    }
  };

  const shiftRoomLayerDown = () => {
    if (activeRoomIndex !== null && activeRoomIndex < rooms.length - 1) {
      const updatedRooms = [...rooms];
      const temp = updatedRooms[activeRoomIndex];
      updatedRooms[activeRoomIndex] = updatedRooms[activeRoomIndex + 1];
      updatedRooms[activeRoomIndex + 1] = temp;

      if (!_.isEqual(updatedRooms, oldRooms)) {
        handleStateChange(updatedRooms); // Use handleStateChange instead of setRooms
      }
      setRooms(updatedRooms);
      setActiveRoomIndex(activeRoomIndex + 1);
    }
  };

  const addDoor = () => {
    if (activeRoomIndex === null) return; // Ensure there's an active room selected

    const room = rooms[activeRoomIndex];

    const addDoorToWall = (vertex1, vertex2, roomIndex) => {
      const doorLengthInFeet = 40 / 12; // 2 feet 7 inches in feet
      const doorLengthInPixels = ((doorLengthInFeet * 12) / 20) * 20; // Convert to pixels (assuming grid size of 20 pixels)

      const dx = vertex2.x - vertex1.x;
      const dy = vertex2.y - vertex1.y;
      const wallLength = Math.sqrt(dx * dx + dy * dy);

      // Calculate door position on the wall
      const ratio = doorLengthInPixels / wallLength;
      const doorStartX = vertex1.x + (dx * (1 - ratio)) / 2;
      const doorStartY = vertex1.y + (dy * (1 - ratio)) / 2;
      const doorEndX = vertex1.x + (dx * (1 + ratio)) / 2;
      const doorEndY = vertex1.y + (dy * (1 + ratio)) / 2;

      // Add the new door to the room's doors array
      const updatedRooms = rooms.map((r, i) =>
        i === roomIndex
          ? {
              ...r,
              doors: [
                ...(r.doors || []),
                {
                  start: { x: doorStartX, y: doorStartY },
                  end: { x: doorEndX, y: doorEndY }
                }
              ]
            }
          : r
      );

      setRooms(updatedRooms);
      setSelectedDoor({
        roomIndex,
        doorIndex: updatedRooms[roomIndex].doors.length - 1
      }); // Select the newly added door
      setDraggingDoor(true); // Start dragging the door
    };

    if (!selectedWall) {
      // Add door to the first wall of the active room
      const vertex1 = room.vertices[0];
      const vertex2 = room.vertices[1];
      addDoorToWall(vertex1, vertex2, activeRoomIndex);
    } else {
      // Use the selected wall
      const { roomIndex, index } = selectedWall;
      const room = rooms[roomIndex];
      const vertex1 = room.vertices[index];
      const vertex2 = room.vertices[(index + 1) % room.vertices.length];
      addDoorToWall(vertex1, vertex2, roomIndex);
    }
  };

  const handleDeleteDoor = (roomIndex, doorIndex) => {
    const updatedRooms = rooms.map((room, rIndex) => {
      if (rIndex === roomIndex) {
        const updatedDoors = room.doors.filter(
          (_, dIndex) => dIndex !== doorIndex
        );
        return { ...room, doors: updatedDoors };
      }
      return room;
    });

    setRooms(updatedRooms);
    setSelectedDoor(null);
  };

  const addStaircase = () => {
    setOpenAddStaircaseModal(true);
  };

  const deleteStaircase = useCallback(() => {
    const prevStaircases = _.cloneDeep(floorStaircases);
    prevStaircases?.[floorName]?.splice(selectedStaircase.index, 1);
    setStaircases(prevStaircases);
    setSelectedStaircase(null);
    dispatch(setSelectedST(null));
  }, [selectedStaircase, floorStaircases, floorName, setStaircases]);

  console.log("1");
  const onSelect = (st) => {
    console.log(st, "st**");
    if (st?.roomId) {
      if (activeRoomIndex != null) {
        setSelectedStaircase(st);
        dispatch(setSelectedST(st));
      }
    } else {
      setSelectedStaircase(st);
      setActiveRoomIndex(null);
      dispatch(setRoomToDraw(null));
      dispatch(setSelectedST(st));
    }
    setSelectedWall(null);
  };

  const floorWiseStaircases = useMemo(() => {
    return floorStaircases[floorName];
  }, [floorStaircases, floorName]);

  const shouldAddStaircaseBtnVisible = useMemo(() => {
    const leftStaircases = getLeftStaircases(
      floorStaircases,
      singleCustomerData
    );
    return leftStaircases.length > 0;
  }, [singleCustomerData, floorStaircases, floorName]);

  return (
    <>
      {rooms?.length > 0 || selectedMethod === "corner" ? (
        <>
          <div
            style={{ height: "100%", position: "relative", width: "100%" }}
            onTouchStart={(e) => {
              handleMouseDown(e);
              handleTouchStart(e);
            }}
            onMouseMove={handleMouseMove}
            onTouchMove={(e) => {
              handleMouseMove(e);
              handleTouchMove(e);
            }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchEnd={(e) => {
              handleMouseUp(e);
              handleTouchEnd(e);
            }}
            onWheel={handleWheel}
            onClick={handleSvgClick}
            onTouchCancel={handleMouseUp}
          >
            <svg
              ref={svgRef}
              width={"100%"}
              height={"100%"}
              style={{
                // border: "1px solid #000",
                overflow: "hidden",
                userSelect: "none"
              }}
            >
              {gridLines}
              {selectedMethod === "corner" && (
                <RoomWithCorners
                  definedCorners={definedCorners}
                  clickedOutside={clickedOutside}
                />
              )}

              <defs>
                {rooms.map((room, roomIndex) => {
                  // Create a composite mask that excludes the current room
                  const otherRooms = rooms.filter(
                    (_, index) => index !== roomIndex
                  );

                  return (
                    <mask id={`maskRoom-${roomIndex}`} key={roomIndex}>
                      <rect
                        width="350%"
                        height="350%"
                        fill="white"
                        x="-120%"
                        y="-120%"
                      />
                      {otherRooms.map((otherRoom, otherRoomIndex) => (
                        <polygon
                          key={otherRoomIndex}
                          points={otherRoom.vertices
                            .map((v) => `${v.x},${v.y}`)
                            .join(" ")}
                          fill="black"
                        />
                      ))}
                    </mask>
                  );
                })}
              </defs>
              <g ref={groupRef}>
                {rooms?.map((room, roomIndex) => {
                  const centroid = calculateCentroid(room.vertices);
                  const offsetVertices = calculateOffsetVertices(
                    room.vertices,
                    8
                  ); // Adjust the offset value as needed
                  const verticeCircle = calculateOffsetVertices(
                    room.vertices,
                    -4
                  ); // Adjust the offset value as needed

                  return (
                    <g
                      key={roomIndex}
                      id={`group-${roomIndex}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePolygonClick(rooms, roomIndex);
                      }}
                      onMouseDown={(e) => {
                        if (isMoving && activeRoomIndex == roomIndex) {
                          e.stopPropagation();
                          const pos = getMousePosition(e);
                          const centroid = calculateCentroid(
                            rooms[activeRoomIndex].vertices
                          );
                          setDragging(true);
                          setCurrentPoint({
                            type: "room",
                            roomIndex: activeRoomIndex,
                            offsetX: pos.x - centroid.x,
                            offsetY: pos.y - centroid.y
                          });
                        }
                      }}
                      onTouchStart={(e) => {
                        if (isMoving && activeRoomIndex == roomIndex) {
                          e.stopPropagation();
                          const pos = getMousePosition(e);
                          const centroid = calculateCentroid(
                            rooms[activeRoomIndex].vertices
                          );
                          setDragging(true);
                          setCurrentPoint({
                            type: "room",
                            roomIndex: activeRoomIndex,
                            offsetX: pos.x - centroid.x,
                            offsetY: pos.y - centroid.y
                          });
                        }
                      }}
                      transform={
                        activeRoomIndex === roomIndex && isRotating
                          ? `rotate(${room.rotationAngle || 0} ${centroid.x} ${
                              centroid.y
                            })`
                          : ""
                      }
                    >
                      <polygon
                        points={offsetVertices
                          .map((v) => `${v.x},${v.y}`)
                          .join(" ")}
                        fillOpacity={0}
                        stroke={
                          currentPoint?.type === "room" &&
                          currentPoint?.roomIndex === roomIndex
                            ? ""
                            : activeRoomIndex !== null
                            ? "#B7B7B7"
                            : "#000000"
                        }
                        strokeWidth="8"
                        mask={`url(#maskRoom-${roomIndex})`}
                      />

                      <polygon
                        points={room?.vertices
                          ?.map((v) => `${v.x},${v.y}`)
                          .join(" ")}
                        fill={"#F5F5F5"}
                        // fill={`url(#${patternId})`}
                        fillOpacity={activeRoomIndex === roomIndex ? 0.5 : 1}
                        stroke={
                          currentPoint?.type === "room" &&
                          currentPoint?.roomIndex === roomIndex
                            ? "#A0DEFF"
                            : activeRoomIndex !== null &&
                              activeRoomIndex !== roomIndex
                            ? "#B7B7B7"
                            : "#000000"
                        }
                        strokeWidth="8"
                      />
                      {room?.vertices?.map((vertex, vertexIndex) => {
                        const isSelectedWall =
                          selectedWall &&
                          selectedWall.roomIndex === roomIndex &&
                          selectedWall.index === vertexIndex;

                        const isHighlightedWall = highlightedWalls.some(
                          (wall) =>
                            wall.roomIndex === roomIndex &&
                            wall.vertexIndex === vertexIndex
                        );

                        const isHiddenWall =
                          room.hiddenWalls &&
                          room.hiddenWalls.includes(vertexIndex);
                        const nextVertex =
                          room?.vertices[
                            (vertexIndex + 1) % room.vertices.length
                          ];
                        const dx = nextVertex.x - vertex.x;
                        const dy = nextVertex.y - vertex.y;
                        const length = Math.sqrt(dx * dx + dy * dy);
                        const unitDx = dx / length;
                        const unitDy = dy / length;
                        const halfThickness = 4; // Half the wall thickness, adjust if needed

                        const reducedStartX = vertex.x + unitDx * halfThickness;
                        const reducedStartY = vertex.y + unitDy * halfThickness;
                        const reducedEndX =
                          nextVertex.x - unitDx * halfThickness;
                        const reducedEndY =
                          nextVertex.y - unitDy * halfThickness;

                        return (
                          <g key={vertexIndex}>
                            {isSelectedWall && isHiddenWall && (
                              <path
                                d={`M ${reducedStartX} ${reducedStartY} L ${reducedEndX} ${reducedEndY}`}
                                stroke="white"
                                strokeWidth={"8"}
                              />
                            )}
                            <path
                              d={`M ${reducedStartX} ${reducedStartY} L ${reducedEndX} ${reducedEndY}`}
                              stroke={
                                isSelectedWall
                                  ? "#A0DEFF"
                                  : isHighlightedWall
                                  ? "#40A578"
                                  : isHiddenWall
                                  ? "#FFFFFF"
                                  : currentPoint?.type === "room" &&
                                    currentPoint?.roomIndex === roomIndex
                                  ? "#A0DEFF"
                                  : activeRoomIndex !== null &&
                                    activeRoomIndex !== roomIndex
                                  ? "#B7B7B7"
                                  : "black"
                              }
                              strokeWidth={
                                isSelectedWall || isHighlightedWall
                                  ? "8"
                                  : isHiddenWall
                                  ? "8"
                                  : "8"
                              }
                              strokeDasharray={
                                isSelectedWall && isHiddenWall ? "8, 5" : ""
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                selectWall(e);
                              }}
                            />
                          </g>
                        );
                      })}
                      {!isMoving &&
                        activeRoomIndex === roomIndex &&
                        room?.doors &&
                        drawDoorDimensions(room?.doors, doorDimensionOffset)}

                      {activeRoomIndex === roomIndex &&
                        room?.vertices &&
                        doorSideWallDimension(room, doorDimensionOffset)}

                      {offsetVertices.map((vertex, vertexIndex) => {
                        const isHiddenWall =
                          room.hiddenWalls &&
                          room.hiddenWalls.includes(vertexIndex);
                        const nextVertex =
                          offsetVertices[
                            (vertexIndex + 1) % offsetVertices.length
                          ];
                        const dx = nextVertex.x - vertex.x;
                        const dy = nextVertex.y - vertex.y;
                        const length = Math.sqrt(dx * dx + dy * dy);
                        const unitDx = dx / length;
                        const unitDy = dy / length;
                        const halfThickness = 4; // Half the wall thickness, adjust if needed

                        const reducedStartX = vertex.x + unitDx * halfThickness;
                        const reducedStartY = vertex.y + unitDy * halfThickness;
                        const reducedEndX =
                          nextVertex.x - unitDx * halfThickness;
                        const reducedEndY =
                          nextVertex.y - unitDy * halfThickness;

                        return (
                          <g key={vertexIndex}>
                            {isHiddenWall && (
                              <path
                                d={`M ${reducedStartX} ${reducedStartY} L ${reducedEndX} ${reducedEndY}`}
                                stroke="white"
                                strokeWidth={"8"}
                              />
                            )}
                          </g>
                        );
                      })}

                      {drawTextInsideRoom(
                        room.name,
                        `${calculateArea(room?.vertices, room?.doors).toFixed(
                          2
                        )} sq ft`,
                        centroid,
                        room.vertices,
                        16
                      )}

                      {!isMoving &&
                        activeRoomIndex === roomIndex &&
                        drawDimensions(
                          room.vertices,
                          roomIndex,
                          rooms,
                          dimensionOffset
                        )}
                      {!isMoving &&
                        activeRoomIndex === roomIndex &&
                        verticeCircle.map((vertex, vertexIndex) => (
                          <g key={vertexIndex}>
                            <circle
                              cx={vertex.x}
                              cy={vertex.y}
                              r={selectionRadius - 1} // Reduce the size of the circle by subtracting 2 from selectionRadius
                              fill={
                                hoveredVertex &&
                                hoveredVertex.roomIndex !== roomIndex &&
                                Math.abs(hoveredVertex.x - vertex.x) <
                                  selectionRadius &&
                                Math.abs(hoveredVertex.y - vertex.y) <
                                  selectionRadius
                                  ? "#9DDE8B"
                                  : selectedVertex &&
                                    selectedVertex.roomIndex === roomIndex &&
                                    selectedVertex.index === vertexIndex
                                  ? "#FF0000"
                                  : "#FFFFFF"
                              }
                              stroke="#000000"
                              strokeWidth="1"
                              onMouseDown={(e) => {
                                e.stopPropagation();
                                const pos = getMousePosition(e);
                                setDragging(true);
                                setCurrentPoint({
                                  type: "vertex",
                                  roomIndex,
                                  index: vertexIndex,
                                  offsetX: pos.x - vertex.x,
                                  offsetY: pos.y - vertex.y
                                });
                              }}
                              onTouchStart={(e) => {
                                e.stopPropagation();
                                const pos = getMousePosition(e);
                                setDragging(true);
                                setCurrentPoint({
                                  type: "vertex",
                                  roomIndex,
                                  index: vertexIndex,
                                  offsetX: pos.x - vertex.x,
                                  offsetY: pos.y - vertex.y
                                });
                              }}
                              onDoubleClick={(e) => {
                                e.stopPropagation();
                                setSelectedVertex({
                                  roomIndex,
                                  index: vertexIndex
                                });
                              }}
                            />
                            {draggingIconPosition && (
                              <svg
                                x={draggingIconPosition?.x - 30}
                                y={draggingIconPosition?.y - 30}
                                width="60"
                                height="60"
                                viewBox="0 0 256 256"
                              >
                                <path
                                  fill={draggingIconColor}
                                  d="M238.51,126.95l-21.19-29.92c-1.02-1.44-3.29-.72-3.29,1.05v13.11h-69.22V41.97h13.11c1.76,0,2.49-2.27,1.05-3.29l-29.92-21.19c-.63-.44-1.47-.44-2.09,0l-29.92,21.19c-1.44,1.02-.72,3.29,1.05,3.29h13.11v69.22H41.97v-13.11c0-1.76-2.27-2.49-3.29-1.05l-21.19,29.92c-.44.63-.44,1.47,0,2.09l21.19,29.92c1.02,1.44,3.29.72,3.29-1.05v-13.11h69.22v69.22h-13.11c-1.76,0-2.49,2.27-1.05,3.29l29.92,21.19c.63.44,1.47.44,2.09,0l29.92-21.19c1.44-1.02.72-3.29-1.05-3.29h-13.11v-69.22h69.22v13.11c0,1.76,2.27,2.49,3.29,1.05l21.19-29.92c.44-.63.44-1.47,0-2.09Z"
                                />
                              </svg>
                            )}
                          </g>
                        ))}

                      {mergeCandidates.length > 0 &&
                        activeRoomIndex === roomIndex &&
                        circlePointIcon(centroid)}
                      {!isRotating &&
                        !draggingIconPosition &&
                        isMoving &&
                        mergeCandidates?.length == 0 &&
                        activeRoomIndex === roomIndex && (
                          <svg
                            x={centroid.x - 30}
                            y={centroid.y - 30}
                            width="60"
                            height="60"
                            viewBox="0 0 256 256"
                            fill="#3a80c7"
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              const pos = getMousePosition(e);
                              setDragging(true);
                              setDraggingMoveIcon(true);
                              setCurrentPoint({
                                type: "room",
                                roomIndex,
                                offsetX: pos.x - centroid.x,
                                offsetY: pos.y - centroid.y
                              });
                            }}
                            onTouchStart={(e) => {
                              e.stopPropagation();
                              const pos = getMousePosition(e);
                              setDragging(true);
                              setDraggingMoveIcon(true);
                              setCurrentPoint({
                                type: "room",
                                roomIndex,
                                offsetX: pos.x - centroid.x,
                                offsetY: pos.y - centroid.y
                              });
                            }}
                          >
                            <defs>
                              <style>
                                {`.cls-10 { fill: #3a80c7; stroke-width: 0px; }`}
                              </style>
                            </defs>
                            <path
                              className="cls-10"
                              d="M238.51,126.95l-21.19-29.92c-1.02-1.44-3.29-.72-3.29,1.05v13.11h-69.22V41.97h13.11c1.76,0,2.49-2.27,1.05-3.29l-29.92-21.19c-.63-.44-1.47-.44-2.09,0l-29.92,21.19c-1.44,1.02-.72,3.29,1.05,3.29h13.11v69.22H41.97v-13.11c0-1.76-2.27-2.49-3.29-1.05l-21.19,29.92c-.44.63-.44,1.47,0,2.09l21.19,29.92c1.02,1.44,3.29.72,3.29-1.05v-13.11h69.22v69.22h-13.11c-1.76,0-2.49,2.27-1.05,3.29l29.92,21.19c.63.44,1.47.44,2.09,0l29.92-21.19c1.44-1.02.72-3.29-1.05-3.29h-13.11v-69.22h69.22v13.11c0,1.76,2.27,2.49,3.29,1.05l21.19-29.92c.44-.63.44-1.47,0-2.09Z"
                            />
                          </svg>
                        )}
                      {activeRoomIndex === roomIndex && isRotating && (
                        <RotationIcon
                          x={
                            centroid.x +
                            50 * Math.cos(room.rotationAngle || 0) -
                            30
                          }
                          y={
                            centroid.y +
                            50 * Math.sin(room.rotationAngle || 0) -
                            30
                          }
                          fill="#3a80c7"
                          transform={`rotate(${
                            (room.rotationAngle || 0) * (180 / Math.PI) + 90
                          } ${centroid.x} ${centroid.y})`}
                          rotationIconColor={rotationIconColor}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                            const pos = getMousePosition(e);
                            setDragging(true);
                            setCurrentPoint({
                              type: "rotate",
                              roomIndex,
                              offsetX: pos.x - centroid.x,
                              offsetY: pos.y - centroid.y
                            });
                          }}
                          onTouchStart={(e) => {
                            e.stopPropagation();
                            const pos = getMousePosition(e);
                            setDragging(true);
                            setCurrentPoint({
                              type: "rotate",
                              roomIndex,
                              offsetX: pos.x - centroid.x,
                              offsetY: pos.y - centroid.y
                            });
                          }}
                          iconTransform={`rotate(${
                            (room.rotationAngle || 0) * (180 / Math.PI)
                          } 128 128)`}
                        />
                      )}
                    </g>
                  );
                })}

                {/* Door map */}
                <Doors
                  rooms={rooms}
                  handlePolygonClick={handlePolygonClick}
                  setSelectedDoor={setSelectedDoor}
                  setDraggingDoor={setDraggingDoor}
                  activeRoomIndex={activeRoomIndex}
                  isRotating={isRotating}
                  selectedDoor={selectedDoor}
                />

                {clickPosition && selectedWall && (
                  <circle
                    cx={clickPosition.x}
                    cy={clickPosition.y}
                    r={redDotRadius}
                    fill="#7469B6"
                    stroke="black"
                    strokeWidth="0.3"
                  />
                )}
                <SelectedWalls
                  highlightedWalls={highlightedWalls}
                  elasticLine={elasticLine}
                  selectedWall={selectedWall}
                />
                <NearestWallAndVertex
                  highlightedWalls={highlightedWalls}
                  elasticLine={elasticLine}
                  selectedWall={selectedWall}
                  nearestVertices={nearestVertices}
                  selectionRadius={selectionRadius}
                />

                {mergeCandidates.map((candidate, index) => {
                  const activeRoomCentroid = calculateCentroid(
                    rooms[activeRoomIndex].vertices
                  );
                  const angle = calculateAngle(
                    activeRoomCentroid,
                    candidate.centroid
                  );

                  return (
                    <svg
                      key={index}
                      x={candidate.centroid.x - 30} // Increase the x offset for a larger clickable area
                      y={candidate.centroid.y - 30} // Increase the y offset for a larger clickable area
                      width="50" // Increase the width for a larger clickable area
                      height="50" // Increase the height for a larger clickable area
                      viewBox="0 0 256 256"
                      onClick={() => mergeRoom(candidate.roomIndex)}
                    >
                      <title>merge</title>
                      <rect
                        x="0"
                        y="0"
                        width="50"
                        height="50"
                        fill="transparent"
                        style={{ cursor: "pointer" }}
                      />
                      <g
                        id="Layer_1"
                        data-name="Layer 1"
                        transform={`rotate(${angle - 90}, 128, 128)`}
                      >
                        <path
                          style={{ fill: "#6cb159" }}
                          d="M178.56,80.62l-48.62-62.51c-.98-1.26-2.89-1.26-3.88,0l-48.62,62.51c-1.36,1.75.12,4.27,2.31,3.93l28.18-4.35v98.62c-8.07,6.09-13.32,15.74-13.32,26.64,0,18.44,14.95,33.39,33.39,33.39s33.39-14.95,33.39-33.39c0-10.9-5.24-20.54-13.32-26.64v-98.62l28.18,4.35c2.19.34,3.68-2.18,2.31-3.93Z"
                        />
                      </g>
                    </svg>
                  );
                })}
              </g>

              {/* Render staircase in top of room layer */}
              <g ref={staircaseRef}>
                {floorWiseStaircases?.map((staircase, index) => {
                  if (staircase.shouldStaircaseVisible) {
                    if (staircase.type === "straight") {
                      return (
                        <StraightStaircase
                          ref={svgRef}
                          key={index}
                          index={index}
                          isSelected={selectedStaircase?.index === index}
                          setStaircases={setStaircases}
                          floorName={floorName}
                          onSelect={onSelect}
                          onTouchStart={(e) => {
                            handleMouseDown(e);
                            handleTouchStart(e);
                          }}
                          onMouseMove={handleMouseMove}
                          onTouchMove={(e) => {
                            handleMouseMove(e);
                            handleTouchMove(e);
                          }}
                          onMouseDown={handleMouseDown}
                          onMouseUp={handleMouseUp}
                          onTouchEnd={(e) => {
                            handleMouseUp(e);
                            handleTouchEnd(e);
                          }}
                          translate={translate}
                          scale={scale}
                          {...staircase}
                        />
                      );
                    } else if (staircase.type === "lshape") {
                      return (
                        <LShapeStaircase
                          ref={svgRef}
                          key={index}
                          index={index}
                          selectedStaircase={selectedStaircase}
                          isSelected={selectedStaircase?.index === index}
                          setStaircases={setStaircases}
                          floorName={floorName}
                          onSelect={onSelect}
                          onTouchStart={(e) => {
                            handleMouseDown(e);
                            handleTouchStart(e);
                          }}
                          onMouseMove={handleMouseMove}
                          onTouchMove={(e) => {
                            handleMouseMove(e);
                            handleTouchMove(e);
                          }}
                          onMouseDown={handleMouseDown}
                          onMouseUp={handleMouseUp}
                          onTouchEnd={(e) => {
                            handleMouseUp(e);
                            handleTouchEnd(e);
                          }}
                          translate={translate}
                          scale={scale}
                          {...staircase}
                        />
                      );
                    }
                  }
                  return null;
                })}
              </g>
            </svg>

            <Modal
              open={confirmLengthChange}
              onClose={() => setConfirmLengthChange(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <ConfirmationPopUp
                onClick={handleWallChangeConfirmation}
                label={"Confirm Length Change"}
                description={
                  "Are you sure you want to change the length of the wall? This wall is locked."
                }
              />
            </Modal>
            <Modal
              open={confirmLockWallChange}
              onClose={() => setConfirmLockWallChange(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <ConfirmationPopUp
                onClick={handleLockWallChangeConfirmation}
                label={"Confirm Lock Wall Change"}
                description={
                  "Are you sure you want to add new vertex to this wall? This wall is locked."
                }
              />
            </Modal>
          </div>
          <DrawingPageSideButtons
            selectedWall={selectedWall}
            activeRoomIndex={activeRoomIndex}
            rotateRoom={rotateRoom}
            isMoving={isMoving}
            moveRoom={moveRoom}
            setIsMoving={setIsMoving}
            prepareMergeRoom={prepareMergeRoom}
            rooms={rooms}
            addDoor={addDoor}
            handleDeleteDoor={handleDeleteDoor}
            shiftRoomLayerDown={shiftRoomLayerDown}
            shiftRoomLayerUp={shiftRoomLayerUp}
            undoStack={undoStack}
            redoStack={redoStack}
            undo={undo}
            redo={redo}
            addStaircase={addStaircase}
            shouldAddStaircaseBtnVisible={shouldAddStaircaseBtnVisible}
            deleteStaircase={deleteStaircase}
            selectedStaircase={selectedStaircase}
            openCancelDrawingRoomModal={openCancelDrawingRoomModal}
            selectedMethod={selectedMethod}
            addVertex={addVertex}
            selectedDoor={selectedDoor}
            restoreWall={restoreWall}
            hideWall={hideWall}
            deleteRoom={deleteRoom}
          />

          {selectedWall && (
            <WallLengthControls
              feet={feet}
              inches={inches}
              handleLengthChange={handleLengthChange}
              unlockWall={unlockWall}
              lockedWall={
                rooms[selectedWall.roomIndex].lockedWalls[
                  selectedWall.index
                ] !== undefined
              }
              setFeet={setFeet}
              setInches={setInches}
            />
          )}

          <CancelConfirmationModal
            open={cancelDrawingRoomModal}
            onClose={closeCancelDrawingRoomModal}
            cancelDrawingRoomWithCorner={cancelDrawingRoomWithCorner}
          />
        </>
      ) : (
        "Kindly select a room to draw a plan"
      )}
      <StaircaseSelectModal
        open={openAddStaircaseModal}
        onClose={() => setOpenAddStaircaseModal(false)}
      />
    </>
  );
};

export default SvgCanvas;
