import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { setMode, setSelectedST } from "../../redux/floorPlan";
import FloorWithRooms from "./FloorWithRooms";
import { Catalogue } from "../product-catalogue/catalogue";
import ProductEstimation from "./ProductEstimation";
import { Tooltip } from "@mui/material";
import { getAuth } from "../../services/identity.service";

const MODES = [
  { key: "drawing", title: "Drawing" },
  { key: "products", title: "Products" },
  { key: "estimation", title: "Estimation" },
  {
    key: "measure",
    title: <img src="/images/measurement.svg" className="w-5 h-5" />,
    tooltip: "Measurement"
  }
];

const Floors = (props) => {
  const { closeMainModal, saveFloorPlanData, isSaving } = props;

  const selectedMode = useSelector((state) => state.floorPlan.selectedMode);
  const dispatch = useDispatch();

  const changeMode = useCallback(
    (mode) => {
      dispatch(setMode(mode));
      dispatch(setSelectedST(null));
    },
    [setMode]
  );
  const loginUserEmail = getAuth();

  return (
    <div className="m-1 relative h-full">
      <div className="mt-2 mb-1 max-w-max">
        <div className="flex justify-center items-center gap-[2px]">
          {MODES.map((mode, i) => {
            if (
              i === 3 &&
              loginUserEmail?.email !== "jenish.vaghani@teqnodux.com"
            ) {
              return null; // Don't render the measurement option for other users
            }

            return (
              <motion.div
                whileTap={{ scale: 0.9 }}
                className={`text-[13px] border-[1px] rounded-md px-2 py-1 my-[2px] flex justify-center items-center ${
                  selectedMode === mode.key && "bg-[#007FFF] text-white"
                }`}
                onClick={() => changeMode(mode.key)}
              >
                <Tooltip title={mode.tooltip}>{mode.title}</Tooltip>
              </motion.div>
            );
          })}
        </div>
      </div>
      <div className="w-full h-[1px] bg-gray-400" />
      {selectedMode === "drawing" && (
        <FloorWithRooms
          isSaving={isSaving}
          saveFloorPlanData={saveFloorPlanData}
          closeMainModal={closeMainModal}
        />
      )}
      {selectedMode === "products" && <Catalogue />}
      {selectedMode === "estimation" && <ProductEstimation />}
    </div>
  );
};

export default Floors;
