import React from "react";

const SelectedWalls = ({ highlightedWalls, selectedWall }) => {
  return (
    <>
      {highlightedWalls.length > 0 && selectedWall && (
        <>
          {highlightedWalls.map((highlightedWall, index) => {
            const { movedWall, otherWall } = highlightedWall;

            // Define a small offset value
            const offset = 20;

            // Function to adjust points slightly away along the wall
            const adjustPointOnWall = (x1, y1, x2, y2, offset) => {
              const length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
              const unitDx = (x2 - x1) / length;
              const unitDy = (y2 - y1) / length;
              return {
                x1: x1 + unitDx * offset,
                y1: y1 + unitDy * offset,
                x2: x2 - unitDx * offset,
                y2: y2 - unitDy * offset
              };
            };

            // Adjust points slightly away from the original vertices along the wall direction
            const adjustedMovedWall = adjustPointOnWall(
              movedWall.x1,
              movedWall.y1,
              movedWall.x2,
              movedWall.y2,
              offset
            );
            const adjustedOtherWall = adjustPointOnWall(
              otherWall.x1,
              otherWall.y1,
              otherWall.x2,
              otherWall.y2,
              offset
            );

            // Define the four points of the polygon
            const polygonPoints = [
              { x: adjustedMovedWall.x1, y: adjustedMovedWall.y1 },
              { x: adjustedMovedWall.x2, y: adjustedMovedWall.y2 },
              { x: adjustedOtherWall.x1, y: adjustedOtherWall.y1 },
              { x: adjustedOtherWall.x2, y: adjustedOtherWall.y2 }
            ];

            // Define the path data for the curved polygon
            const pathData = `
                        M ${polygonPoints[0].x},${polygonPoints[0].y}
                        Q ${(polygonPoints[0].x + polygonPoints[1].x) / 2},${
              (polygonPoints[0].y + polygonPoints[1].y) / 2
            } ${polygonPoints[1].x},${polygonPoints[1].y}
                        L ${polygonPoints[2].x},${polygonPoints[2].y}
                        Q ${(polygonPoints[2].x + polygonPoints[3].x) / 2},${
              (polygonPoints[2].y + polygonPoints[3].y) / 2
            } ${polygonPoints[3].x},${polygonPoints[3].y}
                        Z
                      `;

            return (
              <path
                key={index}
                d={pathData}
                fill="rgba(157, 222, 139, 0.5)"
                stroke="green"
                strokeWidth="1"
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default SelectedWalls;
