import { v4 as uuidv4 } from "uuid";

export const refishingCheckList = [
  {
    id: uuidv4(),
    level: 1,
    question: "Can Floor be buffed & recoated?",
    answer: "none",
    type: "BOOLEAN",
    col: 6,
    mandatory: true,
    subQuestion: [
      {
        id: uuidv4(),
        level: 2,
        question: "Conf. no waxes or polishes on the floor?",
        answer: "",
        type: "DROPDOWN",
        col: 6,
        mandatory: true,
        option: [
          "Test not yet completed",
          "Test was negative",
          "Test was inconclusive",
        ],
        whenToShow: true,
      },
      {
        id: uuidv4(),
        level: 2,
        question: "Buffing and Recoating Notes",
        answer: "",
        type: "TEXT",
        col: 6,
        whenToShow: true,
      },
    ],
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Confirmation of enough material for sanding?",
    answer: "none",
    type: "BOOLEAN",
    col: 6,
    mandatory: true,
    subQuestion: [
      {
        id: uuidv4(),
        level: 2,
        question: "Solid / Engineered ",
        answer: "",
        type: "DROPDOWN",
        col: 12,
        mandatory: true,
        whenToShow: true,
        option: ["Solid", "Engineered"],
        subQuestion: [
          {
            id: uuidv4(),
            level: 3,
            question: "Wear Layer thickness If Eng.",
            answer: "",
            type: "TEXT",
            col: 12,
            mandatory: true,
            whenToShow: "Engineered",
          },
        ],
      },
      {
        id: uuidv4(),
        level: 2,
        question: "Add Images",
        answer: [],
        type: "IMAGE",
        col: 12,
        mandatory: false,
        whenToShow: true,
      },
      {
        id: uuidv4(),
        level: 2,
        question: "Notes",
        answer: "",
        type: "TEXT",
        col: 12,
        mandatory: false,
        whenToShow: true,
      },
      {
        id: uuidv4(),
        level: 2,
        question: "Details for thickness of wear layer (confirmed to thin)",
        answer: "",
        type: "TEXT",
        col: 12,
        mandatory: true,
        whenToShow: false,
      },
    ],
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Excessive damage (ex. pet stains,cracked boards)",
    answer: "none",
    type: "BOOLEAN",
    col: 6,
    mandatory: true,
    subQuestion: [
      {
        id: uuidv4(),
        level: 2,
        question: "Pet Stains",
        answer: false,
        type: "BOOLEAN",
        col: 4,
        whenToShow: true,
        subQuestion: [
          {
            id: uuidv4(),
            level: 3,
            question: "Note for pet stains",
            answer: "",
            type: "TEXT",
            col: 12,
            whenToShow: true,
          },
        ],
      },
      {
        id: uuidv4(),
        level: 2,
        question: "Cracked Boards",
        answer: false,
        type: "BOOLEAN",
        col: 4,
        whenToShow: true,
        subQuestion: [
          {
            id: uuidv4(),
            level: 3,
            question: "Note for cracked boards",
            answer: "",
            type: "TEXT",
            col: 12,
            whenToShow: true,
          },
        ],
      },
      {
        id: uuidv4(),
        level: 2,
        question: "Required Repairs",
        answer: false,
        type: "BOOLEAN",
        col: 4,
        whenToShow: true,
        subQuestion: [
          {
            id: uuidv4(),
            level: 3,
            question: "Note for Required Repairs",
            answer: "",
            type: "TEXT",
            col: 12,
            whenToShow: true,
          },
        ],
      },
      {
        id: uuidv4(),
        level: 2,
        question: "Weave Ins",
        answer: false,
        type: "BOOLEAN",
        col: 4,
        whenToShow: true,
        subQuestion: [
          {
            id: uuidv4(),
            level: 3,
            question: "Note for Weave Ins",
            answer: "",
            type: "TEXT",
            col: 12,
            whenToShow: true,
          },
        ],
      },
      {
        id: uuidv4(),
        level: 2,
        question: "Loose Boards",
        answer: false,
        type: "BOOLEAN",
        col: 4,
        whenToShow: true,
        subQuestion: [
          {
            id: uuidv4(),
            level: 3,
            question: "Note for Loose Boards",
            answer: "",
            type: "TEXT",
            col: 12,
            whenToShow: true,
          },
        ],
      },
      {
        id: uuidv4(),
        level: 2,
        question: "Floor Sequeaking",
        answer: false,
        type: "BOOLEAN",
        col: 4,
        whenToShow: true,
        subQuestion: [
          {
            id: uuidv4(),
            level: 3,
            question: "Note for Floor Sequeaking",
            answer: "",
            type: "TEXT",
            col: 12,
            whenToShow: true,
          },
        ],
      },
      {
        id: uuidv4(),
        level: 2,
        question: "Significant Gaps",
        answer: false,
        type: "BOOLEAN",
        col: 4,
        whenToShow: true,
        subQuestion: [
          {
            id: uuidv4(),
            level: 3,
            question: "Note for Significant Gaps",
            answer: "",
            type: "TEXT",
            col: 12,
            whenToShow: true,
          },
        ],
      },
      {
        id: uuidv4(),
        level: 2,
        question: "Other",
        answer: false,
        type: "BOOLEAN",
        col: 4,
        whenToShow: true,
        subQuestion: [
          {
            id: uuidv4(),
            level: 3,
            question: "Note for Other Damage",
            answer: "",
            type: "TEXT",
            col: 12,
            whenToShow: true,
          },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Surface issues ( ex. mastic, paint, wax)",
    answer: "none",
    type: "BOOLEAN",
    col: 6,
    mandatory: true,
    subQuestion: [
      {
        id: uuidv4(),
        level: 2,
        question: "Mastic (Sticky)",
        answer: false,
        type: "BOOLEAN",
        col: 4,
        whenToShow: true,
        subQuestion: [
          {
            id: uuidv4(),
            level: 3,
            question: "Note for mastic (sticky)",
            answer: "",
            type: "TEXT",
            col: 12,
            whenToShow: true,
          },
        ],
      },
      {
        id: uuidv4(),
        level: 2,
        question: "Paint",
        answer: false,
        type: "BOOLEAN",
        col: 4,
        whenToShow: true,
        subQuestion: [
          {
            id: uuidv4(),
            level: 3,
            question: "Note for Paint",
            answer: "",
            type: "TEXT",
            col: 12,
            whenToShow: true,
          },
        ],
      },
      {
        id: uuidv4(),
        level: 2,
        question: "wax",
        answer: false,
        type: "BOOLEAN",
        col: 4,
        whenToShow: true,
        subQuestion: [
          {
            id: uuidv4(),
            level: 3,
            question: "Note for wax",
            answer: "",
            type: "TEXT",
            col: 12,
            whenToShow: true,
          },
        ],
      },
      {
        id: uuidv4(),
        level: 2,
        question: "Top Nailed Boards",
        answer: false,
        type: "BOOLEAN",
        col: 4,
        whenToShow: true,
        subQuestion: [
          {
            id: uuidv4(),
            level: 3,
            question: "Note for top Nailed Boards",
            answer: "",
            type: "TEXT",
            col: 12,
            whenToShow: true,
          },
        ],
      },
      {
        id: uuidv4(),
        level: 2,
        question: "Rug Marks",
        answer: false,
        type: "BOOLEAN",
        col: 4,
        whenToShow: true,
        subQuestion: [
          {
            id: uuidv4(),
            level: 3,
            question: "Note for Rug Marks",
            answer: "",
            type: "TEXT",
            col: 12,
            whenToShow: true,
          },
        ],
      },
      {
        id: uuidv4(),
        level: 2,
        question: "Other",
        answer: false,
        type: "BOOLEAN",
        col: 4,
        whenToShow: true,
        subQuestion: [
          {
            id: uuidv4(),
            level: 3,
            question: "Note for Other",
            answer: "",
            type: "TEXT",
            col: 12,
            whenToShow: true,
          },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    level: 1,
    question: "Notes",
    answer: "",
    type: "TEXT",
    col: 12,
  },
];
