import React from "react";
import {
  convertToFeetAndInches,
  isDoorOnWall
} from "../../../utils/FloorPlanFunc/FloorPlanFunc";

const doorSideWallDimension = (room, doorDimensionOffset) => {
  const drawTriangle = (x, y, angle) => {
    const triangleSize = 3;
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);
    return `
          M ${x} ${y}
          L ${x - triangleSize * cos + triangleSize * sin} ${
      y - triangleSize * sin - triangleSize * cos
    }
          L ${x - triangleSize * cos - triangleSize * sin} ${
      y - triangleSize * sin + triangleSize * cos
    }
          Z
        `;
  };

  return room.vertices.map((vertex, vertexIndex) => {
    const nextVertexIndex = (vertexIndex + 1) % room.vertices.length;
    const nextVertex = room.vertices[nextVertexIndex];

    // Find doors on this wall segment
    const doorsOnSegment = room?.doors?.filter((door) =>
      isDoorOnWall(door, vertex, nextVertex)
    );

    // Only show dimensions if there are doors on the segment
    if (
      doorsOnSegment?.length === 0 ||
      !room?.doors ||
      room?.doors?.length === 0
    ) {
      return null;
    }

    // Split wall into segments based on doors
    const segments = [];
    if (doorsOnSegment?.length > 0) {
      doorsOnSegment.forEach((door, index) => {
        if (index === 0) {
          segments.push({
            start: vertex,
            end: door.start
          });
        }
        if (index === doorsOnSegment.length - 1) {
          segments.push({
            start: door.end,
            end: nextVertex
          });
        }
        if (index > 0) {
          segments.push({
            start: doorsOnSegment[index - 1].end,
            end: door.start
          });
        }
      });
    } else {
      segments.push({
        start: vertex,
        end: nextVertex
      });
    }

    return segments?.map((segment, segmentIndex) => {
      const angle = Math.atan2(
        segment.end.y - segment.start.y,
        segment.end.x - segment.start.x
      );
      const offsetX = doorDimensionOffset * Math.cos(angle - Math.PI / 2);
      const offsetY = doorDimensionOffset * Math.sin(angle - Math.PI / 2);

      const innerOffset = 4; // Define the inner offset value
      const innerOffsetX = innerOffset * Math.cos(angle);
      const innerOffsetY = innerOffset * Math.sin(angle);

      // Adjusted start and end points
      const adjustedStart = {
        x: segment.start.x + (segment.start === vertex ? innerOffsetX : 0),
        y: segment.start.y + (segment.start === vertex ? innerOffsetY : 0)
      };
      const adjustedEnd = {
        x: segment.end.x - (segment.end === nextVertex ? innerOffsetX : 0),
        y: segment.end.y - (segment.end === nextVertex ? innerOffsetY : 0)
      };

      // Calculate the adjusted length
      const adjustedLength = Math.sqrt(
        Math.pow(adjustedEnd.x - adjustedStart.x, 2) +
          Math.pow(adjustedEnd.y - adjustedStart.y, 2)
      );

      const midX = (adjustedStart.x + adjustedEnd.x) / 2;
      const midY = (adjustedStart.y + adjustedEnd.y) / 2;

      const { feet, inches } = convertToFeetAndInches(adjustedLength);
      const dimensionText = `${feet}'${inches}"`;
      const textX = midX + offsetX * 1.5;
      const textY = midY + offsetY * 1.5;

      // Ensure correct offset only from vertices
      const isAdjustedFromVertex = (point, vertex) =>
        point.x === vertex.x && point.y === vertex.y;

      return (
        <g key={`${vertexIndex}-${segmentIndex}`}>
          {/* Reference dotted lines */}
          <line
            x1={
              isAdjustedFromVertex(segment.start, vertex)
                ? adjustedStart.x
                : segment.start.x
            }
            y1={
              isAdjustedFromVertex(segment.start, vertex)
                ? adjustedStart.y
                : segment.start.y
            }
            x2={
              isAdjustedFromVertex(segment.start, vertex)
                ? adjustedStart.x + offsetX
                : segment.start.x + offsetX
            }
            y2={
              isAdjustedFromVertex(segment.start, vertex)
                ? adjustedStart.y + offsetY
                : segment.start.y + offsetY
            }
            stroke="#5c5c5c"
            strokeWidth="0.5"
            strokeDasharray="1.5,1.2"
          />
          <line
            x1={
              isAdjustedFromVertex(segment.end, nextVertex)
                ? adjustedEnd.x
                : segment.end.x
            }
            y1={
              isAdjustedFromVertex(segment.end, nextVertex)
                ? adjustedEnd.y
                : segment.end.y
            }
            x2={
              isAdjustedFromVertex(segment.end, nextVertex)
                ? adjustedEnd.x + offsetX
                : segment.end.x + offsetX
            }
            y2={
              isAdjustedFromVertex(segment.end, nextVertex)
                ? adjustedEnd.y + offsetY
                : segment.end.y + offsetY
            }
            stroke="#5c5c5c"
            strokeWidth="0.5"
            strokeDasharray="1.5,1.2"
          />

          {/* Dimension line */}
          <line
            x1={
              isAdjustedFromVertex(segment.start, vertex)
                ? adjustedStart.x + offsetX
                : segment.start.x + offsetX
            }
            y1={
              isAdjustedFromVertex(segment.start, vertex)
                ? adjustedStart.y + offsetY
                : segment.start.y + offsetY
            }
            x2={
              isAdjustedFromVertex(segment.end, nextVertex)
                ? adjustedEnd.x + offsetX
                : segment.end.x + offsetX
            }
            y2={
              isAdjustedFromVertex(segment.end, nextVertex)
                ? adjustedEnd.y + offsetY
                : segment.end.y + offsetY
            }
            stroke="#5c5c5c"
            strokeWidth="1"
          />
          <path
            d={drawTriangle(
              isAdjustedFromVertex(segment.start, vertex)
                ? adjustedStart.x + offsetX
                : segment.start.x + offsetX,
              isAdjustedFromVertex(segment.start, vertex)
                ? adjustedStart.y + offsetY
                : segment.start.y + offsetY,
              angle + Math.PI
            )}
            fill="#5c5c5c"
          />
          <path
            d={drawTriangle(
              isAdjustedFromVertex(segment.end, nextVertex)
                ? adjustedEnd.x + offsetX
                : segment.end.x + offsetX,
              isAdjustedFromVertex(segment.end, nextVertex)
                ? adjustedEnd.y + offsetY
                : segment.end.y + offsetY,
              angle
            )}
            fill="#5c5c5c"
          />
          <text
            x={textX}
            y={textY}
            fontSize="8"
            fill="#5c5c5c"
            alignmentBaseline="middle"
            textAnchor="middle"
          >
            {dimensionText}
          </text>
        </g>
      );
    });
  });
};

export default doorSideWallDimension;
