import React from "react";
import { isPointInPolygon } from "../../../utils/svgFunctions";

const drawTextInsideRoom = (
  roomName,
  sqft,
  centroid,
  vertices,
  initialFontSize
) => {
  let fontSize = initialFontSize;

  const calculateBoundingBox = (text, x, y, fontSize) => {
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    const textElement = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "text"
    );
    textElement.setAttribute("x", x);
    textElement.setAttribute("y", y);
    textElement.setAttribute("font-size", fontSize);
    textElement.textContent = text;
    svg.appendChild(textElement);
    document.body.appendChild(svg);
    const bbox = textElement.getBBox();
    document.body.removeChild(svg);
    return bbox;
  };

  const isBoundingBoxInsidePolygon = (bbox, vertices) => {
    const points = [
      { x: bbox.x, y: bbox.y },
      { x: bbox.x + bbox.width, y: bbox.y },
      { x: bbox.x + bbox.width, y: bbox.y + bbox.height },
      { x: bbox.x, y: bbox.y + bbox.height }
    ];

    return points.every((point) => isPointInPolygon(point, vertices));
  };

  const getAdjustedTextPosition = (
    text1,
    text2,
    x,
    y,
    vertices,
    initialFontSize
  ) => {
    let fontSize = initialFontSize;
    let bbox1 = calculateBoundingBox(text1, x, y, fontSize);
    let bbox2 = calculateBoundingBox(text2, x, y + 15, fontSize);

    while (
      !(
        isBoundingBoxInsidePolygon(bbox1, vertices) &&
        isBoundingBoxInsidePolygon(bbox2, vertices)
      ) &&
      fontSize > 6
    ) {
      fontSize -= 1;
      bbox1 = calculateBoundingBox(text1, x, y, fontSize);
      bbox2 = calculateBoundingBox(text2, x, y + 15, fontSize);
    }

    return { x, y, fontSize };
  };

  const {
    x,
    y,
    fontSize: adjustedFontSize
  } = getAdjustedTextPosition(
    roomName,
    sqft,
    centroid.x,
    centroid.y,
    vertices,
    fontSize
  );

  return (
    <>
      <text
        x={x}
        y={y}
        textAnchor="middle"
        fontSize={adjustedFontSize}
        fill="#000000"
        style={{ userSelect: "none" }}
      >
        {roomName}
      </text>
      <text
        x={x}
        y={y + 15}
        textAnchor="middle"
        fontSize={adjustedFontSize - 4}
        fill="#000000"
        style={{ userSelect: "none" }}
      >
        {sqft}
      </text>
    </>
  );
};
export default drawTextInsideRoom;
