import React, { useCallback, useState } from "react";
import { Dialog, DialogContent, Grid, styled } from "@mui/material";
import { motion } from "framer-motion";
import FloorPlanDrawer from "./FloorPlanDrawer";
import Floors from "./Floors";
import SaveConfirmationModal from "./SaveConfirmationModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateFloorPlanData } from "../../services/customers.service";
import {
  setInitialDataForDrawingRooms,
  setStaircasesForPlan
} from "../../redux/floorPlan";
import MuiSnackbar from "../UI/MuiSnackbar";
import { usePreview } from "react-dnd-preview";
import { Card, Typography } from "antd";
import { AiOutlineMenu } from "react-icons/ai";
import { Constants } from "../../utils/Constants";
import { calculateArea } from "../../utils/svgFunctions";
import _, { toNumber } from "lodash";
import { updateSingleCustomerApi } from "../../redux/customer";

const DRAG_ICON_STYLE = {
  cursor: "grab",
  fontSize: "20px",
  color: "white",
  zIndex: 10,
  marginRight: "9px",
  marginLeft: "11px",
  marginTop: "2px",
  width: "15px"
};

const CustomDialog = styled(Dialog)({
  overflow: "visible",
  "& .MuiDialog-paper": {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: 0
  }
});

const CustomDialogContent = styled(DialogContent)({
  width: "100%",
  height: "100%"
});

const FloorPlanModal = (props) => {
  const { open, setOpenFloorPlanDrawer } = props;
  const [saveConfirmationModalOpen, setSaveConfirmationModalOpen] =
    useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const singleCustomerData = useSelector(
    (state) => state.customerReducer.singleCustomer
  );
  const floors = singleCustomerData.scope?.floors || [];

  const params = useParams();
  const drawingRooms = useSelector((state) => state.floorPlan.drawingRooms);
  const floorStaircases = useSelector(
    (state) => state.floorPlan.floorStaircases
  );
  const dispatch = useDispatch();

  const onClose = () => {
    setSaveConfirmationModalOpen(true);
  };

  const getRoomArea = useCallback(
    (room) => {
      if (!room?.vertices) return 0;
      return calculateArea(room.vertices)?.toFixed(2);
    },
    [calculateArea]
  );

  // Function to calculate the perimeter in pixels considering an 8-pixel offset for the wall
  const calculatePerimeter = (vertices) => {
    if (!vertices || vertices.length < 2) return 0;

    let perimeter = 0;
    const offset = 8; // 8-pixel offset to be subtracted

    for (let i = 0; i < vertices.length; i++) {
      const currentVertex = vertices[i];
      const nextVertex = vertices[(i + 1) % vertices.length]; // Wrap around to the first vertex

      const dx = nextVertex.x - currentVertex.x;
      const dy = nextVertex.y - currentVertex.y;

      let distance = Math.sqrt(dx * dx + dy * dy);

      // Adjust the distance by subtracting the offset
      distance = Math.max(0, distance - offset); // Ensure distance doesn't become negative

      perimeter += distance;
    }

    return perimeter;
  };

  // Function to convert pixels to feet
  const convertPixelsToFeet = (pixels) => {
    const feet = pixels / 20; // Convert pixels to feet (20 pixels = 1 foot)

    return feet.toFixed(2); // Return the result rounded to 2 decimal places
  };

  // Function to get the perimeter of a room
  const getRoomPerimeter = useCallback((room) => {
    if (!room?.vertices) return 0;
    const perimeterInPixels = calculatePerimeter(room.vertices);
    return convertPixelsToFeet(perimeterInPixels); // Convert the perimeter to feet
  }, []);

  const saveFloorPlanData = useCallback(async () => {
    setIsSaving(true);
    const createFloorPlanDataRes = await updateFloorPlanData(
      params?.customerId,
      { floor_plan_data: { drawingRooms, floorStaircases } }
    );

    const result = createFloorPlanDataRes?.data;
    setOpenSnackbar(true);
    if (result?.status) {
      setType("success");
      setMessage("Floor plan data updated!");

      const updatedRoomsWithArea = Object.keys(drawingRooms).reduce(
        (acc, floorName) => {
          const rooms = drawingRooms[floorName];
          const roomsWithArea = rooms?.reduce((initRoom, room) => {
            const area = getRoomArea(room);
            const perimeter = getRoomPerimeter(room);

            initRoom[room.id] = { area, perimeter };
            return initRoom;
          }, {});
          acc[floorName] = roomsWithArea || {};

          return acc;
        },
        {}
      );
      console.log("updatedRoomsWithArea", updatedRoomsWithArea);

      const mapWithOrgFloorRooms = floors?.map((f) => {
        if (_.size(updatedRoomsWithArea[f.name]) > 0) {
          console.log(_.size(updatedRoomsWithArea[f.name]) > 0);

          return {
            ...f,
            rooms: f.rooms.map((room) => {
              if (
                Object.prototype.hasOwnProperty.call(
                  updatedRoomsWithArea[f.name],
                  room.id
                )
              ) {
                const { area, perimeter } =
                  updatedRoomsWithArea[f.name]?.[room.id] || 0;
                return {
                  ...room,
                  finalTotalSqFeet: toNumber(area || "0"),
                  linear_ft: toNumber(perimeter || "0")
                };
              }
              return room;
            })
          };
        }

        return f;
      });

      dispatch(
        updateSingleCustomerApi({
          ...singleCustomerData,
          scope: { ...singleCustomerData.scope, floors: mapWithOrgFloorRooms }
        })
      );
    } else {
      setMessage(
        result?.message ||
          "Something went wrong, please try again to udpated floor plan data"
      );
      setType("error");
    }
    dispatch(setInitialDataForDrawingRooms(drawingRooms));
    dispatch(
      setStaircasesForPlan({
        TYPE: "withInitialData",
        staircases: floorStaircases
      })
    );
    setIsSaving(false);
  }, [
    drawingRooms,
    setInitialDataForDrawingRooms,
    floorStaircases,
    setStaircasesForPlan,
    setIsSaving
  ]);

  const MyPreview = () => {
    const preview = usePreview();
    if (!preview.display) {
      return null;
    }
    const { item, style } = preview;

    if (!item) return null;

    return (
      <motion.div style={style} className="w-[220px] z-[10000]">
        <Card
          bordered={false}
          style={{
            width: "100%",
            backgroundColor: Constants.TEXT_COLOR,
            margin: "5px 0",
            height: "40px",
            fontFamily: Constants.FONT_FAMILY_POPPINS
          }}
          bodyStyle={{
            padding: "10px 4px",
            display: "flex",
            justifyContent: "left"
          }}
        >
          <div>
            <AiOutlineMenu style={DRAG_ICON_STYLE} />
          </div>

          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              expandable: false
            }}
            style={{
              marginBottom: "0",
              fontFamily: Constants.FONT_FAMILY_POPPINS,
              color: "white"
            }}
          >
            {item?.item?.Product_Name}
          </Typography.Paragraph>
        </Card>
      </motion.div>
    );
  };

  return (
    <CustomDialog open={open}>
      <MyPreview />
      <CustomDialogContent sx={{ p: 1 }}>
        <Grid container spacing={1} sx={{ height: "100%" }}>
          <Grid item xs={9.5}>
            <FloorPlanDrawer />
          </Grid>
          <Grid item xs={2.5} className="!border-l-[1px] border-gray-400">
            <Floors
              closeMainModal={onClose}
              saveFloorPlanData={saveFloorPlanData}
              isSaving={isSaving}
            />
          </Grid>
        </Grid>
      </CustomDialogContent>
      <SaveConfirmationModal
        open={saveConfirmationModalOpen}
        onClose={() => setSaveConfirmationModalOpen(false)}
        setOpenFloorPlanDrawer={setOpenFloorPlanDrawer}
        saveFloorPlanData={saveFloorPlanData}
      />
      <MuiSnackbar
        open={openSnackbar}
        message={message || ""}
        type={type || ""}
        onClose={() => setOpenSnackbar(false)}
      />
    </CustomDialog>
  );
};

export default FloorPlanModal;
